import { Card, Layout, message, Steps, Typography } from 'antd';
import { BaseLayoutComponent } from '@components';

const { Text } = Typography;

const { Content } = Layout;

export const OrderCompletePageComponent = () => {
    return (
        <>
            {/* {contextHolder} */ }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={ { marginTop: 20 } }
                        progressDot
                        current={ 4 }
                        items={ [
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Delivery information',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ] }
                    />
                    <Card
                        style={ { height: 400, justifyContent: 'center', display: 'flex' } }
                    >
                        <Text type='success' underline strong style={ { fontSize: 40, marginTop: 40 } }>Order have been
                            successfully Updated!</Text>
                    </Card>
                </Content>
            }
            />
        </>
    );
};