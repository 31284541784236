import axios, { AxiosInstance } from 'axios';
import { GetToken } from './authentication';
import { environment } from '../../environments/environment';

export const API: AxiosInstance = axios.create({
    baseURL: environment.baseUrl,
    timeout: 240000,
    timeoutErrorMessage: 'Timed out due to inactivity of server. Please try again or contact your system administrator.'
});

API.interceptors.request.use(
    (config) => {
        const token = GetToken();

        if (token)
            config.headers.authorization = `Bearer ${ token }`;

        return config;
    },
);

API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = '/sign-in';  
        }
        return Promise.reject(error);
    }
);
