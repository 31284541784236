
let myPermissions: Set<string> = new Set<string>();

export const setPermissionsInApplicationMemory = (permissions: string[]): void => {
    myPermissions = new Set(permissions);
};

export const GetPermissions = (): Set<string> => myPermissions;

export const ResetPermssions = (): void => {
    myPermissions = new Set<string>();
};

export const HasPermission = (permission = ''): boolean => {
    return myPermissions.has(permission);
}

export const hasAllPermissions = (permissions: string[] = []): boolean => {
    if (!permissions.length)
        return true;

    for (const permission of permissions)
        if (!myPermissions.has(permission))
            return false;

    return true;
};

export const HasAnyPermission = (permissions: string[] = []): boolean => {
    if (!permissions.length)
        return true;

    for (const permission of permissions)
        if (myPermissions.has(permission))
            return true;

    return false;
};


export const IsAuthorized = (): boolean => {
    return !!(myPermissions.size);
};