export enum DocumentTypeSearch {
    CNIC_FRONT = 'cnic_front',
    CUSTOMER_CONTRACT = 'customer_contract',
    PAYMENT_PROOF = 'payment_proof',
    TRANSACTION_CREDIT = 'transaction_credit',
    PROCUREMENT_PAYMENT = 'procurement_payment',
    DOWNPAYMENT_RECEIPT = 'downpayment_receipt',
    CUSTOMER_PICTURE = 'customer_picture',
    DELIVERY_LOCATION_PICTURE = 'delivery_location_picture',
    LOCK_PICTURE = 'lock_picture',
    CNIC_BACK = 'cnic_back',
    BANK_STATEMENT = 'bank_statement',
    RESIDENTIAL_PROOF = 'residential_proof',
    SALARY_SLIP = 'salary_slip',
    CHEQUE = 'cheque',
    OTHERS = 'others',
    BOX_CHEQUE_RETURNED = 'box_cheque_returned',
    INVOICE = 'invoice',
    HOUSE_VIDEO = 'house_video',
}
