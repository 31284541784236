import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IPaymentTypeDTO, IPaymentTypeEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const PaymentTypeModalComponent = ({ onClose, onSubmit, editMode = false, id: paymentTypeId = '' }: AddEditModalProps<IPaymentTypeEntity>) => {
    const [form] = Form.useForm<Partial<IPaymentTypeDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [paymentType, setPaymentType] = useState<Partial<IPaymentTypeEntity>>();

    const { mutate: createPaymentType, isLoading } = useMutation<IPaymentTypeEntity, AxiosError>({
        mutationKey: ['createPaymentType'],
        mutationFn: async () =>
            PostItem<IPaymentTypeEntity, IPaymentTypeDTO>(
                'payment_type',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IPaymentTypeDTO>
            ),
        onSuccess: async (item: IPaymentTypeEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updatePaymentType, isLoading: isPaymentTypeUpdating } = useMutation<IPaymentTypeEntity, AxiosError>({
        mutationKey: ['updatePaymentType'],
        mutationFn: async () =>
            PutItem<IPaymentTypeEntity, IPaymentTypeDTO>(
                'payment_type',
                paymentTypeId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IPaymentTypeDTO>
            ),
        onSuccess: async (item: IPaymentTypeEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchPaymentType, isFetching: isPaymentTypeFetching } = useQuery<IPaymentTypeEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getPaymentType'],
        queryFn: () => GetItem<IPaymentTypeEntity>(
            'payment_type',
            paymentTypeId || ''
        ),
        onSuccess: (result: IPaymentTypeEntity): void => {
            setPaymentType(paymentType);
            form.setFieldsValue({
                ...result,
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const updateFormFields = (property: string, value: string | undefined | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createPaymentType())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updatePaymentType())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchPaymentType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode)
            fetchPaymentType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Payment Type' : 'Edit Payment Type'}
                open={true}
                confirmLoading={isLoading || isPaymentTypeFetching || isPaymentTypeUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Payment Type' : 'Edit Payment Type'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='name'
                        label='Name'
                        rules={[
                            { required: true, message: 'Please enter the name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='index'
                        label='index'
                        rules={[
                            { required: true, message: 'Please enter the index' },
                            // { min: 1, message: 'At least 2 characters' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('index', event?.target.value ? event?.target.value : undefined)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};