import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { LockOutlined, UserAddOutlined } from '@ant-design/icons';
import { FilterOperatorsEnum, IGroupEntity, IItemsWithCount, IRoleEntity, IUserDTO, IUserEntity, OrderStatusEnum, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { TagsDropdown } from '@components';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const UserModalComponent = ({ onClose, onSubmit, editMode = false, id: userId = '' }: AddEditModalProps<IUserEntity>) => {
    const [form] = Form.useForm<Partial<IUserDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [user, setUser] = useState<Partial<IUserEntity>>();
    const [roles, setRoles] = useState<IRoleEntity[]>([]);
    const [groups, setGroups] = useState<IGroupEntity[]>([]);

    const { mutate: createUser, isLoading } = useMutation<IUserEntity, AxiosError>({
        mutationKey: ['createUser'],
        mutationFn: async () =>
            PostItem<IUserEntity, IUserDTO>(
                'user/addAgent',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IUserDTO>
            ),
        onSuccess: async (item: IUserEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateUser, isLoading: isUserUpdating } = useMutation<IUserEntity, AxiosError>({
        mutationKey: ['updateUser'],
        mutationFn: async () =>
            PutItem<IUserEntity, IUserDTO>(
                'user',
                userId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IUserDTO>
            ),
        onSuccess: async (item: IUserEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchUser, isFetching: isUserFetching } = useQuery<IUserEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUser'],
        queryFn: () => GetItem<IUserEntity>(
            'user',
            userId || '',
            ['roles', 'groups'],
            ['roles.id', 'roles.name', 'groups.id', 'groups.name']
        ),
        onSuccess: (result: IUserEntity): void => {
            setUser(user);
            form.setFieldsValue({
                ...result,
                role_ids: result.roles?.map(({ id }) => id),
                group_ids: result.groups?.map(({ id }) => id)
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const [{ refetch: fetchRoles, isFetching: isRolesFetching }] = useQueries({
        queries: [
            {
                ...BASE_QUERY_OPTIONS,
                queryKey: ['getRoles'],
                queryFn: () => GetItems<IRoleEntity>(
                    'role',
                    [],
                    ['id', 'name'],
                    [
                        [
                            {
                                field: 'name',
                                operator: FilterOperatorsEnum.NOT_EQUAL,
                                value: `customer`
                            }
                        ]
                    ]
                ),
                onSuccess: (returnedResult: IItemsWithCount<IRoleEntity>): void => setRoles(returnedResult.items),
                onError: () => setRoles([])
            }
            // {
            //     ...BASE_QUERY_OPTIONS,
            //     queryKey: ['getGroups'],
            //     queryFn: () => GetItems<IGroupEntity>(
            //         'group',
            //         [],
            //         ['id', 'name']
            //     ),
            //     onSuccess: (returnedResult: IItemsWithCount<IGroupEntity>): void => setGroups(returnedResult.items),
            //     onError: () => setGroups([])
            // },
        ]
    });

    const updateFormFields = (property: string, value: string) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createUser())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateUser())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchRoles();
        // fetchGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode)
            fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Agent' : 'Edit Agent'}
                open={true}
                confirmLoading={isLoading || isRolesFetching || isUserFetching || isUserUpdating}
                okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Agent' : 'Edit Agent'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}>
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='first_name'
                        label='First Name'
                        rules={[
                            { required: true, message: 'Please enter the first name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('first_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='last_name'
                        label='Last Name'
                        rules={[
                            { required: true, message: 'Please enter the last name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='email'
                        label='Email'
                        rules={[
                            { required: true, message: 'Please enter the email', type: 'email' }
                        ]}
                    >
                        <Input
                            prefix={<UserAddOutlined />}
                            onChange={(event) => updateFormFields('email', event?.target.value)} />
                    </Form.Item>
                    {
                        !editMode &&
                        <Form.Item
                            name='password'
                            label='Password'
                            rules={[
                                { required: true, message: 'Please enter the password' },
                                { min: 8, message: 'Atleast 8 characters' }
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined />}
                                type='password'
                                onChange={(event) => updateFormFields('password', event?.target.value)} />
                        </Form.Item>
                    }
                    <Form.Item
                        name='role_ids'
                        label='Roles'
                        rules={[{ required: true, message: 'Atleast one role required' }]}
                    >
                        <Select
                            mode='multiple'
                            tagRender={TagsDropdown}
                            className='w-full'
                            options={MapToSelectOption(roles, 'name', 'id')}
                            showSearch={false}
                            onChange={(selectedItemIds: string) => updateFormFields('role_ids', selectedItemIds)}
                        />
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    name='group_ids'*/}
                    {/*    label='Groups'*/}
                    {/*    rules={ [{ required: false }] }*/}
                    {/*>*/}
                    {/*    <Select*/}
                    {/*        mode='multiple'*/}
                    {/*        tagRender={ TagsDropdown }*/}
                    {/*        onChange={ (selectedItemIds: string) => updateFormFields('group_ids', selectedItemIds) }*/}
                    {/*        showSearch={ false }*/}
                    {/*        className='w-full'*/}
                    {/*        options={ MapToSelectOption(groups, 'name', 'id') }*/}
                    {/*    />*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        </>
    );
};