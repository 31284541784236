import { Card, Layout, Steps, Typography } from 'antd';
import { BaseLayoutComponent } from '@components';

const { Text } = Typography;

const { Content } = Layout;

export const TransactionCompletePageComponent = () => {
    return (
        <>
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={ { marginTop: 20 } }
                        progressDot
                        current={ 3 }
                        items={ [
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Instalments information',
                            },
                            {
                                title: 'Transaction Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ] }
                    />
                    <Card
                        style={ { height: 400, justifyContent: 'center', display: 'flex' } }
                    >
                        <Text type='success' underline strong style={ { fontSize: 40, marginTop: 40 } }>Transaction has
                            been
                            successfully recorded!</Text>
                    </Card>
                </Content>
            }
            />
        </>
    );
};