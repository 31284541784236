import { createContext } from 'react';
import { IUserEntity } from '@rasayi-workspace/shared';

export interface IUserContext {
    user: Partial<IUserEntity>;
    id: string,
    updateUser: (user?: IUserEntity) => void;
    updateUserId: (userId: string) => void;
};

export const DEFAULT_USER_CONTEXT: IUserContext = {
    user: {},
    id: '',
    updateUser: () => null,
    updateUserId: () => null
};

export const UserContext = createContext<IUserContext>(DEFAULT_USER_CONTEXT);