export enum PaymentMethodEnum {
    FINJA= 'finja',
    MEEZANBANK='meezanbank',
    FAYSALBANK='faysalbank',
    EASYPAISA = 'easypaisa',
    JAZZCASH = 'jazzcash',
    BANKTRANSFER = 'banktransfer',
    CASH = 'cash',
    CHEQUE = 'cheque'
}
