import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, ConfigProvider, DatePicker, Divider, Form, Input, Layout, Row, Select, Steps, Tag } from 'antd';
import { FilterOperatorsEnum, getEnumeratorKeys, IInventoryEntity, IOrderEntity, KeyValueRecord, OrderStatusEnum } from '@rasayi-workspace/shared';
import { BaseLayoutComponent, SearchableDropdown } from '@components';
import { orderContext } from '@contexts';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';
import { InstalmentDetailsComponent } from '../1-instalment-information';

const { Content } = Layout;

export const PayInstalmentPageComponent = () => {
    const { orderDetails, setOrderDetails, lastAccess, setInitialInventories, setInventories, setGuarantorId, setUserId, setUser, setLastAccess } = useContext(orderContext);
    const [form] = Form.useForm<Partial<any>>();
    const [selectedOrder, setSelectedOrder] = useState<KeyValueRecord>({});
    const [nextPage, setNextPage] = useState(false);
    const [orderDate, setOrderDate] = useState<string>('');
    const [selectedId, setSelectedId] = useState<string | undefined>('');

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const nextPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue() });
        setNextPage(true);
    };

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
        form.setFieldsValue({ 'order_date': date?.toISOString() });
        if (date)
            setOrderDate(date.toISOString());
    };

    useEffect(() => {
        if (!Object.keys(selectedOrder).length && Object.keys(orderDetails).length) {
            setSelectedOrder({ ...orderDetails });
            setSelectedId(orderDetails?.internal_id);
            form.setFieldsValue({ ...orderDetails });
        } else
            form.setFieldsValue({ ...selectedOrder });

        if (lastAccess !== 'payInstalment') {
            setInitialInventories([]);
            setOrderDetails({});
            setInventories([]);
            setGuarantorId('');
            setUserId('');
            setUser({});
            setLastAccess('payInstalment');
        }

    }, [selectedOrder]);

    return (
        <>
            {
                nextPage &&
                <InstalmentDetailsComponent/>
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={ { marginTop: 20 } }
                        progressDot
                        current={ 0 }
                        items={ [
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Instalments information',
                            },
                            {
                                title: 'Transaction Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ] }
                    />
                    <Card
                        title='Select Order'
                        extra={ <>
                            <Button
                                type='primary'
                                style={ { marginLeft: 4 } }
                                disabled
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                onClick={ nextPageHandler }
                                style={ { marginLeft: 4 } }
                                disabled={ !(selectedOrder) }
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={ { height: 600, overflow: 'auto' } }
                    >
                        <div className='flex justify-end'>
                            <Button
                                type='primary'
                                onClick={ () => navigator.clipboard.writeText(selectedId || 'No Order Selected') }
                                style={ { marginLeft: 4 } }
                                disabled={ !selectedId }
                            >
                                Copy Order ID
                            </Button>
                        </div>
                        <Form
                            form={ form }
                        >
                            <Row justify={ 'center' } gutter={ 16 } style={ { marginTop: 10 } }>
                                <Col span={ 12 }>
                                    <Form.Item
                                        label='Order'
                                        rules={ [
                                            { required: true, message: 'Please enter the Order ID' }
                                        ] }
                                    >
                                        { SearchableDropdown<IOrderEntity>(
                                            {
                                                key: 'internal_id',
                                                defaultValue: selectedOrder?.internal_id || '',
                                                value: 'internal_id',
                                                queryKey: ['getBrands'],
                                                queryFnParams: {
                                                    apiRoute: 'order',
                                                    relations: ['internal_point_of_contact', 'channel', 'user', 'instalment_plan', 'inventories.mobile_info', 'inventories.procurement.product_type', 'inventories.procurement.brand'],
                                                    fields: [],
                                                    filter: {
                                                        field: 'internal_id',
                                                        operator: FilterOperatorsEnum.LIKE,
                                                        value: ''
                                                    },
                                                    predefinedFilters: [
                                                        {
                                                            field: 'order_status',
                                                            operator: FilterOperatorsEnum.NOT_IN,
                                                            value: `${ OrderStatusEnum.INCOMPLETE }|${ OrderStatusEnum.COMPLETE }|${ OrderStatusEnum.REFUND }`
                                                        }
                                                    ]
                                                },
                                                onSelectionChange: (selectedItemIds: string | string[], option: KeyValueRecord, items) => {
                                                    const currentOrder: IOrderEntity | undefined = items.find((order: IOrderEntity) => order.internal_id === selectedItemIds);
                                                    if (currentOrder) {
                                                        setSelectedOrder({ ...currentOrder });
                                                        setSelectedId(currentOrder?.internal_id);
                                                        setOrderDetails({ ...currentOrder });
                                                        form.setFieldsValue({ ...currentOrder });
                                                    }
                                                }
                                            }
                                        )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            { selectedId ? <>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['user', 'internal_id'] }
                                            label='user.internal_id'
                                            initialValue={ selectedOrder?.user?.internal_id }
                                        >
                                            <Input
                                                disabled
                                                value={ selectedOrder?.user?.internal_id }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['user', 'first_name'] }
                                            label='first_name'
                                            rules={ [
                                                { required: true, message: 'Please enter the user.first_name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }

                                            initialValue={ selectedOrder?.user?.first_name }
                                        >
                                            <Input
                                                disabled
                                                value={ selectedOrder?.user?.first_name }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['user', 'last_name'] }
                                            label='user.last_name'
                                            rules={ [
                                                { required: true, message: 'Please enter the user.last_name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.user?.last_name }
                                        >
                                            <Input disabled value={ selectedOrder?.user?.last_name }
                                                   onChange={ (event) => updateFormFields('user.last_name', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['user', 'email'] }
                                            label='user.email'
                                            rules={ [
                                                { required: true, message: 'Please enter the user.email' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.user?.email }
                                        >
                                            <Input disabled value={ selectedOrder?.user?.email }
                                                   onChange={ (event) => updateFormFields('user.email', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['user', 'cnic_number'] }
                                            label='user.cnic_number'
                                            rules={ [
                                                { required: true, message: 'Please enter the user.cnic_number' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.user?.cnic_number }
                                        >
                                            <Input disabled value={ selectedOrder?.user?.cnic_number }
                                                   onChange={ (event) => updateFormFields('user.cnic_number', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['user', 'primary_mobile'] }
                                            label='user.primary_mobile'
                                            rules={ [
                                                { required: true, message: 'Please enter the user.primary_mobile' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.user?.primary_mobile }
                                        >
                                            <Input disabled value={ selectedOrder?.user?.primary_mobile }
                                                   onChange={ (event) => updateFormFields('user.primary_mobile', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider/>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='total_retail_price'
                                            label='total_retail_price'
                                            rules={ [
                                                { required: true, message: 'Please enter the total_retail_price' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.total_retail_price }
                                        >
                                            <Input value={ selectedOrder?.total_retail_price } disabled
                                                   onChange={ (event) => {
                                                       updateFormFields('total_retail_price', event?.target?.value ? parseInt(event.target.value) : undefined);
                                                       // breakDownHandler();
                                                   } }/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='apr'
                                            label='apr'
                                            rules={ [
                                                { required: true, message: 'Please enter the apr' },
                                            ] }
                                            initialValue={ selectedOrder?.apr }
                                        >
                                            <Input value={ selectedOrder?.apr } disabled
                                                   onChange={ (event) => updateFormFields('apr', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='lastInstalmentDate'
                                            label='lastInstalmentDate'
                                            rules={ [
                                                { required: true, message: 'Please enter the lastInstalmentDate' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ dayjs(selectedOrder?.lastInstalmentDate) }

                                        >
                                            <ConfigProvider locale={ locale }>
                                                <DatePicker
                                                    disabled
                                                    value={ dayjs(selectedOrder?.lastInstalmentDate) }
                                                    showTime={ false }
                                                    style={ { width: '100%' } }
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='total_customer_price'
                                            label='total_customer_price'
                                            rules={ [
                                                { required: true, message: 'Please enter the total_customer_price' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.total_customer_price }
                                        >
                                            <Input
                                                value={ selectedOrder?.total_customer_price }
                                                disabled
                                                onChange={ (event) => updateFormFields('total_customer_price', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='downpayment'
                                            label='downpayment'
                                            rules={ [
                                                { required: true, message: 'Please enter the downpayment' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.downpayment }
                                        >
                                            <Input value={ selectedOrder?.downpayment } disabled
                                                   onChange={ (event) => {
                                                       updateFormFields('downpayment', event?.target.value);
                                                   } }/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['instalment_plan', 'name'] }
                                            label='instalment_plan'
                                            rules={ [
                                                { required: true, message: 'Please enter the instalment_plan_id' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.instalment_plan?.name }
                                        >
                                            <Input value={ selectedOrder?.instalment_plan?.name } disabled
                                                   onChange={ (event) => updateFormFields('instalment_plan_id', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='order_date'
                                            label='order_date'
                                            rules={ [
                                                { required: true, message: 'Please enter the downpayment' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.order_date ? dayjs(selectedOrder?.order_date) : undefined }
                                        >
                                            <ConfigProvider locale={ locale }>
                                                <DatePicker
                                                    disabled
                                                    value={ orderDate ? dayjs(orderDate) : dayjs(selectedOrder?.order_date) }
                                                    showTime={ false }
                                                    style={ { width: '100%' } }
                                                    onChange={ (date, dateString) => {
                                                        handleDateChange(date, dateString);
                                                    } }
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='order_status'
                                            label='order_status'
                                            rules={ [
                                                { required: true, message: 'Please enter the order_status' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.order_status }
                                        >
                                            <Select disabled onChange={ (value) => {
                                                // setIncompleteOrder(value === OrderStatusEnum.INCOMPLETE);
                                                updateFormFields('order_status', value);
                                            } }>
                                                {
                                                    getEnumeratorKeys(OrderStatusEnum).map(
                                                        (key) => {
                                                            if (OrderStatusEnum.INCOMPLETE === OrderStatusEnum[key as keyof typeof OrderStatusEnum] || OrderStatusEnum.PENDING === OrderStatusEnum[key as keyof typeof OrderStatusEnum])
                                                                return (<Select.Option key={ key }
                                                                                       value={ OrderStatusEnum[key as keyof typeof OrderStatusEnum] }>
                                                                    <Tag key={ key }>{ key.toUpperCase() }</Tag>
                                                                </Select.Option>);
                                                            else
                                                                return null;
                                                        }
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider/>

                                { selectedOrder?.inventories?.map((inventoryItem: IInventoryEntity, index: number) => (
                                    <div key={ index }>
                                        <Row justify={ 'center' } gutter={ 16 }>
                                            <p style={ { fontSize: 20 } }> Product { index + 1 }</p>
                                        </Row>
                                        <Row gutter={ 16 }>
                                            <Col span={ 12 }>
                                                <Form.Item
                                                    key={ `mobile_info.imei_1_${ index }` }
                                                    label={ `imei_1 (${ index + 1 })` }
                                                    initialValue={ inventoryItem?.mobile_info?.imei_1 }
                                                >
                                                    <Input disabled value={ inventoryItem?.mobile_info?.imei_1 }/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={ 12 }>
                                                <Form.Item
                                                    key={ `mobile_info.imei_2_${ index }` }
                                                    label={ `imei_2 (${ index + 1 })` }
                                                    initialValue={ inventoryItem?.mobile_info?.imei_2 }
                                                >
                                                    <Input disabled value={ inventoryItem?.mobile_info?.imei_2 }/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={ 16 }>
                                            <Col span={ 12 }>
                                                <Form.Item
                                                    key={ `brand_name_${ index }` }
                                                    label={ `Brand Name (${ index + 1 })` }
                                                    initialValue={ inventoryItem?.procurement?.brand?.name }
                                                >
                                                    <Input value={ inventoryItem?.procurement?.brand?.name } disabled
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={ 12 }>
                                                <Form.Item
                                                    key={ `product_type_${ index }` }
                                                    label={ `Product Type (${ index + 1 })` }
                                                    initialValue={ inventoryItem?.procurement?.product_type?.name }
                                                >
                                                    <Input value={ inventoryItem?.procurement?.product_type?.name }
                                                           disabled/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={ 16 }>
                                            <Col span={ 12 }>
                                                <Form.Item
                                                    key={ `model_${ index }` }
                                                    label={ `Model (${ index + 1 })` }
                                                    initialValue={ inventoryItem?.procurement?.model }
                                                >
                                                    <Input disabled value={ inventoryItem?.procurement?.model }/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={ 12 }>
                                                <Form.Item
                                                    key={ `locking_identifier_${ index }` }
                                                    label={ `Locking identifier (${ index + 1 })` }
                                                    initialValue={ inventoryItem?.mobile_info?.locking_identifier }
                                                >
                                                    <Input disabled
                                                           value={ inventoryItem?.mobile_info?.locking_identifier }/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                )) }


                                <Divider/>
                                <Row gutter={ 16 }>

                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='cheque_provider'
                                            label='cheque_provider'
                                            rules={ [
                                                { required: true, message: 'Please enter the cheque_provider' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.cheque_provider }
                                        >
                                            <Input value={ selectedOrder?.cheque_provider } disabled
                                                   onChange={ (event) => updateFormFields('cheque_provider', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='delivery_city'
                                            label='delivery_city'
                                            rules={ [
                                                { required: true, message: 'Please enter the delivery_city' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.delivery_city }
                                        >
                                            <Input value={ selectedOrder?.delivery_city } disabled
                                                   onChange={ (event) => updateFormFields('delivery_city', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['channel', 'name'] }
                                            label='channel.name'
                                            rules={ [
                                                { required: true, message: 'Please enter the channel.name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.channel?.name }
                                        >
                                            <Input value={ selectedOrder?.channel?.name } disabled
                                                   onChange={ (event) => updateFormFields('channel.name', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name='segment'
                                            label='segment'
                                            rules={ [
                                                { required: true, message: 'Please enter the segment' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ] }
                                            initialValue={ selectedOrder?.segment }
                                        >
                                            <Input value={ selectedOrder?.segment } disabled
                                                   onChange={ (event) => updateFormFields('segment', event?.target.value) }/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ 16 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name={ ['internal_point_of_contact', 'first_name'] }
                                            label='POC'
                                            initialValue={ selectedOrder?.internal_point_of_contact?.first_name }
                                            rules={ [
                                                { required: true, message: 'Please select the POC' },
                                            ] }
                                        >
                                            <Input
                                                disabled
                                                value={ selectedOrder?.internal_point_of_contact?.first_name  }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> : '' }
                            {/* Add more rows with Col and Form.Item as needed */ }
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};