import { useEffect, useState } from 'react';
import { DatePicker, Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getEnumeratorKeys, IInstalmentEntity, IItemsWithCount, IOrderEntity, IProcurementEntity, ITransactionDTO, ITransactionEntity, IUserEntity, IVendorEntity, PaymentFlowEnum, PaymentMethodEnum, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';
import { ConfigProvider } from 'antd/lib';

export const TransactionModalComponent = ({ onClose, onSubmit, editMode = false, id: transactionId = '' }: AddEditModalProps<ITransactionEntity>) => {
    const [form] = Form.useForm<Partial<ITransactionDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [transaction, setTransaction] = useState<Partial<ITransactionEntity>>();
    const [users, setUsers] = useState<IUserEntity[]>([]);
    const [orders, setOrders] = useState<IOrderEntity[]>([]);
    const [instalment, setInstalment] = useState<IInstalmentEntity[]>([]);
    const [vendors, setVendors] = useState<IVendorEntity[]>([]);
    const [procurement, setProcurement] = useState<IProcurementEntity[]>([]);
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<dayjs.Dayjs | null>(null);


    const { mutate: createTransaction, isLoading } = useMutation<ITransactionEntity, AxiosError>({
        mutationKey: ['createTransaction'],
        mutationFn: async () =>
            PostItem<ITransactionEntity, ITransactionDTO>(
                'transaction',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<ITransactionDTO>
            ),
        onSuccess: async (item: ITransactionEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateTransaction, isLoading: isTransactionUpdating } = useMutation<ITransactionEntity, AxiosError>({
        mutationKey: ['updateTransaction'],
        mutationFn: async () =>
            PutItem<ITransactionEntity, ITransactionDTO>(
                'transaction',
                transactionId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<ITransactionDTO>
            ),
        onSuccess: async (item: ITransactionEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchTransaction, isFetching: isTransactionFetching } = useQuery<ITransactionEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getTransaction'],
        queryFn: () => GetItem<ITransactionEntity>(
            'transaction',
            transactionId || '',
            [],
            []
        ),
        onSuccess: (result: ITransactionEntity): void => {
            setTransaction(transaction);
            form.setFieldsValue({
                ...result,
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchUsers, isFetching: isUsersFetching } = useQuery<IItemsWithCount<IUserEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUsers'],
        queryFn: () => GetItems<IUserEntity>(
            'user',
            [],
            ['id', 'first_name', 'cnic_number']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IUserEntity>): void => setUsers(returnedResult.items),
        onError: () => setUsers([])
    });

    const { refetch: fetchOrders } = useQuery<IItemsWithCount<IOrderEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getOrders'],
        queryFn: () => GetItems<IOrderEntity>(
            'order',
            [],
            ['id']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IOrderEntity>): void => setOrders(returnedResult.items),
        onError: () => setOrders([])
    });

    const { refetch: fetchInstalment, isFetching: isInstalmentFetching } = useQuery<IItemsWithCount<IInstalmentEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInstalment'],
        queryFn: () => GetItems<IInstalmentEntity>(
            'instalment',
            [],
            ['id']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IInstalmentEntity>): void => setInstalment(returnedResult.items),
        onError: () => setInstalment([])
    });

    const { refetch: fetchProcurement } = useQuery<IItemsWithCount<IProcurementEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getProcurements'],
        queryFn: () => GetItems<IProcurementEntity>(
            'procurement',
            [],
            ['id']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IProcurementEntity>): void => setProcurement(returnedResult.items),
        onError: () => setProcurement([])
    });


    const { refetch: fetchVendors } = useQuery<IItemsWithCount<IVendorEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getVendors'],
        queryFn: () => GetItems<IVendorEntity>(
            'vendor',
            [],
            ['id', 'first_name', 'last_name', 'vendor_name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IVendorEntity>): void => setVendors(returnedResult.items),
        onError: () => setVendors([])
    });

    const updateFormFields = (property: string, value: string | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
        setSelectedDateOfBirth(date);
        // form.setFieldsValue({ 'datetime': dateString });
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createTransaction())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateTransaction())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchUsers();
        fetchOrders();
        fetchInstalment();
        fetchProcurement();
        fetchVendors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                className='modalStyle'
                title={!editMode ? 'Add Transaction' : 'Edit Transaction'}
                open={true}
                confirmLoading={isLoading || isUsersFetching || isInstalmentFetching || isTransactionFetching || isTransactionUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Transaction' : 'Edit Transaction'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='order_id'
                        label='order_id'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(orders, 'id', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='instalment_id'
                        label='instalment_id'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(instalment, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='user_id'
                        label='User'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(users, 'cnic_number', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='vendor_id'
                        label='Vendor id'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(vendors, 'vendor_name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='procurement_id'
                        label='Procurement id'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(procurement, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='flow'
                        label='flow'
                        rules={[
                            { required: true, message: 'Please enter the flow' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('flow', value)}>
                            {
                                getEnumeratorKeys(PaymentFlowEnum).map(
                                    (key) => <Select.Option key={key} value={PaymentFlowEnum[key as keyof typeof PaymentFlowEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='amount'
                        label='amount'
                        rules={[
                            { required: true, message: 'Please enter the amount' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('amount', parseInt(event?.target.value))} />
                    </Form.Item>
                    <Form.Item
                        name='transaction_id'
                        label='external transaction_id'
                        rules={[
                            { required: true, message: 'Please enter the transaction_id' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('transaction_id', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='payment_proof'
                        label='payment_proof'
                        rules={[
                            { required: true, message: 'Please enter the payment_proof' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('payment_proof', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='payment_method'
                        label='payment_method'
                        rules={[
                            { required: true, message: 'Please enter the payment_method' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('payment_method', value)}>
                            {
                                getEnumeratorKeys(PaymentMethodEnum).map(
                                    (key) => <Select.Option key={key} value={PaymentMethodEnum[key as keyof typeof PaymentMethodEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='datetime'
                        label='datetime'
                        rules={[
                            { required: true, message: 'Please enter the datetime' }
                        ]}
                    >
                        <ConfigProvider locale={locale}>
                            <DatePicker
                                value={dayjs(form.getFieldValue('datetime'))}
                                onChange={handleDateChange}
                                showTime={false}
                                style={{ width: '100%' }}
                            />
                        </ConfigProvider>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};