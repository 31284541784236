import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, Card, Layout, message, Table, TableProps, Tag, Tooltip } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IItemsWithCount, IRoleEntity, KeyValueRecord, PermissionsEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission } from '@services';
import { ErrorResponse, ITableState } from '@interfaces';
import { RoleModalComponent } from './add-edit';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';

const { Content } = Layout;

export const RolePageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [rolesData, setRolesData] = useState<IItemsWithCount<IRoleEntity>>(EMPTY_INITIAL_ITEMS);
    const [isRoleModalOpened, setIsRoleModalOpened] = useState(false);
    const [editingRoleId, setEditingRoleId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IRoleEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['roles'],
        queryFn: () => GetTableItems<IRoleEntity>(
            'role',
            ['permissions', 'groups'],
            ['permissions.id', 'permissions.name', 'groups.id', 'groups.name'],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IRoleEntity>): void => setRolesData(returnedResult),
        onError: () => setRolesData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteRole } = useMutation<IRoleEntity, AxiosError>({
        mutationKey: ['deleteRole'],
        mutationFn: async () =>
            DeleteItem<IRoleEntity>(
                'role',
                editingRoleId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Role deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IRoleEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IRoleEntity>).columnKey as keyof IRoleEntity]: MapAntDesignSortOrder((sorter as SorterResult<IRoleEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onRoleModalCloseHandler = (newRole: IRoleEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingRoleId ? {
                type: 'success',
                content: `New role '${ newRole?.name }' created!`,
            } : {
                type: 'success',
                content: `Role '${ newRole?.name }' updated!`,
            }
        );

    }

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsRoleModalOpened(false);
        setEditingRoleId('');
    }

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IRoleEntity> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: () => 0,
            ...ColumnsSearchProps('name', 'by role name', resetSearchHandler)
        },
        {
            title: 'Permissions',
            key: 'permissions.name',
            ellipsis: true,
            render: (_: string, item: IRoleEntity) => {
                const permissions = item.permissions?.map(({ name }) => name) || [];

                return <Tooltip placement='topLeft' title={
                    <ol className='h-40 w-40 overflow-auto'>
                        {
                            permissions.map(
                                (permission, index) => <li key={index}>{permission}</li>
                            )
                        }
                    </ol>
                }>
                    <span className='cursor-pointer'>
                        {
                            permissions.map((permission, index) => <Tag key={index} color='green'>{permission}</Tag>)
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('permissions.name', 'by permission name', resetSearchHandler)
        },
        {
            title: 'Groups',
            key: 'groups.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IRoleEntity) => {
                const groups = item.groups?.map(({ name }) => name) || [];

                return <Tooltip placement='topLeft' title={groups.join(', ')}>
                    <span className='cursor-pointer'>
                        {
                            groups.map((name, index) => <Tag key={index} color='blue'>{name}</Tag>)
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('groups.name', 'by group name', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            sorter: () => 0,
            render: (value: string) => transformToFormattedTime(value),
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            sorter: () => 0,
            render: (value: string) => transformToFormattedTime(value),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, name }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.ROLE_FULL, PermissionsEnum.ROLE_WRITE])}
                        onClick={
                            () => {
                                setEditingRoleId(id);
                                setIsRoleModalOpened(true)
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.ROLE_FULL, PermissionsEnum.ROLE_DELETE])}
                        onClick={
                            () => {
                                setEditingRoleId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete role '${name}'?`,
                                    onOk: () => {
                                        deleteRole();
                                    },
                                    onCancel: () => resetModal()

                                })
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isRoleModalOpened &&
                <RoleModalComponent
                    editMode={ !!editingRoleId }
                    id={ editingRoleId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onRoleModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Roles'
                                addButtonText='Add Roles'
                                disabled={!HasAnyPermission([PermissionsEnum.ROLE_FULL, PermissionsEnum.ROLE_WRITE])}
                                onClick={() => setIsRoleModalOpened(true)}
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={rolesData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: rolesData.count,
                                        pageSize: rolesData.pageSize,
                                        current: rolesData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true}}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};