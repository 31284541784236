import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import type { ColumnType, FilterDropdownProps } from 'antd/es/table/interface';
import { ReactNode } from 'react';

export function ColumnsSearchProps<T>(dataIndex: string, title: string, reset: (dataIndex: string) => void): ColumnType<T> {
    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps): ReactNode => (
            <div className='p-3 w-56' onKeyDown={event => event.stopPropagation()}>
                <Input
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0] || ''}
                    onChange={event => setSelectedKeys(event.target?.value ? [event.target.value] : [])}
                    onPressEnter={() => confirm()}
                    className='mb-2'
                />
                <div className='flex justify-between'>
                    <Button
                        type='primary'
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size='small'
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedKeys([]);
                            reset(dataIndex);
                            clearFilters && clearFilters();
                            confirm({ closeDropdown: false });
                        }}
                        size='small'
                    >
                        Clear
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => close()}
                    >
                        Close
                    </Button>
                </div>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined className={`flex items-center text-lg ${filtered && 'text-blue-600' || ''} `} />
    });
} 