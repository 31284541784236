import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IInventoryEntity, IItemsWithCount, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { InventoryModalComponent } from './add-edit';

const { Content } = Layout;

const InventoryPageComponent = (): any => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [inventorysData, setInventorysData] = useState<IItemsWithCount<any>>(EMPTY_INITIAL_ITEMS);
    const [isInventoryModalOpened, setIsInventoryModalOpened] = useState(false);
    const [editingInventoryId, setEditingInventoryId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['inventory'],
        queryFn: () => GetTableItems<any>(
            'inventory',
            ['procurement.vendor', 'procurement.brand'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<any>): void => setInventorysData(returnedResult),
        onError: () => setInventorysData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteInventory } = useMutation<any, AxiosError>({
        mutationKey: ['deleteInventory'],
        mutationFn: async () =>
            DeleteItem<any>(
                'inventory',
                editingInventoryId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Inventory deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IInventoryEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IInventoryEntity>).columnKey as keyof IInventoryEntity]: MapAntDesignSortOrder((sorter as SorterResult<IInventoryEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onInventoryModalCloseHandler = (newInventory: any) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingInventoryId ? {
                type: 'success',
                content: `New inventory created!`,
            } : {
                type: 'success',
                content: `Inventory updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsInventoryModalOpened(false);
        setEditingInventoryId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item: any) => !item.dataKey) || []
        });
    };

    const columns: ColumnsType<any> = [
        {
            title: 'ID',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('internal_id', 'by ID', resetSearchHandler)
        },
        {
            title: 'Procurement ID',
            dataIndex: 'procurement.internal_id',
            key: 'procurement.internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.internal_id', 'by Procurement ID', resetSearchHandler),
            render: (_: string, item: IInventoryEntity) => {
                const agent = item.procurement?.internal_id;
                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            agent
                        }
                    </span>
                </Tooltip>;
            }
        },
        {
            title: 'Inventory Status',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('status', 'by Inventory Status', resetSearchHandler)
        },
        {
            title: 'Vendor Name',
            dataIndex: ['procurement', 'vendor', 'vendor_name'],
            key: 'procurement.vendor.vendor_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.vendor.vendor_name', 'by Vendor Name', resetSearchHandler)
        },
        {
            title: 'Brand Name',
            dataIndex: ['procurement', 'brand', 'name'],
            key: 'procurement.brand.name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.brand.name', 'by Brand Name', resetSearchHandler)
        },
        {
            title: 'Model',
            dataIndex: ['procurement', 'model'],
            key: 'procurement.model',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.model', 'by Model', resetSearchHandler)
        },
        {
            title: 'Retail Price',
            dataIndex: 'retail_price',
            key: 'retail_price',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('retail_price', 'by Retail Price', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, first_name = '', last_name = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingInventoryId(id);
                                setIsInventoryModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={
                            () => {
                                setEditingInventoryId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete inventory?`,
                                    onOk: () => {
                                        deleteInventory();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isInventoryModalOpened &&
                <InventoryModalComponent
                    editMode={ !!editingInventoryId }
                    id={ editingInventoryId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onInventoryModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Inventory'
                                addButtonText='Add Inventory'
                                // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                                onClick={() => setIsInventoryModalOpened(true)}
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={inventorysData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: inventorysData.count,
                                        pageSize: inventorysData.pageSize,
                                        current: inventorysData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true }}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default InventoryPageComponent;