import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IGroupEntity, IItemsWithCount, IPermissionEntity, IRoleDTO, IRoleEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { TagsDropdown } from '@components';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const RoleModalComponent = ({ onClose, onSubmit, editMode = false, id: roleId = '' }: AddEditModalProps<IRoleEntity>) => {
    const [form] = Form.useForm<Partial<IRoleDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [role, setRole] = useState<Partial<IRoleEntity>>();
    const [permissions, setPermissions] = useState<IPermissionEntity[]>([]);
    const [groups, setGroups] = useState<IGroupEntity[]>([]);

    const { mutate: createRole, isLoading } = useMutation<IRoleEntity, AxiosError>({
        mutationKey: ['createRole'],
        mutationFn: async () =>
            PostItem<IRoleEntity, IRoleDTO>(
                'role',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IRoleDTO>
            ),
        onSuccess: async (item: IRoleEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateRole, isLoading: isRoleUpdating } = useMutation<IRoleEntity, AxiosError>({
        mutationKey: ['updateRole'],
        mutationFn: async () =>
            PutItem<IRoleEntity, IRoleDTO>(
                'role',
                roleId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IRoleDTO>
            ),
        onSuccess: async (item: IRoleEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchRole, isFetching: isRoleFetching } = useQuery<IRoleEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getRole'],
        queryFn: () => GetItem<IRoleEntity>(
            'role',
            roleId || '',
            ['permissions', 'groups'],
            ['permissions.id', 'permissions.name', 'groups.id', 'groups.name']
        ),
        onSuccess: (result: IRoleEntity): void => {
            setRole(role);
            form.setFieldsValue({
                ...result,
                permission_ids: result.permissions?.map(({ id }) => id),
                group_ids: result.groups?.map(({ id }) => id)
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchPermissions, isFetching: isPermissionsFetching } = useQuery<IItemsWithCount<IPermissionEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getPermissions'],
        queryFn: () => GetItems<IPermissionEntity>(
            'permission',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IPermissionEntity>): void => setPermissions(returnedResult.items),
        onError: () => setPermissions([])
    });

    const { refetch: fetchGroups, isFetching: isGroupsFetching } = useQuery<IItemsWithCount<IGroupEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getGroups'],
        queryFn: () => GetItems<IGroupEntity>(
            'group',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IGroupEntity>): void => setGroups(returnedResult.items),
        onError: () => setGroups([])
    });

    const updateFormFields = (property: string, value: string) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        const permission_ids = form.getFieldValue('permission_ids');

        setIsValidForm(fieldsTouched && !hasErrors && permission_ids?.length > 0);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createRole())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateRole())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchPermissions();
        fetchGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode)
            fetchRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Role' : 'Edit Role'}
                open={true}
                confirmLoading={isLoading || isPermissionsFetching || isRoleFetching || isRoleUpdating || isGroupsFetching}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Role' : 'Edit Role'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{maxHeight: 550, overflow: 'auto'}}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='name'
                        label='Role name'
                        rules={[
                            { required: true, message: 'Please enter the role name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='permission_ids'
                        label='Permissions'
                        rules={[{ required: false }]}
                    >
                        <Select
                            mode='multiple'
                            tagRender={TagsDropdown}
                            onChange={(selectedItemIds: string) => updateFormFields('permission_ids', selectedItemIds)}
                            showSearch={false}
                            className='w-full'
                            options={MapToSelectOption(permissions, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='group_ids'
                        label='Groups'
                        rules={[{ required: false }]}
                    >
                        <Select
                            mode='multiple'
                            tagRender={TagsDropdown}
                            onChange={(selectedItemIds: string) => updateFormFields('group_ids', selectedItemIds)}
                            showSearch={false}
                            className='w-full'
                            options={MapToSelectOption(groups, 'name', 'id')}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
