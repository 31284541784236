import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps } from 'antd';
import { DeleteOutlined, EditOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IItemsWithCount, IProductTypeEntity, KeyValueRecord, PermissionsEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { ProductTypeModalComponent } from './add-edit';

const { Content } = Layout;

export const ProductTypePageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [usersData, setProductTypesData] = useState<IItemsWithCount<IProductTypeEntity>>(EMPTY_INITIAL_ITEMS);
    const [isProductTypeModalOpened, setIsProductTypeModalOpened] = useState(false);
    const [editingProductTypeId, setEditingProductTypeId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IProductTypeEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['productTypes'],
        queryFn: () => GetTableItems<IProductTypeEntity>(
            'product_type',
            [],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IProductTypeEntity>): void => setProductTypesData(returnedResult),
        onError: () => setProductTypesData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteProductType } = useMutation<IProductTypeEntity, AxiosError>({
        mutationKey: ['deleteProductType'],
        mutationFn: async () =>
            DeleteItem<IProductTypeEntity>(
                'product_type',
                editingProductTypeId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `ProductType deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IProductTypeEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IProductTypeEntity>).columnKey as keyof IProductTypeEntity]: MapAntDesignSortOrder((sorter as SorterResult<IProductTypeEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onProductTypeModalCloseHandler = (newProductType: IProductTypeEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingProductTypeId ? {
                type: 'success',
                content: `ProductType '${ newProductType?.name }' created!`,
            } : {
                type: 'success',
                content: `ProductType '${ newProductType?.name }' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsProductTypeModalOpened(false);
        setEditingProductTypeId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IProductTypeEntity> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('name', 'by name', resetSearchHandler)
        },
        {
            title: 'product_code',
            dataIndex: 'product_code',
            key: 'product_code',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('product_code', 'by product_code', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, name = '', description = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EditOutlined/> }
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        disabled
                        onClick={
                            () => {
                                setEditingProductTypeId(id);
                                setIsProductTypeModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={ <DeleteOutlined/> }
                        disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE]) }
                        onClick={
                            () => {
                                setEditingProductTypeId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete product type: ${ name }?`,
                                    onOk: () => {
                                        deleteProductType();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isProductTypeModalOpened &&
                <ProductTypeModalComponent
                    editMode={ !!editingProductTypeId }
                    id={ editingProductTypeId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onProductTypeModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Product Types'
                                addButtonText='Add Product Type'
                                disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                                onClick={ () => setIsProductTypeModalOpened(true) }
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ usersData.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: usersData.count,
                                        pageSize: usersData.pageSize,
                                        current: usersData.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};