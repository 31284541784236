import { AxiosError } from 'axios';
import { Key, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Steps, Table, TableProps } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IInstalmentEntity, IInstalmentPlanEntity, KeyValueRecord } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { GetItem } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { PayInstalmentPageComponent } from '../0-order-information';
import { orderContext } from '@contexts';
import { PaymentDetailsPageComponent } from '../2-payment-details';

const { Content } = Layout;

export const InstalmentDetailsComponent = () => {
    const { orderDetails, setOrderDetails } = useContext(orderContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [allInstalments, setAllInstalments] = useState<IInstalmentEntity | any>();
        const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [selected, setSelected] = useState<boolean>(false);

    const { refetch: fetchOrderInstalment, isFetching: isOrderInstalmentFetching } = useQuery<IInstalmentEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getOrderInstallments'],
        queryFn: () => GetItem<IInstalmentEntity>(
            `order/${ orderDetails.id }/instalment`,
            '',
            ['payment_type'],
            []
        ),
        onSuccess: (result: IInstalmentEntity): void => {
            setAllInstalments(result);
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const handleChange: TableProps<IInstalmentEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IInstalmentPlanEntity>).columnKey as keyof IInstalmentPlanEntity]: MapAntDesignSortOrder((sorter as SorterResult<IInstalmentPlanEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };


    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const nextPageHandler = () => {
        setNextPage(true);
    };

    const previousPageHandler = () => {
        setPreviousPage(true);
    };

    const columns: ColumnsType<IInstalmentEntity> = [
        {
            title: 'Index',
            dataIndex: ['payment_type', 'index'],
            key: 'index',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('payment_type.index', 'by index', resetSearchHandler)
        },
        {
            title: 'Name',
            dataIndex: ['payment_type', 'name'],
            key: 'name',
            ellipsis: true,
        },
        {
            title: 'ID',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('internal_id', 'by id', resetSearchHandler)
        },
        {
            title: 'Total Customer Amount',
            dataIndex: ['order', 'total_customer_price'],
            key: 'order.total_customer_price',
            ellipsis: true,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('amount', 'by amount', resetSearchHandler)
        },
        {
            title: 'Fine',
            dataIndex: 'fine',
            key: 'fine',
            ellipsis: false,
            render: (text, record) => (record.fine || 0),
        },
        {
            title: 'Adjustment',
            dataIndex: 'carried_forward',
            key: 'carried_forward',
            ellipsis: false,
            render: (text, record) => (record.carried_forward || 0),
        },
        {
            title: 'Total',
            dataIndex: 'total_amount',
            key: 'total_amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('total_amount', 'by total amount', resetSearchHandler)
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amount',
            key: 'paid_amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => (record.paid_amount || 0),
            ...ColumnsSearchProps('paid_amount', 'by paid amount', resetSearchHandler)
        },
        {
            title: 'Pending Amount',
            ellipsis: true,
            render: (text, record) => ((record.total_amount || 0) - (record.paid_amount || 0)),
        },
        {
            title: 'Due Date',
            dataIndex: 'expected_datetime',
            key: 'expected_datetime',
            ellipsis: true,
            sorter: () => 0,
            render: (text, record) => (record.expected_datetime.split('T')[0] || 0),
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'Paid Date',
            dataIndex: 'paid_datetime',
            key: 'paid_datetime',
            ellipsis: true,
            sorter: () => 0,
            render: (text, record) => (record.paid_datetime ? record.paid_datetime.split('T')[0] : undefined),

            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            ellipsis: true,
            render: (text, record) => (record.paid ? 'YES' : 'NO'),

        },
        {
            title: 'Status',
            dataIndex: 'instalment_status',
            key: 'instalment_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('instalment_status', 'by Status', resetSearchHandler)
        },
    ];

    const rowSelection: any = {
        selectedRowKeys: orderDetails.selected_instalment_ids ? orderDetails.selected_instalment_ids : [],
        onChange: (newSelectedRowKeys: Key[], selectedRows: KeyValueRecord[]) => {
            if (newSelectedRowKeys.length)
                setSelected(true);
            else
                setSelected(false);
            setOrderDetails({ ...orderDetails, 'selected_instalment': selectedRows, 'selected_instalment_ids': newSelectedRowKeys });
        },
        getCheckboxProps: (record: KeyValueRecord, index: number) => {
            const nextUnpaidIndex = allInstalments.filter((el:any) => el.paid === true)
            const unpaidIndex = nextUnpaidIndex?.length
            const id = allInstalments[unpaidIndex]?.id
            return ({
            // nextUnpaidIndex,
            disabled: record.paid === true || record?.id !== id
        })
    },
    };

    const getRowColor = (record: KeyValueRecord, index: number) => {
        if (record['instalment_status'] === 'due') {
            return 'background-color: red; color: white; font-weight: bold;';
        }
        return '';
    };

    useEffect(() => {
        Object.keys(orderDetails).length && !isOrderInstalmentFetching && fetchOrderInstalment();
        orderDetails?.selected_instalment_ids?.length && setSelected(true);
    }, [orderDetails]);

    return (
        <>
            { contextHolder }
            {
                nextPage &&
                <PaymentDetailsPageComponent/>
            }
            {
                previousPage &&
                <PayInstalmentPageComponent/>
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={ { marginTop: 20 } }
                        progressDot
                        current={ 1 }
                        items={ [
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Instalments information',
                            },
                            {
                                title: 'Transaction Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ] }
                    />
                    <Card
                        title='Select Instalment'
                        extra={ <>
                            <Button
                                type='primary'
                                style={ { marginLeft: 4 } }
                                onClick={ previousPageHandler }
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                onClick={ nextPageHandler }
                                style={ { marginLeft: 4 } }
                                disabled={ !selected }
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={ { height: 600, overflow: 'auto' } }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                rowSelection={ {
                                    type: 'radio',
                                    ...rowSelection,
                                } }
                                columns={ columns }
                                dataSource={ allInstalments }
                                onChange={ handleChange }
                                loading={ isOrderInstalmentFetching }
                                rowKey={ 'id' }
                                size='large'
                                scroll={ { x: true } }
                                rowClassName={ getRowColor }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};