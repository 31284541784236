import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Select, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useQuery } from '@tanstack/react-query';
import { IFilterParameter, IItemsWithCount, KeyValueRecord, SortOrder } from '@rasayi-workspace/shared';
import { BASE_QUERY_OPTIONS } from '@constants';
import { useDebounce } from '@hooks';
import { GetItems } from '@services';

/**
 * Searchable dropdown component using debounce
 * @param key the key which gets the key's value from the object from the given T item
 * @param value the value key which gets the object value from the given T item
 * @param queryKey the querykey to manage cache and state of querying to api
 * @param queryFnparams the getItems query function parameters as required by all the get items query
 * @param onSelectionChange the function which is fired when the value of selection is changed
 * @returns 
 */
export function SearchableDropdown<T>(
    {
        mode = undefined,
        key,
        value,
        queryKey,
        queryFnParams: { apiRoute, relations, fields, filter, predefinedFilters },
        onSelectionChange = () => undefined,
        defaultValue,
        defaultMultiValue,
        sortBy
    }: {
        mode?: 'multiple' | 'tags',
        key: string,
        value: string,
        queryKey: string[],
        queryFnParams: {
            apiRoute: string,
            relations: string[],
            fields: string[],
            filter: IFilterParameter,
            predefinedFilters: IFilterParameter[]
        },
        onSelectionChange?: ((value: string | string[], option: DefaultOptionType | DefaultOptionType[], items: T[]) => void),
        defaultValue?: string,
        defaultMultiValue?: string[],
        sortBy?: SortOrder
    }
): JSX.Element {
    const [items, setItems] = useState<T[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [spinner, setSpinner] = useState<boolean>(false);
    const debouncedValue = useDebounce(searchTerm, 500);
    const [defaultRead, setDefaultRead] = useState<boolean>(false);

    const { refetch } = useQuery<IItemsWithCount<T>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey,
        queryFn: () => GetItems<T>(
            apiRoute,
            relations,
            fields,
            [[...predefinedFilters, ...[{ ...filter, value: debouncedValue }]]],
            sortBy
        ),
        onSuccess: (returnedResult: IItemsWithCount<T>): void => setItems(returnedResult.items),
        onError: () => setItems([])
    });

    useEffect(() => {
        setSpinner(false);
        if (defaultValue && !defaultRead) {
            setDefaultRead(true);
            setSearchTerm(defaultValue);
        }
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue, defaultValue]);

    return <Select
        mode={mode}
        value={ defaultMultiValue || undefined }
        defaultValue={ defaultMultiValue || undefined }
        autoClearSearchValue={false}
        showSearch={true}
        searchValue={searchTerm}
        optionFilterProp='children'
        onChange={(value, option) => onSelectionChange(value, option, items)}
        onSearch={(value: string) => {
            setSearchTerm(value);
            setSpinner(true);
        }}
        notFoundContent={spinner ? <Spin className='flex justify-center p-5' size='small' /> : undefined}
    >
        {
            items?.map(
                (item: T) => (
                    <Select.Option key={(item as KeyValueRecord)[key]} value={(item as KeyValueRecord)[key]}>
                        {(item as KeyValueRecord)[value]}
                    </Select.Option>
                )
            )
        }
    </Select>
}