import React from 'react';
import { Modal, Descriptions } from 'antd';
import { IDeliveryEntity } from '@rasayi-workspace/shared';
import dayjs from 'dayjs';

interface ViewDeliveryModalComponentProps {
    id: string;
    visible: boolean;
    onClose: () => void;
    deliveryData: IDeliveryEntity | null;
}

const ViewDeliveryModalComponent: React.FC<ViewDeliveryModalComponentProps> = ({ id, visible, onClose, deliveryData }) => {
    if (!deliveryData) return null;

    const formatDateTime = (dateTime: string | undefined) => {
        return dateTime ? dayjs(dateTime).format('MMMM D, YYYY h:mm A') : undefined;
    };

    return (
        <Modal
            title={`View Delivery`}
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Order ID">{deliveryData?.order?.internal_id}</Descriptions.Item>
                <Descriptions.Item label="Customer Name">{deliveryData?.order?.user?.first_name} {deliveryData?.order?.user?.last_name}</Descriptions.Item>
                <Descriptions.Item label="CNIC Number">{deliveryData?.order?.user?.cnic_number}</Descriptions.Item>
                <Descriptions.Item label="Phone Number">{deliveryData?.order?.user?.primary_mobile}</Descriptions.Item>
                <Descriptions.Item label="Guarantor Name">{deliveryData?.order?.guarantor?.first_name} {deliveryData?.order?.guarantor?.last_name}</Descriptions.Item>
                <Descriptions.Item label="Guarantor Number">{deliveryData?.order?.guarantor?.primary_mobile}</Descriptions.Item>
                <Descriptions.Item label="Model">{deliveryData?.order?.inventories?.map(procurement =>  procurement?.procurement?.model)}</Descriptions.Item>
                <Descriptions.Item label="Token Amount">{deliveryData?.order?.tokenamount}</Descriptions.Item>
                <Descriptions.Item label="Suggested Delivery Time">{formatDateTime(deliveryData?.order?.suggested_delivery_time)}</Descriptions.Item>
                <Descriptions.Item label="Delivery Pin">
                    <a href={deliveryData?.delivery_pin} target='_blank' rel='noopener noreferrer'>
                        {deliveryData?.delivery_pin}
                    </a>
                </Descriptions.Item>
                <Descriptions.Item label="Order Delivery Pin">
                    <a href={deliveryData?.order?.delivery_pin} target='_blank' rel='noopener noreferrer'>
                        {deliveryData?.order?.delivery_pin}
                    </a>
                </Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default ViewDeliveryModalComponent;
