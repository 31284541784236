import { useState } from 'react';
import { Divider, message, Modal, Radio, Table, TableProps } from 'antd';
import { IInventoryEntity, IOrderEntity, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { DEFAULT_TABLE_STATE } from '@constants';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { AddEditModalProps, ITableState } from '@interfaces';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { PayInstallmentModalComponent } from './pay-installments';
import { ColumnsSearchProps } from '@components';

export const ViewOrderInventoriesModalComponent = ({ onClose, onSubmit, viewMode = false, id: orderId = '', row }: AddEditModalProps<IInventoryEntity>) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [isPayInstallmentModalOpened, setIsPayInstallmentModalOpened] = useState(false);
    const [payInstallmentOrderId, setPayInstallmentOrderId] = useState('');

    const handleChange: TableProps<IInventoryEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IInventoryEntity>).columnKey as keyof IInventoryEntity]: MapAntDesignSortOrder((sorter as SorterResult<IInventoryEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const onPayInstallmentModalCloseHandler = (newOrder: IOrderEntity) => {
        resetModal();

        messageApi.open(
            !payInstallmentOrderId ? {
                type: 'success',
                content: `New order '${newOrder?.user + ' ' + newOrder?.total_customer_price}' created!`,
            } : {
                type: 'success',
                content: `Order '${newOrder?.user + ' ' + newOrder?.total_customer_price}' updated!`,
            }
        );
    }

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsPayInstallmentModalOpened(false);
        setPayInstallmentOrderId('')
    }

    const columns: ColumnsType<IInventoryEntity> = [
        {
            title: 'Id',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('internal_id', 'by ID', resetSearchHandler)
        },
        {
            title: 'Procurement ID',
            dataIndex: ['procurement', 'internal_id'],
            key: 'procurement.internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.internal_id', 'by Procurement ID', resetSearchHandler)
        },
        {
            title: 'Product Type',
            dataIndex: ['procurement', 'product_type', 'name'],
            key: 'procurement.product_type.name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.product_type.name', 'by Product Name', resetSearchHandler)
        },
        {
            title: 'Brand',
            dataIndex: ['procurement', 'brand', 'name'],
            key: 'procurement.brand.name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.brand.name', 'by Brand Name', resetSearchHandler)
        },
        {
            title: 'Model',
            dataIndex: ['procurement', 'model'],
            key: 'procurement.model',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.model', 'by Model', resetSearchHandler)
        },
        {
            title: 'Specifications',
            dataIndex: ['procurement', 'specifications'],
            key: 'procurement.specifications',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.specifications', 'by Specifications', resetSearchHandler)
        },
        {
            title: 'Color',
            dataIndex: ['procurement', 'color'],
            key: 'procurement.color',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.color', 'by Color', resetSearchHandler)
        },
        {
            title: 'IMEI 1',
            dataIndex: ['mobile_info', 'imei_1'],
            key: 'mobile_info.imei_1',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('mobile_info.imei_1', 'by IMEI 1', resetSearchHandler)
        },
        {
            title: 'IMEI 2',
            dataIndex: ['mobile_info', 'imei_2'],
            key: 'mobile_info.imei_2',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('mobile_info.imei_2', 'by IMEI 2', resetSearchHandler)
        },
        {
            title: 'Ram',
            dataIndex: ['mobile_info', 'ram'],
            key: 'mobile_info.ram',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('mobile_info.ram', 'by Ram', resetSearchHandler)
        },
        {
            title: 'Storage',
            dataIndex: ['mobile_info', 'storage'],
            key: 'mobile_info.storage',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('mobile_info.storage', 'by Storage', resetSearchHandler)
        },
        {
            title: 'Purchase Price',
            dataIndex: ['purchase_price'],
            key: 'purchase_price',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('purchase_price', 'by Purchase Price', resetSearchHandler)
        },
        {
            title: 'Approved Retail Price',
            dataIndex: ['procurement', 'suggested_retail_price_per_piece'],
            key: 'procurement.suggested_retail_price_per_piece',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.suggested_retail_price_per_piece', 'by Approved Retail Price', resetSearchHandler)
        },
        {
            title: 'Retail Price',
            dataIndex: ['retail_price'],
            key: 'retail_price',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('retail_price', 'by Retail Price', resetSearchHandler)
        },
        {
            title: 'Final Customer Price',
            dataIndex: ['final_customer_price'],
            key: 'final_customer_price',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('final_customer_price', 'by Final Customer Price', resetSearchHandler)
        },
        {
            title: 'Vendor Name',
            dataIndex: ['procurement', 'vendor', 'vendor_name'],
            key: 'procurement.vendor.vendor_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement.vendor.vendor_name', 'by Vendor Name', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        }
    ];

    return (
        <>
            {contextHolder}
            {
                isPayInstallmentModalOpened &&
                <PayInstallmentModalComponent
                    editMode={!!payInstallmentOrderId}
                    id={payInstallmentOrderId}
                    onClose={onModalCloseHandler}
                    onSubmit={onPayInstallmentModalCloseHandler}
                />
            }
            <Radio.Group
                onChange={({ target: { value } }) => {
                    setSelectionType(value);
                }}
                value={selectionType}
            >
                <Radio value="checkbox">Checkbox</Radio>
                <Radio value="radio">radio</Radio>
            </Radio.Group>

            <Divider />
            <Modal
                title={'Order Inventories'}
                open={true}
                onOk={() => onClose()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
                width={1100}
            >
                <Table
                    columns={columns}
                    dataSource={row}
                    onChange={handleChange}
                    rowKey={'id'}
                    size='large'
                    scroll={{ x: true }}
                />
            </Modal>
        </>
    );
};