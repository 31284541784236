import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Col, Form, Input, Layout, Row, Select, Steps } from 'antd';
import { FilterOperatorsEnum, IInventoryEntity, IItemsWithCount, InventoryStatusEnum } from '@rasayi-workspace/shared';
import { MapToSelectOption } from '@helpers';
import { BASE_QUERY_OPTIONS } from '@constants';
import { GetItems } from '@services';
import { BaseLayoutComponent, TagsDropdown } from '@components';
import { GuarantorInformationPageComponent } from '../guarantor-information';
import { orderContext } from 'src/app/contexts/order';
import { PlusOutlined } from '@ant-design/icons';
import { DeliveryInformationPageComponent } from '../delivery-information';

const { Content } = Layout;

export const ProductInformationPageComponent = () => {
    const [form] = Form.useForm<Partial<any>>();
    const { inventories, setInventories, incompleteOrder, orderDetails, initialInventories } = useContext(orderContext);
    const [rawInventories, setRawInventories] = useState<IInventoryEntity[]>([]);
    const [selectedInventory, setSelectedInventory] = useState<IInventoryEntity[]>([]);
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [isValidForm, setIsValidForm] = useState(true);


    const { refetch: fetchInventory, isFetching: isInventoryFetching } = useQuery<IItemsWithCount<IInventoryEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInventory'],
        queryFn: () => GetItems<IInventoryEntity>(
            'inventory',
            ['mobile_info', 'procurement.brand', 'procurement.product_type', 'procurement.vendor'],
            ['id', 'internal_id', 'purchase_price', 'status'],
            [
                [
                    {
                        field: 'status',
                        operator: FilterOperatorsEnum.EQUAL,
                        value: InventoryStatusEnum.UNSOLD
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IInventoryEntity>): void => setRawInventories([...returnedResult.items, ...initialInventories]),
        onError: () => setRawInventories([])
    });

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const refetechHandler = () => {
        fetchInventory();
    };

    const previousPageHandler = () => {
        setPreviousPage(true);
    };

    const handleFormChangeMain = () => {
        let validValues = selectedInventory?.length ? !selectedInventory.some((value: IInventoryEntity) => value.retail_price === undefined) : true;

        if (validValues && selectedInventory?.length) {
            const sumRetail = selectedInventory.reduce((sum, value: IInventoryEntity) => {
                if (value.retail_price)
                    return sum + value.retail_price;
                return sum;
            }, 0);
            if (sumRetail !== parseInt(String(orderDetails.total_retail_price)))
                validValues = false;
        }

        setIsValidForm(incompleteOrder ? validValues : (validValues && !!selectedInventory?.length));
    };


    useEffect(() => {
        fetchInventory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (rawInventories?.length && inventories?.length) {
            setSelectedInventory(inventories);
        } else {
            setSelectedInventory([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventories, rawInventories]);

    useEffect(() => {
        handleFormChangeMain();
    }, [selectedInventory]);

    return (
        <>
            {
                nextPage &&
                <GuarantorInformationPageComponent />
            }
            {
                previousPage &&
                <DeliveryInformationPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={2}
                        items={[
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Delivery information',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Product Information'
                        extra={<>
                            <Button
                                type='primary'
                                onClick={previousPageHandler}
                                style={{ marginLeft: 4 }}
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => setNextPage(true)}
                                style={{ marginLeft: 4 }}
                                disabled={!isValidForm}
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={{ height: 800, overflow: 'scroll' }}
                    >
                        <div className='flex justify-end'>
                            <Button
                                key='link'
                                type='primary'
                                onClick={refetechHandler}
                            >
                                Refetch Inventory
                            </Button>
                        </div>
                        <Form
                            form={form}
                        >
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='internal_id'
                                        label='Inventory Id'
                                        rules={[
                                            { required: !incompleteOrder, message: 'Please enter the inventory_ids' }
                                        ]}
                                        initialValue={inventories.map((inventory: IInventoryEntity) => inventory.internal_id)}
                                    >
                                        <Select
                                            mode='multiple'
                                            tagRender={TagsDropdown}
                                            onChange={(selectedItemIds: string[]) => {
                                                const selectedInventories = rawInventories.reduce((finalSelected: IInventoryEntity[], currentInventory: IInventoryEntity) => {
                                                    if (selectedItemIds.includes(currentInventory.internal_id)) {
                                                        finalSelected.push({
                                                            ...currentInventory,
                                                            retail_price: inventories?.find((innerInventory: IInventoryEntity) => innerInventory.internal_id === currentInventory.internal_id)?.retail_price || undefined
                                                        });
                                                        return finalSelected;
                                                    }
                                                    return finalSelected;
                                                }, []);
                                                setSelectedInventory(selectedInventories);
                                                setInventories(selectedInventories);
                                            }}
                                            showSearch
                                            className='w-full'
                                            options={MapToSelectOption(rawInventories, 'internal_id', 'internal_id')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='total_retail_price'
                                        label="Order's Total Retail Price"
                                        initialValue={orderDetails.total_retail_price}
                                    >
                                        <Input disabled value={orderDetails.total_retail_price} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <p className='d-flex text-center'>
                                <b>Note: </b>
                                Retail prices should be Equal to Order's Total Retail Price
                            </p>
                            {selectedInventory.map((inventoryItem, index) => (
                                <div key={index}>
                                    <Row justify={'center'} gutter={16}>
                                        <p style={{ fontSize: 20 }}> Product {index + 1}</p>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`retail_price_${inventoryItem.internal_id}`}
                                                label={`Enter Retail Price (${index + 1})`}
                                                id={inventoryItem.internal_id}
                                                required={!!selectedInventory.length}
                                                rules={[
                                                    { required: true, message: 'Please enter the retail_price_' }
                                                ]}
                                                initialValue={inventoryItem?.retail_price ? parseInt(String(inventoryItem?.retail_price)) : undefined}
                                            >
                                                <Input
                                                    value={inventoryItem?.retail_price ? parseInt(String(inventoryItem?.retail_price)) : undefined}
                                                    onChange={(event) => {
                                                        const tempInventory: IInventoryEntity[] = selectedInventory.reduce((finalInventory: IInventoryEntity[], currentInventory: IInventoryEntity) => {
                                                            if (currentInventory.internal_id === inventoryItem.internal_id) {
                                                                finalInventory.push({ ...currentInventory, retail_price: parseInt(event?.target?.value) || undefined });
                                                            } else
                                                                finalInventory.push(currentInventory);
                                                            return finalInventory;
                                                        }, []);
                                                        setSelectedInventory(tempInventory);
                                                        setInventories(tempInventory);
                                                    }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`total_purchase_price_${index}`}
                                                label={`Purchase Price (${index + 1})`}
                                                initialValue={inventoryItem?.purchase_price}
                                            >
                                                <Input disabled value={inventoryItem?.purchase_price} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`vendor_name_${index}`}
                                                label={`Vendor Name (${index + 1})`}
                                                initialValue={inventoryItem?.procurement?.vendor?.vendor_name}
                                            >
                                                <Input disabled
                                                    value={inventoryItem?.procurement?.vendor?.vendor_name}
                                                    onChange={(event) => updateFormFields('vendor.vendor_name', event?.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`product_type_${index}`}
                                                label={`Product Type (${index + 1})`}
                                                initialValue={inventoryItem?.procurement?.product_type?.name}
                                            >
                                                <Input disabled value={inventoryItem?.procurement?.product_type?.name}
                                                    onChange={(event) => updateFormFields('product_type.name', event?.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`brand_name_${index}`}
                                                label={`Brand Name (${index + 1})`}
                                                initialValue={inventoryItem?.procurement?.brand?.name}
                                            >
                                                <Input disabled value={inventoryItem?.procurement?.brand?.name}
                                                    onChange={(event) => updateFormFields('brand.name', event?.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`model_${index}`}
                                                label={`Model (${index + 1})`}
                                                initialValue={inventoryItem?.procurement?.model}
                                            >
                                                <Input disabled value={inventoryItem?.procurement?.model} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`mobile_info.imei_1_${index}`}
                                                label={`imei_1 (${index + 1})`}
                                                initialValue={inventoryItem?.mobile_info?.imei_1}
                                            >
                                                <Input disabled value={inventoryItem?.mobile_info?.imei_1} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`mobile_info.imei_2_${index}`}
                                                label={`imei_2 (${index + 1})`}
                                                initialValue={inventoryItem?.mobile_info?.imei_2}
                                            >
                                                <Input disabled value={inventoryItem?.mobile_info?.imei_2} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`locking_identifier_${index}`}
                                                label={`Locking Identifier (${index + 1})`}
                                                rules={[
                                                    { required: true, message: 'Please enter the locking_identifier' },
                                                    { min: 2, message: 'At least 2 characters' }
                                                ]}
                                                initialValue={inventoryItem?.mobile_info?.locking_identifier}
                                            >
                                                <Input disabled value={inventoryItem?.mobile_info?.locking_identifier}
                                                    onChange={(event) => updateFormFields('locking_identifier', event?.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                key={`serial_number${index}`}
                                                label={`Serial Number (${index + 1})`}
                                                rules={[
                                                    { required: true, message: 'Please enter the locking_identifier' },
                                                    // { min: 2, message: 'At least 2 characters' }
                                                ]}
                                                initialValue={inventoryItem?.serial_number}
                                            >
                                                <Input disabled value={inventoryItem?.serial_number}
                                                    onChange={(event) => updateFormFields('serial_number', event?.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};