import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IItemsWithCount, IRoleEntity, IGroupDTO, IGroupEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { TagsDropdown } from '@components';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const GroupModalComponent = ({ onClose, onSubmit, editMode = false, id: groupId = '' }: AddEditModalProps<IGroupEntity>) => {
    const [form] = Form.useForm<Partial<IGroupDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [group, setGroup] = useState<Partial<IGroupEntity>>();
    const [roles, setRoles] = useState<IRoleEntity[]>([]);

    const { mutate: createGroup, isLoading } = useMutation<IGroupEntity, AxiosError>({
        mutationKey: ['createGroup'],
        mutationFn: async () =>
            PostItem<IGroupEntity, IGroupDTO>(
                'group',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IGroupDTO>
            ),
        onSuccess: async (item: IGroupEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateGroup, isLoading: isGroupUpdating } = useMutation<IGroupEntity, AxiosError>({
        mutationKey: ['updateGroup'],
        mutationFn: async () =>
            PutItem<IGroupEntity, IGroupDTO>(
                'group',
                groupId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IGroupDTO>
            ),
        onSuccess: async (item: IGroupEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchGroup, isFetching: isGroupFetching } = useQuery<IGroupEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getGroup'],
        queryFn: () => GetItem<IGroupEntity>(
            'group',
            groupId || '',
            ['roles'],
            ['roles.id', 'roles.name']
        ),
        onSuccess: (result: IGroupEntity): void => {
            setGroup(group);
            form.setFieldsValue({
                ...result,
                role_ids: result.roles?.map(({ id }) => id)
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: error.response?.status && ENGLISH.errors[error.response.status]('Could not find groups data!') || ''
        })
    });

    const { refetch: fetchRoles, isFetching: isRolesFetching } = useQuery<IItemsWithCount<IRoleEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getRoles'],
        queryFn: () => GetItems<IRoleEntity>(
            'role',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IRoleEntity>): void => setRoles(returnedResult.items),
        onError: () => setRoles([])
    });

    const updateFormFields = (property: string, value: string) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createGroup())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateGroup())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode)
            fetchGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Group' : 'Edit Group'}
                open={true}
                confirmLoading={isLoading || isRolesFetching || isGroupFetching || isGroupUpdating}
                okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Group' : 'Edit Group'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{maxHeight: 550, overflow: 'auto'}}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='name'
                        label='Group name'
                        rules={[
                            { required: true, message: 'Please enter the group name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='role_ids'
                        label='Roles'
                        rules={[{ required: false }]}
                    >
                        <Select
                            mode='multiple'
                            tagRender={TagsDropdown}
                            onChange={(selectedItemIds: string) => updateFormFields('role_ids', selectedItemIds)}
                            showSearch={false}
                            className='w-full'
                            options={MapToSelectOption(roles, 'name', 'id')}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
