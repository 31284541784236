import { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getEnumeratorKeys, IInventoryEntity, IItemsWithCount, IMobileInfoDTO, IMobileInfoEntity, LockStatusEnum, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const MobileInfoModalComponent = ({ onClose, onSubmit, editMode = false, id: mobileInfoId = '' }: AddEditModalProps<IMobileInfoEntity>) => {
    const [form] = Form.useForm<Partial<IMobileInfoDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [mobileInfo, setMobileInfo] = useState<Partial<IMobileInfoEntity>>();
    const [inventory, setInventorys] = useState<IInventoryEntity[]>([]);


    const { mutate: createMobileInfo, isLoading } = useMutation<IMobileInfoEntity, AxiosError>({
        mutationKey: ['createMobileInfo'],
        mutationFn: async () =>
            PostItem<IMobileInfoEntity, IMobileInfoDTO>(
                'mobile_info',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IMobileInfoDTO>
            ),
        onSuccess: async (item: IMobileInfoEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateMobileInfo, isLoading: isMobileInfoUpdating } = useMutation<IMobileInfoEntity, AxiosError>({
        mutationKey: ['updateMobileInfo'],
        mutationFn: async () =>
            PutItem<IMobileInfoEntity, IMobileInfoDTO>(
                'mobile_info',
                mobileInfoId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IMobileInfoDTO>
            ),
        onSuccess: async (item: IMobileInfoEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchMobileInfo, isFetching: isMobileInfoFetching } = useQuery<IMobileInfoEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getMobileInfo'],
        queryFn: () => GetItem<IMobileInfoEntity>(
            'mobile_info',
            mobileInfoId || '',
            ['inventory.procurement'],
        ),
        onSuccess: (result: IMobileInfoEntity): void => {
            setMobileInfo(mobileInfo);
            form.setFieldsValue({
                ...result,
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchInventory } = useQuery<IItemsWithCount<IInventoryEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInventorys'],
        queryFn: () => GetItems<IInventoryEntity>(
            'inventory',
            [],
            ['id']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IInventoryEntity>): void => setInventorys(returnedResult.items),
        onError: () => setInventorys([])
    });

    const updateFormFields = (property: string, value: string | number | undefined) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createMobileInfo())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateMobileInfo())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchInventory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode)
            fetchMobileInfo();
        fetchInventory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const optionsIsVerified = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={ !editMode ? 'Add Mobile Info' : 'Edit Mobile Info' }
                open={true}
                confirmLoading={isLoading || isMobileInfoFetching || isMobileInfoUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={ !editMode ? 'Add Mobile Info' : 'Edit Mobile Info' }
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name={['inventory', 'internal_id']}
                        label='inventory id'
                        rules={[{ required: false }]}
                    >
                        <Select
                        disabled
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(inventory, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='locking_identifier'
                        label='locking_identifier'
                        rules={[
                            { required: true, message: 'Please enter the locking_identifier' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('locking_identifier', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='lockable'
                        label='lockable'
                        rules={[{ required: false }]}>
                        <Select>
                            { optionsIsVerified.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='lock_status'
                        label='lock_status'
                        rules={[
                            { required: true, message: 'Please enter the customer status' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('customer_status', value)}>
                            {
                                getEnumeratorKeys(LockStatusEnum).map(
                                    (key) => <Select.Option key={key} value={LockStatusEnum[key as keyof typeof LockStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                         name={['inventory', 'procurement', 'color']}
                        label='color'
                        rules={[
                            { required: true, message: 'Please enter the color' }
                        ]}
                    >
                        <Input disabled onChange={(event) => updateFormFields('color', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='imei_1'
                        label='imei_1'
                        rules={[
                            { required: true, message: 'Please enter the imei_1' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('imei_1', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='imei_2'
                        label='imei_2'
                        rules={[
                            { required: false, message: 'Please enter the imei_2' }
                        ]}
                    >
                        <Input
                            onChange={ (event) => updateFormFields('imei_2', event?.target?.value || undefined) }/>
                    </Form.Item>
                    <Form.Item
                        name='ram'
                        label='ram'
                        rules={[
                            { required: true, message: 'Please enter the ram' }
                        ]}
                    >
                        <Input
                            onChange={ (event) => updateFormFields('ram', parseInt(event?.target?.value) || undefined) }/>
                    </Form.Item>
                    <Form.Item
                        name='storage'
                        label='storage'
                        rules={[
                            { required: true, message: 'Please enter the storage' }
                        ]}
                    >
                        <Input
                            onChange={ (event) => updateFormFields('storage', parseInt(event?.target?.value) || undefined) }/>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
};