import { Modal } from 'antd';
import { IActivityLogs, KeyValueRecord } from '@rasayi-workspace/shared';

interface ViewActivityLogsProps {
    editMode: boolean;
    id: string;
    activityLog: IActivityLogs | null;
    onClose: () => void;
}

const ViewActivityLogs = ({ editMode, id, activityLog, onClose }: ViewActivityLogsProps) => {

    const renderApiBody = (apiBody: KeyValueRecord | string) => {
        if (typeof apiBody === 'string') {
            return <p>{apiBody}</p>;
        }
        if (typeof apiBody === 'object' && apiBody !== null) {
            return (
                <ul>
                    {Object.entries(apiBody).map(([key, value]) => (
                        <li key={key}>
                            <strong>{key}:</strong> {JSON.stringify(value)}
                        </li>
                    ))}
                </ul>
            );
        }
        return null;
    };

    return (
        <Modal
            open={true}
            title={'View Activity Log'}
            onCancel={onClose}
            onOk={onClose}
        >
            {
                activityLog && (
                    <div>
                        <div><strong>POC:</strong> {activityLog?.user && activityLog?.user?.first_name}</div>
                        <div><strong>API Path:</strong> {activityLog.api_path}</div>
                        <div><strong>API Status:</strong> {activityLog.api_status}</div>
                        <div><strong>API Param:</strong> {activityLog.api_param}</div>
                        <div><strong>API Body:</strong> {renderApiBody(activityLog.api_body)}</div>
                    </div>
                )
            }
        </Modal>
    );
};

export default ViewActivityLogs;
