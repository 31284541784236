import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { KeyValueRecord, OrderStatusEnum } from '@rasayi-workspace/shared';

interface LabelProps {
  className?: string;
  status?: OrderStatusEnum;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info' | 'danger' | 'accent';

  children?: ReactNode;
}

const themeColors: KeyValueRecord = {
  primary: '#D9D9D9',
  secondary: '#9FC5E8',
  success: '#B6D7A8',
  warning: '#FFE599',
  error: '#666666',
  info: '#F1C232',
  danger: '#E06666',
  accent: '#EA9999',
};

const ORDER_COLOR_CONFIG: Record<OrderStatusEnum, string> = {
  [OrderStatusEnum.PENDING]: 'primary',
  [OrderStatusEnum.INCOMPLETE]: 'warning',
  [OrderStatusEnum.ACTIVE]: 'secondary',
  [OrderStatusEnum.OVERDUE]: 'accent',
  [OrderStatusEnum.DEFAULT]: 'danger',
  [OrderStatusEnum.BLACK_LISTED]: 'error',
  [OrderStatusEnum.COMPLETE]: 'success',
  [OrderStatusEnum.REFUND]: 'info'
};

const Label: FC<LabelProps> = ({
  className = '',
  color = 'secondary',
  children,
  status = OrderStatusEnum.DEFAULT,
}) => {
  const tagColor = themeColors[ORDER_COLOR_CONFIG[status]] || themeColors[color];

  return (
    <Tag
      className={`${className}`}
      color={tagColor}
    >
      {children}
    </Tag>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info']),
};

export default Label;
