import React, { useEffect, useState } from 'react';
import { Modal, message, Button } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IItemsWithCount, IDocumentEntity, KeyValueRecord, DocumentTypeSearch } from '@rasayi-workspace/shared';
import { GetItems, } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps } from '@interfaces';
import { Image } from 'antd';
import { CloudDownloadOutlined, FileOutlined } from '@ant-design/icons';
import { handleDownloadImage } from '@helpers';

export const ViewDocumentModalComponent = ({ onClose, onSubmit, editMode = false, id: instalmentId = '' }: AddEditModalProps<IDocumentEntity>) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [paymentProofDocument, setPaymentProofDocument] = useState<any>([]);

    const { refetch: paymentProofDocumentsOne, isFetching: isDocumentsFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getPaymentProofDocument'],
        queryFn: () => {
            const queryParams = new URLSearchParams({
                instalmentId: instalmentId
            });

            return GetItems<KeyValueRecord>('transaction/instalment?' + queryParams.toString());
        },
        onSuccess: (returnedResult: IItemsWithCount<KeyValueRecord>): void => setPaymentProofDocument(returnedResult),
        onError: () => setPaymentProofDocument([]),
    });

    useEffect(() => {
        !!instalmentId && !isDocumentsFetching && paymentProofDocumentsOne();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Customer' : 'View Documents'}
                open={true}
                confirmLoading={isDocumentsFetching}
                onOk={() => onClose()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <h2>
                    Documents
                </h2>
                {
                    paymentProofDocument &&
                    paymentProofDocument?.map((image: KeyValueRecord, index: number) => {
                        return <div className='flex-container'
                            style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                            {!image?.isPdf ?
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.payment_proof?.includes(el))?.toUpperCase()}
                                    </h4>
                                    <Image
                                        key={index}
                                        width={400}
                                        src={image?.fileContent}
                                    />
                                </div>
                                :
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.payment_proof?.includes(el))?.toUpperCase()}
                                    </h4>
                                    <div className='w-[400px] flex justify-center'><FileOutlined /></div>
                                </div>
                            }
                            <div>
                                <Button
                                    className=' '
                                    type='primary'
                                    icon={<CloudDownloadOutlined />}
                                    style={{ marginLeft: 10, marginBottom: 5 }}
                                    onClick={() => handleDownloadImage(image)}
                                />
                            </div>
                        </div>;
                    })
                }
            </Modal>
        </>
    );
};