import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import { useContext } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { UserContext } from '@contexts';
import { SignOut } from '@services';
import { VERSION_APP } from '@rasayi-workspace/shared';

const menuItems: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <a href={'/'} onClick={() => SignOut()}>
        Sign out
      </a>
    ),
  },
];

export const HeaderComponent = () => {
  const { user } = useContext(UserContext);

  return (
    <header className="bg-white">
      <nav className="mx-auto flex items-center justify-between pl-4 pr-2">
        <div className="items-center flex-row">
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img
              src="../../../../assets/rasayi-logo.png"
              className="w-24"
              alt=""
            />
          </a>
          <div className="mt-2 bg-rose-700 text-base w-20" style={{borderRadius: 4}}>
            <Tooltip title={`Version: ${VERSION_APP}`} placement="right">
              <span className=" text-white text-center inline-block text-base w-20">
                {VERSION_APP}
              </span>
            </Tooltip>
          </div>
          {/* <h4 className='p-0 m-0 ml-2 text-lg'>KalPay Rasayi</h4> */}
        </div>
        <div className=" flex flex-1 justify-end">
          <Dropdown menu={{ items: menuItems }} trigger={['click']}>
            <Button type="text" size="large" className="!h-16">
              <div className="capitalize text-gray-500 font-bold">
                {user?.first_name + ' ' + user?.last_name || ''}
                <LogoutOutlined className="ml-2" />
              </div>
              <div className="text-xs">{user.email}</div>
            </Button>
          </Dropdown>
        </div>
      </nav>
    </header>
  );
};
