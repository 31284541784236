import { useEffect, useMemo, useState } from 'react';
import { Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getEnumeratorKeys, ICategoryEntity, IItemsWithCount, IVendorDTO, IVendorEntity, IVendorRegistrationTypeEntity, ProvinceEnumerator, regexCollection, removeEmptyValues, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS, PROVINCES_AND_CITIES } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const VendorModalComponent = ({ onClose, onSubmit, editMode = false, id: vendorId = '' }: AddEditModalProps<IVendorEntity>) => {
    const [form] = Form.useForm<Partial<IVendorDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [categories, setCategories] = useState<ICategoryEntity[]>([]);
    const [vendor, setVendor] = useState<IVendorEntity[]>([]);
    const [vendorRegistrationType, setVendorRegistrationType] = useState<IVendorRegistrationTypeEntity[]>([]);

    const { mutate: createVendor, isLoading } = useMutation<IVendorEntity, AxiosError>({
        mutationKey: ['createVendor'],
        mutationFn: async () =>
            PostItem<IVendorEntity, IVendorDTO>(
                'vendor',
                removeWhiteSpaceFromAllProperties(removeEmptyValues(form.getFieldsValue())) as Partial<IVendorDTO>
            ),
        onSuccess: async (item: IVendorEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateVendor, isLoading: isVendorUpdating } = useMutation<IVendorEntity, AxiosError>({
        mutationKey: ['updateVendor'],
        mutationFn: async () =>
            PutItem<IVendorEntity, IVendorDTO>(
                'vendor',
                vendorId,
                removeWhiteSpaceFromAllProperties(removeEmptyValues(form.getFieldsValue())) as Partial<IVendorDTO>
            ),
        onSuccess: async (item: IVendorEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchVendorRegistrationTypes, isFetching: isVendorRegistrationTypesFetching } = useQuery<IItemsWithCount<IVendorRegistrationTypeEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getVendorRegistrationTypes'],
        queryFn: () => GetItems<IVendorRegistrationTypeEntity>(
            'vendor_registration_type',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IVendorRegistrationTypeEntity>): void => setVendorRegistrationType(returnedResult.items),
        onError: () => setVendorRegistrationType([])
    });

    const { refetch: fetchVendor, isFetching: isVendorFetching } = useQuery<IVendorEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getVendor'],
        queryFn: () => GetItem<IVendorEntity>(
            'vendor',
            vendorId || ''
        ),
        onSuccess: (result: IVendorEntity): void => {
            setVendor(vendor);
            form.setFieldsValue({
                ...result,
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchCategories } = useQuery<IItemsWithCount<ICategoryEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getCategories'],
        queryFn: () => GetItems<ICategoryEntity>(
            'category',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<ICategoryEntity>): void => setCategories(returnedResult.items),
        onError: () => setCategories([])
    });

    const updateFormFields = (property: string, value: string | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        const categories_ids = form.getFieldValue('categories_ids');

        console.log(categories_ids, 'categories_ids')

        setIsValidForm(fieldsTouched && !hasErrors && categories_ids?.length > 0);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createVendor())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateVendor())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchVendorRegistrationTypes();
        fetchCategories();
        fetchVendor()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cities = useMemo(
        () => Object
            .values(PROVINCES_AND_CITIES)
            .reduce(
                (aggregator, values) => ([...aggregator, ...values]), []
            )
            .sort(),
        []
    );

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Vendor' : 'Edit Vendor'}
                open={true}
                confirmLoading={isLoading || isVendorRegistrationTypesFetching || isVendorUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Vendor' : 'Edit Vendor'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='first_name'
                        label='First Name'
                        rules={[
                            { required: true, message: 'Please enter the first name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('first_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='last_name'
                        label='Last Name'
                        rules={[
                            { required: true, message: 'Please enter the last name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='mobile'
                        label='mobile'
                        rules={[
                            {
                                required: true, message: 'Please enter correct mobile like 03xxxxxxxxx',
                                pattern: regexCollection.phone
                            }
                        ]}
                    >
                        <Input
                            maxLength={11}
                            minLength={11}
                            onChange={(event) => updateFormFields('mobile', event?.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name='business_mobile'
                        label='business_mobile'
                        rules={[
                            {
                                required: false, message: 'Please enter correct mobile like 03xxxxxxxxx',
                                pattern: regexCollection.phone
                            }
                        ]}
                    >
                        <Input
                            maxLength={11}
                            minLength={11}
                            onChange={(event) => updateFormFields('business_mobile', event?.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name='email'
                        label='email'
                        rules={[
                            { required: false, message: 'Please enter the email' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('email', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='business_email'
                        label='business_email'
                        rules={[
                            { required: false, message: 'Please enter the business_email' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('business_email', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='position_company'
                        label='position_company'
                        rules={[
                            { required: true, message: 'Please enter the position_company' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('position_company', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='url'
                        label='url'
                    >
                        <Input onChange={(event) => updateFormFields('url', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='vendor_name'
                        label='vendor_name'
                        rules={[
                            { required: true, message: 'Please enter the vendor_name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('vendor_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item name='active'
                        label='Is active'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='vendor_market'
                        label='vendor_market'
                        rules={[
                            { required: true, message: 'Please enter the vendor_market' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('vendor_market', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='address_line_1'
                        label='address_line_1'
                    >
                        <Input onChange={(event) => updateFormFields('address_line_1', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='address_line_2'
                        label='address_line_2'
                    >
                        <Input onChange={(event) => updateFormFields('address_line_2', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='city'
                        label='City'
                        rules={[
                            { required: true, message: 'Please enter the city' }
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {cities.map((o, i) => (
                                <Select.Option key={`option-${i}`} value={o}>
                                    {o}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='province'
                        label='Province'
                        rules={[
                            { required: true, message: 'Please enter the province' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('province', value)}>
                            {
                                getEnumeratorKeys(ProvinceEnumerator).map(
                                    (key) => <Select.Option key={key}
                                        value={ProvinceEnumerator[key as keyof typeof ProvinceEnumerator]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='postal_code'
                        label='Postal Code'
                        rules={[
                            { required: false, message: 'Please enter the postal code' }
                        ]}
                    >
                        <Input
                            maxLength={5}
                            onChange={(event) => updateFormFields('postal_code', event?.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};