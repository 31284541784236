import React from 'react';
import { BaseLayoutComponent } from '../../shared';
import { Content } from 'antd/es/layout/layout';
import { Card, Row, Col, Spin } from 'antd';
import { BASE_QUERY_OPTIONS } from '@constants';
import { GetItems } from '@services';
import { useQuery } from '@tanstack/react-query';
import { KeyValueRecord } from '../../../../../../../libs/shared/src/types';
import StatisticsComponent from './date-filter-data';

interface DashboardData {
    [key: string]: {
        [status: string]: number;
    };
}

const DashBoard: React.FC = () => {
    const fetchDashboardData = async (): Promise<KeyValueRecord> => {
        const data = await GetItems<DashboardData>('/order/my_stats');
        return data;
    };

    const { data, error, isFetching } = useQuery<DashboardData, Error>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['dashboard'],
        queryFn: fetchDashboardData,
        enabled: false,
        onError: (error: Error) => {
            console.error('Error fetching API data', error);
        },
    });

    const renderCards = () => {
        if (!data) return null;

        return Object.keys(data).map((key) => {
            if (key === 'orderStatus') return null;

            return (
                <Col xs={24} sm={12} md={8} key={key}>
                    <Card title={key}>
                        {Object.entries(data[key]).map(([status, count]) => (
                            <div key={status}>
                                {status}: {count}
                            </div>
                        ))}
                    </Card>
                </Col>
            );
        });
    };

    return (
        <BaseLayoutComponent>
            <Content className="m-5 bg-white border rounded-lg">
                <Card title="Dashboard">
                    <StatisticsComponent />
                    {isFetching ? (
                        <div className="spinner-container">
                            <Spin size="large" />
                        </div>
                    ) : error ? (
                        <div>Error loading data: {error.message}</div>
                    ) : (
                        <Row gutter={[22, 22]}>
                            {renderCards()}
                        </Row>
                    )}
                </Card>
            </Content>
        </BaseLayoutComponent>
    );
};

export default DashBoard;
