import { ISignedToken, ISignInResponse, IUserEntity, KeyValueRecord } from '@rasayi-workspace/shared';
import jwt from 'jwt-decode';
import { ResetPermssions } from './authorization';

export const IsValidToken = (): boolean => {
    const token: string | null = GetToken();

    if (!token)
        return false;

    const { exp } = jwt(token) as ISignedToken;

    if (exp && (Date.now() < (exp * 1000)))
        return true;

    SignOut();

    return false;
}

export const SetUserDetails = ({ id, email, roles }: Partial<IUserEntity>): void => {
    localStorage.setItem('rasayi-auth-user', JSON.stringify({ id, email, roles }));
}

export const GetUserDetails = (): Partial<IUserEntity> => {
    return localStorage.getItem('rasayi-auth-user') && JSON.parse(localStorage.getItem('rasayi-auth-user') as string) || null;
}

export const SetToken = (token: string, refreshToken: string): void => {
    localStorage.setItem('rasayi-auth-access-token', token);
    localStorage.setItem('rasayi-auth-refresh-token', refreshToken);
}

export const GetToken = (): string | null => {
    return localStorage.getItem('rasayi-auth-access-token');
}

/**
 * Parses the jwt token to get its encoded data
 * @param {string} token token string
 * @returns 
 */
const ParseJwt = (token: string): KeyValueRecord => {
    const base64Url: string = token.split('.')[1];
    const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload: string = decodeURIComponent(
        window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')
    );

    return JSON.parse(jsonPayload);
}

export const GetTokenDetails = (): IUserEntity | undefined => {
    return GetToken() ? ParseJwt(GetToken() as string) as IUserEntity : undefined;
}

export const SaveSignInDetails = ({ access_token, refresh_token, user }: ISignInResponse): void => {
    SetToken(access_token, refresh_token);
    SetUserDetails(user);
}

export const SignOut = (): void => {
    ResetPermssions();
    localStorage.removeItem('rasayi-auth-user');
    localStorage.removeItem('rasayi-auth-access-token');
    localStorage.removeItem('rasayi-auth-refresh-token');
    // eslint-disable-next-line no-restricted-globals
    window.location.reload();
}

export const IsAuthenticated = (): boolean => !!(GetToken() && IsValidToken());