import React from 'react';
import { Modal, InputNumber, Form } from 'antd';

interface EditPaidAmountModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (amount: number) => void;
    initialValue?: number;
}

const EditPaidAmountModal: React.FC<EditPaidAmountModalProps> = ({ visible, onClose, onSubmit, initialValue }) => {
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title="Edit Paid Amount"
            onCancel={onClose}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onSubmit(values.amount);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="vertical" initialValues={{ amount: initialValue }}>
                <Form.Item
                    name="amount"
                    label="Paid Amount"
                    rules={[{ required: true, message: 'Please input the amount!' }]}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditPaidAmountModal;
