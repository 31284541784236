import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Layout, Row, Steps } from 'antd';
import { FilterOperatorsEnum, IUserEntity, UserRolesEnum } from '@rasayi-workspace/shared';
import { BaseLayoutComponent, SearchableDropdown } from '@components';
import { orderContext } from '@contexts';
import { OrderDetailPageComponent } from '../order-details';

const { Content } = Layout;

export const CreateOrderPageComponent = () => {
    const { user, setUser, lastAccess, setLastAccess, setInitialInventories, setOrderDetails, setInventories, setGuarantorId, setUserId } = useContext(orderContext);
    const [form] = Form.useForm<Partial<any>>();
    const [nextPage, setNextPage] = useState(false);

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const nextPageHandler = () => {
        setNextPage(true);
    };

    useEffect(() => {
        if (user?.id)
            form.setFieldsValue({ ...user });
        if (lastAccess !== 'createOrder') {
            setInitialInventories([]);
            setOrderDetails({});
            setInventories([]);
            setGuarantorId('');
            setUserId('');
            setUser({});
            setLastAccess('createOrder');
        }
    }, []);

    return (
        <>
            {
                nextPage &&
                <OrderDetailPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={0}
                        items={[
                            {
                                title: 'Customer Information',
                            },
                            {
                                title: 'Order Details',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Delivery Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Customer Information'
                        extra={<>
                            <Button
                                key='previousPage'
                                type='primary'
                                style={{ marginLeft: 4 }}
                                disabled
                            >
                                Previous Page
                            </Button>
                            <Button
                                key='nextPage'
                                type='primary'
                                onClick={nextPageHandler}
                                style={{ marginLeft: 4 }}
                                disabled={ !(user && user?.id) }
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            form={form}
                        >
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Customer'
                                        rules={ [
                                            { required: true, message: 'Please enter the internal_id' }
                                        ] }
                                    >
                                        {
                                            SearchableDropdown<IUserEntity>(
                                                {
                                                    key: 'id',
                                                    defaultValue: user?.cnic_number || '',
                                                    sortBy: { 'cnic_number': 'ASC' },
                                                    value: 'cnic_number',
                                                    queryKey: ['getCustomersOrder'],
                                                    queryFnParams: {
                                                        apiRoute: 'user',
                                                        relations: [],
                                                        fields: ['id', 'first_name', 'cnic_number', 'last_name', 'primary_mobile', 'address_line1', 'city', 'email'],
                                                        filter: {
                                                            field: 'cnic_number',
                                                            operator: FilterOperatorsEnum.LIKE,
                                                            value: '', 
                                                        },
                                                        predefinedFilters: [
                                                            {
                                                                field: 'roles.name',
                                                                operator: FilterOperatorsEnum.EQUAL,
                                                                value: UserRolesEnum.CUSTOMER
                                                            },
                                                            {
                                                                field: 'is_active',
                                                                operator: FilterOperatorsEnum.EQUAL,
                                                                value: 'true',
                                                            },
                                                            {
                                                                field: 'address_line1',
                                                                operator: FilterOperatorsEnum.NOT_NULL,
                                                                value: '',
                                                            },
                                                            {
                                                                field: 'city',
                                                                operator: FilterOperatorsEnum.NOT_NULL,
                                                                value: '',
                                                            },
                                                            {
                                                                field: 'province',
                                                                operator: FilterOperatorsEnum.NOT_NULL,
                                                                value: '',
                                                            },
                                                            {
                                                                field: 'cnic_number',
                                                                operator: FilterOperatorsEnum.NOT_NULL,
                                                                value: '',
                                                            },
                                                        ]
                                                    },
                                                    onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                                        const selectedOption: IUserEntity | undefined = items.find((user: IUserEntity) => user.id === selectedItemIds);

                                                        if (selectedOption) {
                                                            form.setFieldsValue({ ...selectedOption });
                                                            setUser(selectedOption);
                                                        }
                                                    }
                                                }
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            { (user && user?.id) ? <>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='first_name'
                                            label='First Name'
                                            rules={[
                                                { required: true, message: 'Please enter the first name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={ user?.first_name }
                                        >
                                            <Input disabled value={ user?.first_name }/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='last_name'
                                            label='Last Name'
                                            rules={[
                                                { required: true, message: 'Please enter the last name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={ user?.last_name }
                                        >
                                            <Input disabled value={ user?.last_name }
                                                onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='cnic_number'
                                            label='Cnic Number'
                                            rules={[
                                                { required: true, message: 'Please enter the Cnic Number' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={ user?.cnic_number }
                                        >
                                            <Input disabled value={ user?.cnic_number }
                                                onChange={(event) => updateFormFields('cnic_number', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='primary_mobile'
                                            label='Primary Mobile'
                                            rules={[
                                                { required: true, message: 'Please enter the Primary Mobile' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={ user?.primary_mobile }
                                        >
                                            <Input disabled value={ user?.primary_mobile }
                                                onChange={(event) => updateFormFields('primary_mobile', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='city'
                                            label='City'
                                            rules={[
                                                { required: true, message: 'Please enter the city' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={ user?.city }
                                        >
                                            <Input disabled value={ user?.city }
                                                onChange={(event) => updateFormFields('city', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='address_line1'
                                            label='Address 1'
                                            rules={[
                                                { required: true, message: 'Please enter the address_line1' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={ user?.address_line1 }
                                        >
                                            <Input disabled value={ user?.address_line1 }
                                                onChange={(event) => updateFormFields('address_line1', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> : ''}
                            {/* Add more rows with Col and Form.Item as needed */}
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};