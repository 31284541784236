import { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Layout, Button, Form, Col, Input, Row, Steps, Select, Tag, ConfigProvider, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BaseLayoutComponent } from '@components';
import { orderContext } from '@contexts';
import { ProductInformationPageComponent } from '../product-information';
import { isUndefined } from '@nestjs/common/utils/shared.utils';
import { GuarantorInformationPageComponent } from '../guarantor-information';
import { PROVINCES_AND_CITIES } from '@constants';
import { ProvinceEnumerator, getEnumeratorKeys } from '@rasayi-workspace/shared';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

const { Content } = Layout;

export const DeliveryInformationPageComponent = () => {
    const [form] = Form.useForm<Partial<any>>();
    const { setOrderDetails, orderDetails, inventories } = useContext(orderContext);
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);

    const updateFormFields = (property: string | number | any, value: string | number | any) => {
        form.setFieldsValue({ [property]: value });
    };

    const previousPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue() });
        setPreviousPage(true);
    };

    const nextPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue() });
        setNextPage(true);
    };

    const handleFormChange = () => {
        const formValues = form.getFieldsValue();
        const requiredFields = ['delivery_address_line_1', 'delivery_city', 'delivery_province', 'delivery_pin'];
        const isValidForm = requiredFields.every(field => !!formValues[field]);
        setIsValidForm(isValidForm);
    };

    const cities = useMemo(
        () => Object
            .values(PROVINCES_AND_CITIES)
            .reduce(
                (aggregator, values) => ([...aggregator, ...values]), []
            )
            .sort(),
        []
    );

    useEffect(() => {
        handleFormChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                nextPage &&
                <GuarantorInformationPageComponent />
            }
            {
                previousPage &&
                <ProductInformationPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={3}
                        items={[
                            {
                                title: 'Customer Information',
                            },
                            {
                                title: 'Order Details',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Delivery Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Delivery Information'
                        extra={<>
                            <Button
                                key='previousPage'
                                type='primary'
                                onClick={previousPageHandler}
                                style={{ marginLeft: 4 }}
                            >
                                Previous Page
                            </Button>
                            <Button
                                key='nextPage'
                                type='primary'
                                style={{ marginLeft: 4 }}
                                onClick={nextPageHandler}
                                disabled={!(isValidForm || !inventories.length)}
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            form={form}
                            onValuesChange={handleFormChange}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_address_line_1'
                                        label='delivery_address_line_1'
                                        rules={[
                                            { required: !!inventories.length, message: 'Please enter the delivery_address_line_1', }
                                        ]}
                                        initialValue={orderDetails?.delivery_address_line_1 || undefined}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_address_line_1', event?.target.value || undefined)} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_address_line_2'
                                        label='delivery_address_line_2'
                                        rules={[
                                            { required: false, message: 'Please enter the delivery_address_line_2', }
                                        ]}
                                        initialValue={orderDetails?.delivery_address_line_2 || undefined}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_address_line_2', event?.target.value || undefined)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {/* <Form.Item
                                        name='delivery_city'
                                        label='delivery_city'
                                        rules={[
                                            { required: !!inventories.length, message: 'Please enter the delivery_city', }
                                        ]}
                                        initialValue={orderDetails?.delivery_city || undefined}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_city', event?.target.value || undefined)} />
                                    </Form.Item> */}
                                    <Form.Item
                                        name='delivery_city'
                                        label='delivery_city'
                                        rules={[
                                            { required: true, message: 'Please enter the city' }
                                        ]}
                                        initialValue={orderDetails?.delivery_city || undefined}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {cities.map((o, i) => (
                                                <Select.Option key={`option-${i}`} value={o}>
                                                    {o}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    {/* <Form.Item
                                        name='delivery_province'
                                        label='delivery_province'
                                        rules={[
                                            { required: !!inventories.length, message: 'Please enter the delivery_province', }
                                        ]}
                                        initialValue={orderDetails?.delivery_province || undefined}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_province', event?.target.value || undefined)} />
                                    </Form.Item> */}
                                    <Form.Item
                                        name='delivery_province'
                                        label='delivery_province'
                                        rules={[
                                            { required: true, message: 'Please enter the province' }
                                        ]}
                                        initialValue={orderDetails?.delivery_province || undefined}
                                    >
                                        <Select onChange={(value) => updateFormFields('province', value)}>
                                            {
                                                getEnumeratorKeys(ProvinceEnumerator).map(
                                                    (key) => <Select.Option key={key}
                                                        value={ProvinceEnumerator[key as keyof typeof ProvinceEnumerator]}>
                                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                                    </Select.Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_postal_code'
                                        label='delivery_postal_code'
                                        rules={[
                                            { required: false, message: 'Please enter the delivery_postal_code', }
                                        ]}
                                        initialValue={orderDetails?.delivery_postal_code || undefined}
                                    >
                                        <Input
                                            maxLength={5}
                                            onChange={(event) => updateFormFields('delivery_postal_code', event?.target.value || undefined)} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_pin'
                                        label='delivery_pin'
                                        rules={[
                                            { required: !!inventories.length, message: 'Please enter the delivery_pin', }
                                        ]}
                                        initialValue={orderDetails?.delivery_pin || undefined}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_pin', event?.target.value || undefined)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="suggested_delivery_time"
                                        label="Suggested Delivery Time"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please enter the Suggested Delivery Time',
                                            },
                                        ]}
                                        initialValue={
                                            orderDetails?.suggested_delivery_time
                                                ? dayjs(orderDetails.suggested_delivery_time)
                                                : undefined
                                        }
                                    >
                                        <ConfigProvider locale={locale}>
                                            <DatePicker
                                                value={form.getFieldValue('suggested_delivery_time')}
                                                showTime={{ format: 'HH:mm' }} // Include this line to enable time selection
                                                style={{ width: '100%' }}
                                                onChange={(date) => {
                                                    updateFormFields('suggested_delivery_time', date);
                                                }}
                                            />
                                        </ConfigProvider>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='tokenamount'
                                        label='tokenamount'
                                        rules={[
                                            { required: false, message: 'Please enter the tokenamount', }
                                        ]}
                                        initialValue={orderDetails?.tokenamount}
                                    >
                                        <Input
                                            onChange={(event) => {
                                                updateFormFields('tokenamount', parseInt(event?.target.value) || undefined);
                                                // breakDownHandler();
                                            }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};