export enum OrderStatusEnum {
    // READY_TO_DELIVER = 'ready_to_deliver',
    PENDING = 'pending',
    INCOMPLETE = 'incomplete',
    ACTIVE = 'active',
    OVERDUE = 'overdue',
    DEFAULT = 'default',
    BLACK_LISTED = 'black_listed',
    COMPLETE = 'complete',
    REFUND = 'refund'
}