import { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FilterOperatorsEnum, getEnumeratorKeys, IBrandEntity, IProcurementDTO, IProcurementEntity, IProductTypeEntity, IUserEntity, IVendorEntity, KeyValueRecord, PaymentMethodEnum, ProcurementStatusEnum, ProcurementTypeEnum, removeWhiteSpaceFromAllProperties, UserRolesEnum } from '@rasayi-workspace/shared';
import { GetItem, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import { SearchableDropdown } from '@components';
import { isArray } from 'class-validator';

export const ProcurementModalComponent = ({ onClose, onSubmit, editMode = false, id: procurementId = '' }: AddEditModalProps<IProcurementEntity>) => {
    const [form] = Form.useForm<Partial<IProcurementDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [procurement, setProcurement] = useState<Partial<IProcurementEntity>>();

    const { mutate: createProcurement, isLoading } = useMutation<IProcurementEntity, AxiosError>({
        mutationKey: ['createProcurement'],
        mutationFn: async () =>
            PostItem<IProcurementEntity, IProcurementDTO>(
                'procurement',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IProcurementDTO>
            ),
        onSuccess: async (item: IProcurementEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateProcurement, isLoading: isProcurementUpdating } = useMutation<IProcurementEntity, AxiosError>({
        mutationKey: ['updateProcurement'],
        mutationFn: async () =>
            PutItem<IProcurementEntity, IProcurementDTO>(
                'procurement',
                procurementId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IProcurementDTO>
            ),
        onSuccess: async (item: IProcurementEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchProcurement, isFetching: isProcurementFetching } = useQuery<IProcurementEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getProcurement'],
        queryFn: () => GetItem<IProcurementEntity>(
            'procurement',
            procurementId || '',
            [],
            []
        ),
        onSuccess: (result: IProcurementEntity): void => {
            setProcurement(procurement);
            form.setFieldsValue({
                ...result,
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const updateFormFields = (property: string, value: string | number | undefined) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;


        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createProcurement())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateProcurement())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        !isProcurementFetching && fetchProcurement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const optionsIsVerified = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Procurement' : 'Edit Procurement'}
                open={true}
                confirmLoading={isLoading || isProcurementFetching || isProcurementUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Procurement' : 'Edit Procurement'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='vendor_id'
                        label='Vendor'
                        rules={[{ required: true }]}
                    >
                        {SearchableDropdown<IVendorEntity>(
                            {
                                key: 'id',
                                value: 'vendor_name',
                                queryKey: ['getVendors'],
                                queryFnParams: {
                                    apiRoute: 'vendor',
                                    relations: [],
                                    fields: ['id', 'first_name', 'last_name', 'vendor_name'],
                                    filter: {
                                        field: 'vendor_name',
                                        operator: FilterOperatorsEnum.LIKE,
                                        value: ''
                                    },
                                    predefinedFilters: []
                                },
                                onSelectionChange: (selectedItemIds: string | string[], option: KeyValueRecord, items) => {
                                    if (!isArray(selectedItemIds)) {
                                        form.setFieldsValue({ 'vendor_id': selectedItemIds || '' });
                                    }
                                }
                            }
                        )
                        }
                    </Form.Item>
                    <Form.Item
                        name='product_type_id'
                        label='Product type'
                        rules={[{ required: true }]}
                    >
                        {SearchableDropdown<IProductTypeEntity>(
                            {
                                key: 'id',
                                value: 'name',
                                queryKey: ['getProductTypes'],
                                queryFnParams: {
                                    apiRoute: 'product_type',
                                    relations: [],
                                    fields: ['id', 'name'],
                                    filter: {
                                        field: 'name',
                                        operator: FilterOperatorsEnum.LIKE,
                                        value: ''
                                    },
                                    predefinedFilters: []
                                },
                                onSelectionChange: (selectedItemIds: string | string[], option: KeyValueRecord, items) => {
                                    if (!isArray(selectedItemIds)) {
                                        form.setFieldsValue({ 'product_type_id': selectedItemIds || '' });
                                    }
                                }
                            }
                        )
                        }
                    </Form.Item>
                    <Form.Item
                        name='brand_id'
                        label='Brand'
                        rules={[{ required: true }]}
                    >
                        {SearchableDropdown<IBrandEntity>(
                            {
                                key: 'id',
                                value: 'name',
                                queryKey: ['getBrands'],
                                queryFnParams: {
                                    apiRoute: 'brand',
                                    relations: [],
                                    fields: ['id', 'name'],
                                    filter: {
                                        field: 'name',
                                        operator: FilterOperatorsEnum.LIKE,
                                        value: ''
                                    },
                                    predefinedFilters: []
                                },
                                onSelectionChange: (selectedItemIds: string | string[], option: KeyValueRecord, items) => {
                                    if (!isArray(selectedItemIds)) {
                                        form.setFieldsValue({ 'brand_id': selectedItemIds || '' });
                                    }
                                }
                            }
                        )
                        }
                    </Form.Item>
                    <Form.Item
                        name='model'
                        label='Model'
                        rules={[
                            { required: true, message: 'Please enter the model' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('model', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='quantity'
                        label='quantity'
                        rules={[
                            { required: true, message: 'Please enter the quantity' }
                        ]}
                    >
                        <Input type='number'
                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={(event) => updateFormFields('quantity', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                    <Form.Item
                        name='specifications'
                        label='Specifications'
                        rules={[
                            { required: true, message: 'Please enter the specifications' },
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('specifications', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='total_purchase_price'
                        label='Total purchase price'
                        rules={[
                            { required: false, message: 'Please enter the total_purchase_price' },
                        ]}
                    >
                        <Input
                            type='number'
                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={(event) => updateFormFields('total_purchase_price', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                    <Form.Item
                        name='suggested_retail_price_per_piece'
                        label='Approved Retail Price'
                        rules={[
                            { required: true, message: 'Please enter the Approved Retail Price Per Piece' },
                        ]}
                    >
                        <Input type='number'
                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={(event) => updateFormFields('suggested_retail_price_per_piece', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                    <Form.Item
                        name='paid_amount'
                        label='Paid Amount'
                        rules={[
                            { required: false, message: 'Please enter the Paid Amount' },
                        ]}
                    >
                        <Input type='number'
                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={(event) => updateFormFields('paid_amount', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                    <Form.Item
                        name='color'
                        label='color'
                        rules={[
                            { required: true, message: 'Please enter the color' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('color', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='procurement_type'
                        label='Procurement type'
                        rules={[
                            { required: true, message: 'Please enter the Procurement type' }
                        ]}
                        initialValue={ProcurementTypeEnum.INVENTORY}
                    >
                        <Select onChange={(value) => updateFormFields('procurement_type', value)}>
                            {
                                getEnumeratorKeys(ProcurementTypeEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={ProcurementTypeEnum[key as keyof typeof ProcurementTypeEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='procured_by_id'
                        label='Procurement request'
                        rules={[{ required: true, message: 'Please enter Procurement request' }]}
                    >
                        {SearchableDropdown<IUserEntity>(
                            {
                                key: 'id',
                                value: 'first_name',
                                queryKey: ['getUsers'],
                                queryFnParams: {
                                    apiRoute: 'user',
                                    relations: [],
                                    fields: ['id', 'first_name', 'last_name'],
                                    filter: {
                                        field: 'first_name',
                                        operator: FilterOperatorsEnum.LIKE,
                                        value: ''
                                    },
                                    predefinedFilters: [
                                        {
                                            field: 'roles.name',
                                            operator: FilterOperatorsEnum.NOT_EQUAL,
                                            value: UserRolesEnum.CUSTOMER
                                        }
                                    ]
                                },
                                onSelectionChange: (selectedItemIds: string | string[], option: KeyValueRecord, items) => {
                                    if (!isArray(selectedItemIds)) {
                                        form.setFieldsValue({ 'procured_by_id': selectedItemIds || '' });
                                    }
                                }
                            }
                        )
                        }
                    </Form.Item>
                    <Form.Item
                        name='paid'
                        label='paid'
                        rules={[{ required: false }]}
                        initialValue={false}
                    >
                        <Select>
                            {optionsIsVerified.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='procurement_status'
                        label='Procurement status'
                        rules={[
                            { required: true, message: 'Please enter the procurement_status' }
                        ]}
                        initialValue={ProcurementStatusEnum.INQUIRY}
                    >
                        <Select onChange={(value) => updateFormFields('procurement_status', value)}>
                            {
                                getEnumeratorKeys(ProcurementStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={ProcurementStatusEnum[key as keyof typeof ProcurementStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='payment_method'
                        label='payment_method'
                        rules={[
                            { required: false, message: 'Please enter the payment_method' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('payment_method', value)}>
                            {
                                getEnumeratorKeys(PaymentMethodEnum).map(
                                    (key) => <Select.Option key={key} value={PaymentMethodEnum[key as keyof typeof PaymentMethodEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};