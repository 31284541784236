import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Routes } from '@routes';
import { IRouteConfiguration } from '@interfaces';
import { IPermissionEntity, IUserEntity } from '@rasayi-workspace/shared';
import { BASE_QUERY_OPTIONS } from '@constants';
import { Spin } from 'antd';
import { createBrowserRouter, RouterProvider, } from 'react-router-dom';
import '../styles.css';
import { GetRawItems, IsAuthorized, setPermissionsInApplicationMemory, GetToken, SignOut, GetTokenDetails, GetItem } from '@services';
import { UserContext } from './contexts/user';
import { OrderContextProvider } from './contexts/order';
import { DeliveryContextProvider } from './contexts/delivery';
import { SideBarContextProvider } from './contexts/sidebar';

const RoutesGenerator = (configurations: IRouteConfiguration[]) => configurations.map(
    ({ path, element, children }: IRouteConfiguration): IRouteConfiguration => {
        if (children?.length)
            return {
                path,
                element,
                children: RoutesGenerator(children)
            };

        return {
            path,
            element,
        };
    }
);

const AuthorizedUsage = ({ children }: { children: JSX.Element }) => {
    const { error: getPermissionsError, isFetching, refetch: getMyPermissions } = useQuery<string[]>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['userPermissions'],
        queryFn: () => GetRawItems<IPermissionEntity, string>('permission/me'),
        onSuccess: (permissions: string[]): void => setPermissionsInApplicationMemory(permissions)
    });

    if (getPermissionsError)
        SignOut();

    useEffect(() => {
        if (GetToken() && !IsAuthorized())
            getMyPermissions();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if ((GetToken() && !IsAuthorized()) || isFetching)
        return <Spin />;

    return children;
};

const router = createBrowserRouter(
    RoutesGenerator(Object.values(Routes))
);

export const Application = (): JSX.Element => {
    const [user, setUser] = useState<Partial<IUserEntity>>(GetTokenDetails()?.id ? { id: GetTokenDetails()?.id } : {});

    const { refetch: getUserData } = useQuery<IUserEntity>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUserInitially'],
        queryFn: () => GetItem<IUserEntity>(
            'user',
            user.id || '',
            ['roles'],
            ['id', 'email', 'first_name', 'last_name', 'roles.id', 'roles.name']
        ),
        onSuccess: (user: IUserEntity) => {
            setUser(user);
        }
    });

    const updateUser = (user?: IUserEntity): void => {
        if (user)
            setUser(user);
        else {
            getUserData();
        }
    };

    const updateUserId = (userId: string): void => setUser({ ...user, id: userId });

    useEffect(() => {
        if (user.id)
            getUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    return (
        <AuthorizedUsage>
            <DeliveryContextProvider>
                <OrderContextProvider>
                    <SideBarContextProvider>
                        <UserContext.Provider
                            value={{
                                user,
                                id: user.id || '',
                                updateUser,
                                updateUserId,
                            }}
                        >
                            <RouterProvider router={router} />
                        </UserContext.Provider>
                    </SideBarContextProvider>
                </OrderContextProvider>
            </DeliveryContextProvider>
        </AuthorizedUsage>
    );
}
