import { useEffect, useState } from 'react';
import { ConfigProvider, DatePicker, Form, Input, message, Modal, Select, Tag } from 'antd';
import { ChequeProviderEnum, ContractStatusEnum, getEnumeratorKeys, IInstalmentEntity, IOrderDTO, IOrderEntity, KeyValueRecord, OrderStatusEnum, SegmentEnumerator } from '@rasayi-workspace/shared';
import { AddEditModalProps } from '@interfaces';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

export const ViewOrderModalComponent = ({ onClose, onSubmit, viewMode = false, id: orderId = '', row }: AddEditModalProps<IOrderEntity>) => {
    const [form] = Form.useForm<Partial<IOrderDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [lastDate, setLastDate] = useState('');

    const updateFormFields = (property: string, value: string | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    useEffect(() => {
        const firstInstalment: IInstalmentEntity | undefined = row?.[0]?.instalments?.find(instalment => instalment?.payment_type?.index === 1);

        const lastInstalmentIndex = (row?.[0]?.instalment_plan?.days || 1) / (row?.[0]?.instalment_plan?.cycle || 1);

        const lastInstalment: IInstalmentEntity | undefined = row?.[0]?.instalments?.find(instalment => instalment?.payment_type?.index === lastInstalmentIndex);

        const instalmentMonths: number = ((row?.[0]?.instalments?.length || 1) - 1) ?? undefined;

        const guarantorName: string = (row?.[0]?.guarantor?.first_name || '') + ' ' + (row?.[0]?.guarantor?.last_name || '');

        setLastDate(lastInstalment?.due_datetime || '');

        const customerAddress: (string | undefined)[] = [
            row?.[0]?.user?.address_line1,
            row?.[0]?.user?.address_line2,
            row?.[0]?.user?.city
        ].filter(item => item !== null);

        const values: KeyValueRecord = row?.length ? {
            ...row[0],
            internal_point_of_contact_name: row?.[0]?.internal_point_of_contact?.first_name + ' ' + row?.[0]?.internal_point_of_contact?.last_name,
            customer_name: row?.[0]?.user?.first_name + ' ' + row?.[0]?.user?.last_name,
            customer_address: customerAddress.join(', '),
            instalment_months: instalmentMonths,
            financed_amount: row?.[0]?.total_retail_price - row?.[0]?.downpayment,
            monthly_emi: firstInstalment?.amount,
            last_date: lastInstalment?.due_datetime,
            guarantor_name: guarantorName
        } : {};
        form.setFieldsValue(values);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={'View order details'}
                open={true}
                width={900}
                onOk={() => onClose()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='internal_id'
                        label='Order ID'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['internal_point_of_contact_name'] }
                        label='POC'
                        initialValue={ row?.[0]?.internal_point_of_contact?.first_name + ' ' + row?.[0]?.internal_point_of_contact?.last_name }
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name='order_date'
                        label='order_date'
                        initialValue={ row?.[0]?.order_date ? dayjs(row?.[0]?.order_date) : undefined }
                    >
                        <ConfigProvider locale={ locale }>
                            <DatePicker
                                disabled
                                value={ dayjs(row?.[0]?.order_date) }
                                showTime={ false }
                                style={ { width: '100%' } }
                            />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        name='order_status'
                        label='Order Status'
                        rules={ [
                            { required: true, message: 'Please enter the internal_id' }
                        ] }
                    >
                        <Select disabled onChange={ (value) => updateFormFields('order_status', value) }>
                            {
                                getEnumeratorKeys(OrderStatusEnum).map(
                                    (key) => <Select.Option key={ key }
                                                            value={ OrderStatusEnum[key as keyof typeof OrderStatusEnum] }>
                                        <Tag key={ key }>{ key.toUpperCase() }</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={ ['customer_name'] }
                        label='Customer Name'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['user', 'primary_mobile'] }
                        label='Mobile Number'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['user', 'email'] }
                        label='Email'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['customer_address'] }
                        label='Customer Address'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['user', 'city'] }
                        label='User City'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['user', 'cnic_number'] }
                        label='User CNIC'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name='cheque_number'
                        label='Cheque number'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name='cheque_provider'
                        label='Cheque Provider'
                    >
                        <Select disabled>
                            {
                                getEnumeratorKeys(ChequeProviderEnum).map(
                                    (key) => <Select.Option key={ key }
                                                            value={ ChequeProviderEnum[key as keyof typeof ChequeProviderEnum] }>
                                        <Tag key={ key }>{ key.toUpperCase() }</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={ ['instalment_months'] }
                        label='No. of Instalments'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['downpayment'] }
                        label='Downpayment'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['financed_amount'] }
                        label='Financed Amount'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ 'monthly_emi' }
                        label='EMI'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name='last_date'
                        label='Last Date'
                        initialValue={ lastDate ? dayjs(lastDate) : undefined }
                    >
                        <ConfigProvider locale={ locale }>
                            <DatePicker
                                disabled
                                value={ lastDate ? dayjs(lastDate) : undefined }
                                showTime={ false }
                                style={ { width: '100%' } }
                            />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        name={ ['guarantor_name'] }
                        label='Guarantor Name'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['guarantor', 'primary_mobile'] }
                        label='Guarantor Number'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['guarantor', 'cnic_number'] }
                        label='Guarantor CNIC'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={ ['guarantor', 'address_line1'] }
                        label='Guarantor Address'
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name='delivery_pin'
                        label='Delivery Pin'
                        rules={[
                            { required: true, message: 'Please enter the internal_id' }
                        ]}
                    >
                        <Input disabled onChange={ (event) => updateFormFields('delivery_pin', event?.target.value) }/>
                    </Form.Item>
                    <Form.Item
                        name='apr'
                        label='apr'
                        rules={ [
                            { required: true, message: 'Please enter the apr' },
                        ] }
                    >
                        <Input disabled onChange={ (event) => updateFormFields('apr', parseInt(event?.target.value)) }/>
                    </Form.Item>
                    <Form.Item
                        name={['instalment_plan', 'name']}
                        label='instalment_plan '
                        rules={[
                            { required: true, message: 'Please enter the name' }
                        ]}
                    >
                        <Input disabled onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='contract_status'
                        label='contract_status'
                        rules={[
                            { required: true, message: 'Please enter the contract_status' }
                        ]}
                    >
                        <Select disabled onChange={(value) => updateFormFields('contract_status', value)}>
                            {
                                getEnumeratorKeys(ContractStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={ContractStatusEnum[key as keyof typeof ContractStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={['channel', 'name']}
                        label='channel '
                        rules={[
                            { required: true, message: 'Please enter the name' }
                        ]}
                    >
                        <Input disabled onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='box_and_cheque_returned'
                        label='BoxCheq Returned'
                        rules={[
                            { required: true, message: 'Please enter the BoxCheq Returned' }
                        ]}>
                        <Select disabled
                        >
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='segment'
                        label='segment'
                        rules={[
                            { required: true, message: 'Please enter the segment' }
                        ]}
                    >
                        <Select disabled onChange={(value) => updateFormFields('segment', value)}>
                            {
                                getEnumeratorKeys(SegmentEnumerator).map(
                                    (key) => <Select.Option key={key}
                                        value={SegmentEnumerator[key as keyof typeof SegmentEnumerator]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='tokenamount'
                        label='tokenamount'
                        rules={ [
                            { required: true, message: 'Please enter the tokenamount' },
                        ] }
                    >
                        <Input disabled onChange={ (event) => updateFormFields('tokenamount', parseFloat(event?.target.value)) }/>
                    </Form.Item>
                    <Form.Item
                        name='suggested_delivery_time'
                        label='suggested_delivery_time'
                        initialValue={ row?.[0]?.suggested_delivery_time ? dayjs(row?.[0]?.suggested_delivery_time) : undefined }
                    >
                        <ConfigProvider locale={ locale }>
                            <DatePicker
                                disabled
                                value={ row?.[0]?.suggested_delivery_time ? dayjs(row?.[0]?.suggested_delivery_time) : undefined}
                                showTime={ true }
                                style={ { width: '100%' } }
                            />
                        </ConfigProvider>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};