import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Checkbox, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IDocumentEntity, IItemsWithCount, IProcurementEntity, KeyValueRecord, PermissionsEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission, PutItem } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { ProcurementModalComponent } from './add-edit';
import { AddDocumentModalComponent } from './add-document';
import { ViewDocumentModalComponent } from './view-documents';

const { Content } = Layout;

export const ProcurementPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>({ ...DEFAULT_TABLE_STATE, sortBy: { 'internal_id': 'DESC' } });
    const [procurementsData, setProcurementsData] = useState<IItemsWithCount<IProcurementEntity>>(EMPTY_INITIAL_ITEMS);
    const [isProcurementModalOpened, setIsProcurementModalOpened] = useState(false);
    const [editingProcurementId, setEditingProcurementId] = useState('');
    const [isAddDocumentModalOpened, setIsAddDocumentModalOpened] = useState(false);
    const [editingDocumentUserId, setEditingDocumentUserId] = useState('');
    const [viewDocumentUserId, setViewDocumentUserId] = useState('');
    const [isViewDocumentModalOpened, setIsViewDocumentModalOpened] = useState(false);
    const [modifiedCheckboxes, setModifiedCheckboxes] = useState<KeyValueRecord>({});
    const defaultSorting: KeyValueRecord = { 'internal_id': 'desc' };

    const { refetch, isFetching } = useQuery<IItemsWithCount<IProcurementEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['procurements'],
        queryFn: () => GetTableItems<IProcurementEntity>(
            'procurement',
            ['vendor', 'brand', 'product_type'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IProcurementEntity>): void => setProcurementsData(returnedResult),
        onError: (err) => setProcurementsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteProcurement } = useMutation<IProcurementEntity, AxiosError>({
        mutationKey: ['deleteProcurement'],
        mutationFn: async () =>
            DeleteItem<IProcurementEntity>(
                'procurement',
                editingProcurementId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Procurement deleted!`,
            });

            !isFetching && await refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateApproveStatus, isLoading: isApproveUpdating } = useMutation<IProcurementEntity, AxiosError>({
        mutationKey: ['updateApproveStatus'],
        mutationFn: async () =>
            PutItem<IProcurementEntity, KeyValueRecord>(
                'procurement/approve',
                '',
                modifiedCheckboxes
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Procurements Updated`,
            });
            setModifiedCheckboxes({});
            !isFetching && await refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const onSaveChanges = () => {
        !isApproveUpdating && updateApproveStatus();
    };

    const handleChange: TableProps<IProcurementEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IProcurementEntity>).columnKey as keyof IProcurementEntity]: MapAntDesignSortOrder((sorter as SorterResult<IProcurementEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : defaultSorting;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onProcurementModalCloseHandler = (newProcurement: IProcurementEntity) => {
        !isFetching && refetch();
        resetModal();

        messageApi.open(
            !editingProcurementId ? {
                type: 'success',
                content: `New procurement created!`,
            } : {
                type: 'success',
                content: `Procurement updated!`,
            }
        );
    };

    const onUserViewDocumentModalCloseHandler = (newUser: IDocumentEntity) => {
        !isFetching && refetch();
        resetModal();

        messageApi.open(
            !viewDocumentUserId ? {
                type: 'success',
                content: `New user '${ newUser?.id + ' ' + newUser?.name }' created!`,
            } : {
                type: 'success',
                content: `User '${ newUser?.id + ' ' + newUser?.name }' updated!`,
            }
        );
    };

    const onUserAddDocumentModalCloseHandler = (newUser: IDocumentEntity) => {
        !isFetching && refetch();
        resetModal();

        messageApi.open(
            !editingDocumentUserId ? {
                type: 'success',
                content: `New user '${ newUser?.id + ' ' + newUser?.name }' created!`,
            } : {
                type: 'success',
                content: `User '${ newUser?.id + ' ' + newUser?.name }' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const onAddDocumentModalCloseHandler = () => resetAddModal();

    const onViewDocumentModalCloseHandler = () => resetViewModal();

    const resetAddModal = () => {
        setIsAddDocumentModalOpened(false);
        setEditingDocumentUserId('');
    };

    const resetViewModal = () => {
        setIsViewDocumentModalOpened(false);
        setViewDocumentUserId('');
    };

    const resetModal = () => {
        setIsProcurementModalOpened(false);
        setEditingProcurementId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const handleCheckboxChange = (checked: boolean, item: IProcurementEntity) => {
        if (item.approved === checked) {
            const { [item.id]: keyToIgnore, ...restKeys } = modifiedCheckboxes;
            setModifiedCheckboxes(restKeys);
        } else {
            setModifiedCheckboxes({ ...modifiedCheckboxes, [item.id]: checked });
        }
    };

    const columns: ColumnsType<IProcurementEntity> = [
        {
            title: 'ID',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('internal_id', 'by ID', resetSearchHandler)
        },
        // {
        //     title: 'Brand Name',
        //     dataIndex: ['brand', 'name'],
        //     key: 'brand.name',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('brand.name', 'by Brand Name', resetSearchHandler)
        // },
        // {
        //     title: 'Product Type',
        //     dataIndex: ['product_type', 'name'],
        //     key: 'product_type.name',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('product_type.name', 'by Product Type', resetSearchHandler)
        // },
        // {
        //     title: 'Model',
        //     dataIndex: 'model',
        //     key: 'model',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('model', 'by model', resetSearchHandler)
        // },
        // {
        //     title: 'Color',
        //     dataIndex: 'color',
        //     key: 'color',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('color', 'by color', resetSearchHandler)
        // },
        // {
        //     title: 'Quantity',
        //     dataIndex: 'quantity',
        //     key: 'quantity',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('quantity', 'by quantity', resetSearchHandler)
        // },
        // {
        //     title: 'Purchase Price',
        //     dataIndex: 'total_purchase_price',
        //     key: 'total_purchase_price',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('total_purchase_price', 'by Purchase Price', resetSearchHandler)
        // },
        // {
        //     title: 'Approved Retail Price',
        //     dataIndex: 'suggested_retail_price_per_piece',
        //     key: 'suggested_retail_price_per_piece',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('suggested_retail_price_per_piece', 'by Retail Price', resetSearchHandler)
        // },
        // {
        //     title: 'Paid Amount',
        //     dataIndex: 'paid_amount',
        //     key: 'paid_amount',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('paid_amount', 'by Retail Price', resetSearchHandler)
        // },
        // {
        //     title: 'Remaining Amount',
        //     dataIndex: 'remaining_amount',
        //     key: 'remaining_amount',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('remaining_amount', 'by Retail Price', resetSearchHandler)
        // },
        // {
        //     title: 'Specifications',
        //     dataIndex: 'specifications',
        //     key: 'specifications',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     sortDirections: ['ascend', 'descend'],
        //     ...ColumnsSearchProps('specifications', 'by Specifications', resetSearchHandler)
        // },
        {
            title: 'Vendor Name',
            dataIndex: ['vendor', 'vendor_name'],
            key: 'vendor.vendor_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('vendor.vendor_name', 'by Vendor Name', resetSearchHandler)
        },
        {
            title: 'Procurement Type',
            dataIndex: 'procurement_type',
            key: 'procurement_type',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement_type', 'by Procurement Type', resetSearchHandler)
        },
        {
            title: 'Procurement Status',
            dataIndex: 'procurement_status',
            key: 'procurement_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('procurement_status', 'by Procurement Status', resetSearchHandler)
        },
        {
            title: 'Payment Method',
            dataIndex: 'payment_method',
            key: 'payment_method',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('payment_method', 'by Procurement Method', resetSearchHandler)
        },
        {
            title: 'Paid ',
            key: 'paid',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IProcurementEntity) => {
                const paid = item?.paid?.toString();

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            paid
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('paid', 'by true/false', resetSearchHandler)
        },
        {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IProcurementEntity) => {
                const isApproved = modifiedCheckboxes[item.id] ?? (item.approved ? item.approved : false);

                return (
                    <Tooltip title={ isApproved ? 'Approved' : 'Not Approved' }>
                        <Checkbox
                            checked={ isApproved }
                            onChange={ (e) => handleCheckboxChange(e.target.checked, item) }
                        />
                    </Tooltip>
                );
            },
            ...ColumnsSearchProps('approved', 'by true/false', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'View Documents',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EyeOutlined/> }
                        disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                        onClick={
                            () => {
                                setViewDocumentUserId(id);
                                setIsViewDocumentModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, internal_id = '', brand = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <CloudUploadOutlined/> }
                        disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                        onClick={
                            () => {
                                setEditingDocumentUserId(id);
                                setIsAddDocumentModalOpened(true);
                            }
                        }
                    />
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EditOutlined/> }
                        disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                        onClick={
                            () => {
                                setEditingProcurementId(id);
                                setIsProcurementModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={ <DeleteOutlined/> }
                        disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE]) }
                        onClick={
                            () => {
                                setEditingProcurementId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete Procurement '${ internal_id + ' ' + brand }'?`,
                                    onOk: () => {
                                        deleteProcurement();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        !isFetching && refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isProcurementModalOpened &&
                <ProcurementModalComponent
                    editMode={ !!editingProcurementId }
                    id={ editingProcurementId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onProcurementModalCloseHandler }
                />
            }
            {
                isAddDocumentModalOpened &&
                <AddDocumentModalComponent
                    editMode={ !!editingDocumentUserId }
                    id={ editingDocumentUserId }
                    onClose={ onAddDocumentModalCloseHandler }
                    onSubmit={ onUserAddDocumentModalCloseHandler }
                />
            }
            {
                isViewDocumentModalOpened &&
                <ViewDocumentModalComponent
                    editMode={ !!viewDocumentUserId }
                    id={ viewDocumentUserId }
                    onClose={ onViewDocumentModalCloseHandler }
                    onSubmit={ onUserViewDocumentModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white bprocurement rounded-lg'>
                    <Card
                        title='Procurements'
                        extra={ <>
                            <Button
                                type='primary'
                                style={ { marginLeft: 4 } }
                                onClick={ onSaveChanges }
                                disabled={ !Object.keys(modifiedCheckboxes).length }
                            >
                                Save Changes
                            </Button>
                            <Button
                                type='primary'
                                disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                                onClick={ () => setIsProcurementModalOpened(true) }
                                style={ { marginLeft: 4 } }
                            >
                                Add Procurement
                            </Button>
                        </>
                        }
                    >
                        <div className='bprocurement-2 bprocurement-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ procurementsData.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: procurementsData.count,
                                        pageSize: procurementsData.pageSize,
                                        current: procurementsData.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};