import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { FilterOperatorsEnum, IOrderEntity, KeyValueRecord } from '@rasayi-workspace/shared';
import { BaseLayoutComponent, SearchableDropdown } from '@components';
import { deliveryContext } from '@contexts';
import { CustomerLocation } from '../2-customer-location';
import { Content } from 'antd/es/layout/layout';

export const CustomerInformation = () => {
    const { deliveryDetails, setDeliveryDetails } = useContext(deliveryContext);
    const [form] = Form.useForm<Partial<any>>();
    const [selectedOrder, setSelectedOrder] = useState<any>();
    const [nextPage, setNextPage] = useState(false);

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const nextPageHandler = () => {
        setNextPage(true);
    };

    useEffect(() => {
        if (deliveryDetails?.internal_id && !selectedOrder?.internal_id) {
            form.setFieldsValue({ ...deliveryDetails });
            setSelectedOrder(deliveryDetails);
        }
    }, []);

    return (
        <>
            {
                nextPage &&
                <CustomerLocation />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={<div className='d-flex'>
                            <h4 className=''>Customer Information</h4>
                            <Button
                                type='primary'
                                style={{ marginLeft: 4 }}
                                disabled
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                onClick={nextPageHandler}
                                style={{ marginLeft: 4 }}
                                disabled={!selectedOrder}
                            >
                                Next
                            </Button>
                        </div>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            form={form}
                        >
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='internal_id'
                                        label='Order ID'
                                        rules={[
                                            { required: true, message: 'Please enter the internal_id' }
                                        ]}
                                    >
                                        { SearchableDropdown<IOrderEntity>(
                                            {
                                                key: 'internal_id',
                                                defaultValue: deliveryDetails?.internal_id || '',
                                                value: 'internal_id',
                                                queryKey: ['getBrands'],
                                                queryFnParams: {
                                                    apiRoute: 'order',
                                                    relations: [
                                                        'user', 'internal_point_of_contact', 'channel', 'guarantor',
                                                        'delivery.downpayment_receipt',
                                                        'delivery.customer_picture',
                                                        'delivery.delivery_location_picture',
                                                        'delivery.lock_picture',
                                                        'delivery.house_video',
                                                    ],
                                                    fields: [],
                                                    filter: {
                                                        field: 'internal_id',
                                                        operator: FilterOperatorsEnum.LIKE,
                                                        value: ''
                                                    },
                                                    predefinedFilters: []
                                                },
                                                onSelectionChange: (selectedItemIds: string | string[], option: KeyValueRecord, items) => {
                                                    const currentOrder: IOrderEntity | undefined = items.find((order: IOrderEntity) => order.internal_id === selectedItemIds);
                                                    if (currentOrder) {
                                                        form.setFieldsValue({ ...currentOrder });
                                                        setDeliveryDetails({ ...currentOrder });
                                                        setSelectedOrder(currentOrder);
                                                    }
                                                }
                                            }
                                        )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            {selectedOrder ? <>
                                <h3>Customer</h3>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'first_name']}
                                            label='First Name'
                                            initialValue={selectedOrder.user?.first_name}
                                        >
                                            <Input disabled value={selectedOrder?.user?.first_name} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'last_name']}
                                            label='Last Name'
                                            initialValue={selectedOrder?.user?.last_name}
                                        >
                                            <Input disabled value={selectedOrder?.user?.last_name}
                                                onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'cnic_number']}
                                            label='Cnic Number'
                                            initialValue={selectedOrder?.user?.cnic_number}
                                        >
                                            <Input disabled value={selectedOrder?.user?.cnic_number}
                                                onChange={(event) => updateFormFields('cnic_number', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'primary_mobile']}
                                            label='Primary Mobile'
                                            initialValue={selectedOrder?.user?.primary_mobile}
                                        >
                                            <Input disabled value={selectedOrder?.user?.primary_mobile}
                                                onChange={(event) => updateFormFields('primary_mobile', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'city']}
                                            label='City'
                                            initialValue={selectedOrder?.user?.city}
                                        >
                                            <Input disabled value={selectedOrder?.user?.city}
                                                onChange={(event) => updateFormFields('city', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'address_line1']}
                                            label='Address 1'
                                            initialValue={selectedOrder?.user?.address_line1}
                                        >
                                            <Input disabled value={selectedOrder?.user?.address_line1}
                                                onChange={(event) => updateFormFields('address_line1', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <h3>Guarantor</h3>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['guarantor', 'first_name']}
                                            label='guarantor_first_name'
                                            initialValue={selectedOrder?.guarantor?.first_name}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['guarantor', 'last_name']}
                                            label='guarantor last name'
                                            initialValue={selectedOrder?.guarantor?.last_name}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['guarantor', 'primary_mobile']}
                                            label='primary_mobile'
                                            initialValue={selectedOrder?.guarantor?.primary_mobile}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['guarantor', 'cnic_number']}
                                            label='guarantor Cnic Number'
                                            initialValue={selectedOrder?.guarantor?.cnic_number}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> : ''}
                            {/* Add more rows with Col and Form.Item as needed */}
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};