import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IDeliveryEntity, IItemsWithCount, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { DeliveryModalComponent } from './add-edit';
import ViewDeliveryModalComponent from './view-delivery'; // Import the new component

const { Content } = Layout;

const DeliveryPageComponent = (): any => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [deliverysData, setDeliverysData] = useState<IItemsWithCount<any>>(EMPTY_INITIAL_ITEMS);
    const [isDeliveryModalOpened, setIsDeliveryModalOpened] = useState(false);
    const [editingDeliveryId, setEditingDeliveryId] = useState('');
    const [viewDeliveryId, setViewDeliveryId] = useState('');
    const [viewDeliveryModalVisible, setViewDeliveryModalVisible] = useState(false);
    const [selectedDelivery, setSelectedDelivery] = useState<IDeliveryEntity | null>(null);

    const { refetch, isFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['delivery'],
        queryFn: () => GetTableItems<any>(
            'delivery',
            ['deliverer', 'order.user', 'order.guarantor', 'order.inventories.procurement'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<any>): void => setDeliverysData(returnedResult),
        onError: () => setDeliverysData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteDelivery } = useMutation<any, AxiosError>({
        mutationKey: ['deleteDelivery'],
        mutationFn: async () =>
            DeleteItem<any>(
                'delivery',
                editingDeliveryId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Delivery deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IDeliveryEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IDeliveryEntity>).columnKey as keyof IDeliveryEntity]: MapAntDesignSortOrder((sorter as SorterResult<IDeliveryEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onDeliveryModalCloseHandler = (newDelivery: any) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingDeliveryId ? {
                type: 'success',
                content: `New delivery created!`,
            } : {
                type: 'success',
                content: `Delivery updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsDeliveryModalOpened(false);
        setEditingDeliveryId('');
        setViewDeliveryId('');
        setViewDeliveryModalVisible(false);
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item: any) => !item.dataKey) || []
        });
    };

    const handleViewDelivery = (id: string) => {
        const delivery = deliverysData.items.find(item => item.id === id);
        setSelectedDelivery(delivery || null);
        setViewDeliveryId(id);
        setViewDeliveryModalVisible(true);
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Order ID',
            dataIndex: ['order', 'internal_id'],
            key: 'order.internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('order.internal_id', 'by order_id', resetSearchHandler)
        },
        {
            title: 'Customer Name',
            dataIndex: ['order', 'user', 'first_name'],
            key: 'order.user.first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (_: string, item: IDeliveryEntity) => {
                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            item?.order?.user?.first_name + ' ' + item?.order?.user?.last_name
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('order.user.first_name', 'by Customer First Name', resetSearchHandler)
        },
        {
            title: 'CNIC Number',
            dataIndex: ['order', 'user', 'cnic_number'],
            key: 'order.user.cnic_number',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('order.user.cnic_number', 'by Customer CNIC Number', resetSearchHandler)
        },
        {
            title: 'delivered_by',
            dataIndex: 'delivered_by',
            key: 'delivered_by',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivered_by', 'by delivered_by', resetSearchHandler)
        },
        {
            title: 'delivery status',
            dataIndex: 'delivery_status',
            key: 'delivery_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivery_status', 'by Delivery status', resetSearchHandler)
        },
        {
            title: 'signature',
            key: 'signature',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IDeliveryEntity) => {
                const signature = item.signature.toString();

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            signature
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('signature', 'by true/false', resetSearchHandler)
        },
        {
            title: 'thumb',
            key: 'thumb',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IDeliveryEntity) => {
                const thumb = item.thumb.toString();

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            thumb
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('thumb', 'by thumb', resetSearchHandler)
        },
        {
            title: 'delivery_pin',
            dataIndex: 'delivery_pin',
            key: 'delivery_pin',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IDeliveryEntity) => {
                const delivery_pin = item.delivery_pin;

                return (
                    <a href={delivery_pin} target='_blank' className='cursor-pointer'>
                        {delivery_pin}
                    </a>
                );
            },
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'order_delivery_pin',
            dataIndex: ['order', 'delivery_pin'],
            key: 'order.delivery_pin',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IDeliveryEntity) => {
                const delivery_pin = item?.order?.delivery_pin;

                return (
                    <a href={delivery_pin} target='_blank' className='cursor-pointer'>
                        {delivery_pin}
                    </a>
                );
            },
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, first_name = '', last_name = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EyeOutlined />}
                        onClick={() => handleViewDelivery(id)}
                    />
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        onClick={() => {
                            setEditingDeliveryId(id);
                            setIsDeliveryModalOpened(true);
                        }}
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            setEditingDeliveryId(id);

                            DeleteConfirmationModal({
                                content: `Are you sure you want to delete delivery '${first_name + ' ' + last_name}'?`,
                                onOk: () => {
                                    deleteDelivery();
                                },
                                onCancel: () => resetModal()
                            });
                        }}
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isDeliveryModalOpened &&
                <DeliveryModalComponent
                    editMode={!!editingDeliveryId}
                    id={editingDeliveryId}
                    onClose={onModalCloseHandler}
                    onSubmit={onDeliveryModalCloseHandler}
                />
            }
            {
                viewDeliveryModalVisible &&
                <ViewDeliveryModalComponent
                    id={viewDeliveryId}
                    visible={viewDeliveryModalVisible}
                    onClose={resetModal}
                    deliveryData={selectedDelivery}
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            'Delivery'
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={deliverysData.items}
                                onChange={handleChange}
                                pagination={{
                                    total: deliverysData.count,
                                    pageSize: deliverysData.pageSize,
                                    current: deliverysData.page,
                                }}
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true }}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default DeliveryPageComponent;
