import { FilterSeparatorsEnum, IFilterParameter, SortOrder } from '@rasayi-workspace/shared';
import { IAPIQueryParams } from '../interfaces/api-params';

/**
 * This function formats the incoming sort order to filter string
 * e.g
 * {
 *      name: 'ASC',
 *      created_at: 'DESC'
 * }
 * 
 * This example means sort the data by name in ascending order and created_at in descending order
 * @param {SortOrder} sortOrder sort order object
 * @returns name,asc;created_at,desc
 */
export const SortOrderQueryParamCreator = (sortOrder: SortOrder): string =>
    Object.entries(sortOrder).map(
        ([key, value]) => key + FilterSeparatorsEnum.COMMA + value.toString().toLocaleLowerCase()
    ).join(FilterSeparatorsEnum.SEMI_COLON);

/**
 * To see in detail how the filters work, see swagger docs. 
 * One example here is
 * e.g.
 * [
 *  [
 *      {field: 'name', operator: 'eq', 'john'},
 *      {field: 'age', operator: 'eq', '15'}
 *  ],
 *  [
 *      {field: 'name', operator: 'eq', 'doe'},
 *      {field: 'age', operator: 'eq', '20'}
 *  ]
 * ]
 * 
 * This means find a user with name equals 'john' AND age equals '15'. OR . find a person name equals 'doe' AND age equals '20'
 * @param filters filters array of arrays. Outer array will be treated as OR conditions. Internal as AND
 * @returns filter string. This example will result is name,eq,john;age,eq,15:name,eq,doe;age,eq,20
 */
export const FilterParamCreator = (filters: IFilterParameter[][]): string => {
    if (!filters.length)
        return '';

    if (filters.length === 1)
        return filters[0].map(
            ({ field, operator, value }) => `${field}${FilterSeparatorsEnum.COMMA}${operator}${FilterSeparatorsEnum.COMMA}${value}`
        ).join(FilterSeparatorsEnum.SEMI_COLON);

    return filters.map(
        filter => filter.map(
            ({ field, operator, value }) => `${field}${FilterSeparatorsEnum.COMMA}${operator}${FilterSeparatorsEnum.COMMA}${value}`
        ).join(FilterSeparatorsEnum.SEMI_COLON)
    ).join(FilterSeparatorsEnum.COLON);
}

export const SelectedPropertiesQueryParamCreator = (selectedItems: string[]): string => selectedItems.join(FilterSeparatorsEnum.COMMA);
export const SelectedFieldsQueryParamCreator = SelectedPropertiesQueryParamCreator;
export const SelectedRelationsQueryParamCreator = SelectedPropertiesQueryParamCreator;

/**
 * api query parameters formatter which respect to above functions
 * @param {IAPIQueryParams} Parameters
 * @returns 
 */
export const CreateQueryParams = ({
    page,
    pageSize,
    fields = [],
    relations = [],
    search = [],
    sortBy = {},
    filter = []
}: IAPIQueryParams) => {
    const queryParams: {
        page?: number; pageSize?: number; sortBy?: string;
        filter?: string; fields?: string; relations?: string;
    } = {};

    if (page)
        queryParams.page = page;

    if (pageSize)
        queryParams.pageSize = pageSize;

    if (search?.length)
        if (!filter.length)
            filter.push(
                [
                    ...search.map(
                        (searchFilter) => ({ field: searchFilter.criteria, operator: searchFilter.operator, value: searchFilter.term })
                    )
                ]
            )
        else
            // if search term is present, make it part of every 'or' condition so that it is searched together.
            // Usually very less cases will happen when such multiple conditions are needed. usually filter will have 1 inner array which inturn will have multiple 'and' conditions
            filter = filter.map(
                item => ([
                    ...item,
                    ...search.map(
                        searchFilter => ({
                            field: searchFilter.criteria,
                            operator: searchFilter.operator,
                            value: searchFilter.term
                        })
                    )
                ])
            );

    if (filter.length)
        queryParams.filter = FilterParamCreator(filter);

    if (Object.keys(sortBy).length)
        queryParams.sortBy = SortOrderQueryParamCreator(sortBy);

    if (relations.length)
        queryParams.relations = SelectedFieldsQueryParamCreator(relations);

    if (fields.length)
        queryParams.fields = SelectedFieldsQueryParamCreator(fields);

    return queryParams;
}