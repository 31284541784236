import { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FilterOperatorsEnum, getEnumeratorKeys, IInventoryDTO, IInventoryEntity, InventoryStatusEnum, IProcurementEntity, LockStatusEnum, ProcurementStatusEnum, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { SearchableDropdown } from '@components';
import { GetItem, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const InventoryModalComponent = ({ onClose, onSubmit, editMode = false, id: inventoryId = '' }: AddEditModalProps<IInventoryEntity>) => {
    const [form] = Form.useForm<Partial<IInventoryDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [inventory, setInventory] = useState<Partial<IInventoryEntity>>();
    const [mobileRender, setMobileRender] = useState(false);
    const [mobileInfo, setMobileInfo] = useState({
        locking_identifier: '',
        imei_1: '',
        imei_2: '',
        storage: 0,
        lockable: true,
        lock_status: LockStatusEnum.PENDING,
        color: '',
        inventory_id: '',
        ram: 0
    });


    const { mutate: createInventory, isLoading } = useMutation<IInventoryEntity, AxiosError>({
        mutationKey: ['createInventory'],
        mutationFn: async () =>
            PostItem<IInventoryEntity, IInventoryDTO>(
                'inventory/add',
                mobileRender ?
                    removeWhiteSpaceFromAllProperties({ mobile_info: mobileInfo, ...form.getFieldsValue() }) as Partial<IInventoryDTO> :
                    removeWhiteSpaceFromAllProperties({ ...form.getFieldsValue() }) as Partial<IInventoryDTO>
            ),
        onSuccess: async (item: IInventoryEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateInventory, isLoading: isInventoryUpdating } = useMutation<IInventoryEntity, AxiosError>({
        mutationKey: ['updateInventory'],
        mutationFn: async () =>
            PutItem<IInventoryEntity, IInventoryDTO>(
                'inventory',
                inventoryId,
                removeWhiteSpaceFromAllProperties({ ...form.getFieldsValue() }) as Partial<IInventoryDTO>
            ),
        onSuccess: async (item: IInventoryEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchInventory, isFetching: isInventoryFetching } = useQuery<IInventoryEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInventory'],
        queryFn: () => GetItem<IInventoryEntity>(
            'inventory',
            inventoryId || '',
            ['mobile_info', 'procurement'],
            []
        ),
        onSuccess: (result: IInventoryEntity): void => {
            setInventory(result);
            form.setFieldsValue({
                ...result,
            });
            if (result.mobile_info) {
                setMobileRender(true);
            }
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createInventory())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateInventory())
        .catch(() => setIsValidForm(false));

    useEffect(() => {
        fetchInventory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchInventory();

        if (inventory?.mobile_info) {
            setMobileInfo({
                ...mobileInfo,
                locking_identifier: inventory.mobile_info.locking_identifier || '',
            });
        }
    }, [inventory?.mobile_info]);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Inventory' : 'Edit Inventory'}
                open={true}
                confirmLoading={isLoading || isInventoryFetching || isInventoryUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Inventory' : 'Edit Inventory'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='procurement_internal_id'
                        label='Procurement id'
                        rules={[{ required: false }]}
                    >
                        {
                            SearchableDropdown<IProcurementEntity>(
                                {
                                    key: 'internal_id',
                                    defaultValue: inventory?.procurement?.internal_id || '',
                                    value: 'internal_id',
                                    sortBy: { 'internal_id': 'DESC' },
                                    queryKey: ['getProcurements'],
                                    queryFnParams: {
                                        apiRoute: 'procurement',
                                        relations: ['product_type'],
                                        fields: ['id', 'internal_id', 'quantity', 'total_purchase_price', 'product_type.name'],
                                        filter: {
                                            field: 'internal_id',
                                            operator: FilterOperatorsEnum.LIKE,
                                            value: ''
                                        },
                                        predefinedFilters: [
                                            {
                                                field: 'procurement_status',
                                                operator: FilterOperatorsEnum.EQUAL,
                                                value: ProcurementStatusEnum.PROCURED
                                            },
                                            {
                                                field: 'approved',
                                                operator: FilterOperatorsEnum.EQUAL,
                                                value: 'true'
                                            }
                                        ]
                                    },
                                    onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                        updateFormFields('procurement_internal_id', selectedItemIds);
                                        setMobileRender(
                                            items.find(el => el.internal_id === selectedItemIds)?.product_type?.name === 'Mobile'
                                        );
                                    }
                                }
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        name='status'
                        label='status'
                        rules={[
                            { required: true, message: 'Please enter the status' }
                        ]}
                        initialValue={InventoryStatusEnum.UNSOLD}
                    >
                        <Select onChange={(value) => updateFormFields('status', value)}>
                            {
                                getEnumeratorKeys(InventoryStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={InventoryStatusEnum[key as keyof typeof InventoryStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    {
                        !mobileRender &&
                        <Form.Item
                            name='serial_number'
                            label='serial_number'
                            rules={[
                                { required: false, message: 'Please enter the serial_number' }
                            ]}
                            initialValue={inventory?.serial_number}
                        >
                            <Input onChange={(event) => updateFormFields('serial_number', event?.target.value)} />
                        </Form.Item>
                    }
                    {
                        inventory?.mobile_info || mobileRender ? <>
                            <Form.Item
                                name='mobile_info.locking_identifier'
                                label='locking_identifier'
                                rules={[
                                    { required: true, message: 'Please enter the locking_identifier' }
                                ]}
                                initialValue={inventory?.mobile_info?.locking_identifier}
                            >
                                {/* <Input  onChange={(event) => updateFormFields('locking_identifier', event?.target.value)} /> */}
                                <Input
                                    onChange={(event) =>
                                        setMobileInfo({
                                            ...mobileInfo,
                                            locking_identifier: event.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name='mobile_info.imei_1'
                                label='imei_1'
                                rules={[
                                    { required: true, message: 'Please enter the imei_1' },
                                ]}
                                initialValue={inventory?.mobile_info?.imei_1}
                            >
                                <Input
                                    onChange={(event) =>
                                        setMobileInfo({
                                            ...mobileInfo,
                                            imei_1: event.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name='mobile_info.imei_2'
                                label='imei_2'
                                rules={[
                                    { required: false, message: 'Please enter the imei_2' },
                                ]}
                                initialValue={inventory?.mobile_info?.imei_2}
                            >
                                <Input
                                    onChange={(event) =>
                                        setMobileInfo({
                                            ...mobileInfo,
                                            imei_2: event.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name='mobile_info.ram'
                                label='ram'
                                initialValue={inventory?.mobile_info?.ram}
                            >
                                <Input
                                    onChange={(event) =>
                                        setMobileInfo({
                                            ...mobileInfo,
                                            ram: parseInt(event.target.value) || 0,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name='mobile_info.storage'
                                label='storage'
                                initialValue={inventory?.mobile_info?.storage}
                            >
                                <Input
                                    onChange={(event) =>
                                        setMobileInfo({
                                            ...mobileInfo,
                                            storage: parseInt(event.target.value) || 0,
                                        })
                                    }
                                />
                            </Form.Item>
                        </> : ''
                    }
                </Form>
            </Modal>
        </>
    );
};