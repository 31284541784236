import { useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getEnumeratorKeys, IBrandEntity, IChannelEntity, IItemsWithCount, ILeadDTO, ILeadEntity, IProductTypeEntity, IUserDTO, IUserEntity, KeyValueRecord, LeadStatusEnum, regexCollection, removeEmptyValues, removeWhiteSpaceFromAllProperties, SegmentEnumerator } from '@rasayi-workspace/shared';
import { TagsDropdown } from '@components';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS, PROVINCES_AND_CITIES } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';
import { ConfigProvider } from 'antd/lib';

export const LeadModalComponent = ({ onClose, onSubmit, editMode = false, id: leadId = '' }: AddEditModalProps<ILeadEntity>) => {
    const [form] = Form.useForm<Partial<ILeadDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [brands, setBrands] = useState<IBrandEntity[]>([]);
    const [channels, setChannels] = useState<IChannelEntity[]>([]);
    const [productType, setProductType] = useState<IProductTypeEntity[]>([]);
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

    const { mutate: createLead, isLoading } = useMutation<ILeadEntity, AxiosError>({
        mutationKey: ['createLead'],
        mutationFn: async () =>
            PostItem<ILeadEntity, ILeadDTO>(
                'lead/addLead',
                removeWhiteSpaceFromAllProperties(removeEmptyValues(form.getFieldsValue())) as Partial<ILeadDTO>
            ),
        onSuccess: async (item: ILeadEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error as KeyValueRecord).response?.data?.message.join(', ') || 'Contact support for details'
            });
        }
    });

    const { mutate: createUser } = useMutation<IUserEntity, AxiosError>({
        mutationKey: ['createUser'],
        mutationFn: async () =>
            PostItem<IUserEntity, IUserDTO>(
                `user/lead/${leadId}`,
                removeWhiteSpaceFromAllProperties({ leadId }) as Partial<any>
            ),
        onSuccess: async (item: any): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateLead, isLoading: isLeadUpdating } = useMutation<ILeadEntity, AxiosError>({
        mutationKey: ['updateLead'],
        mutationFn: async () =>
            PutItem<ILeadEntity, ILeadDTO>(
                'lead',
                leadId,
                removeWhiteSpaceFromAllProperties(removeEmptyValues(form.getFieldsValue())) as Partial<ILeadDTO>
            ),
        onSuccess: async (item: ILeadEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchLead, isFetching: isLeadFetching } = useQuery<ILeadEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getLead'],
        queryFn: () => GetItem<ILeadEntity>('lead', leadId || ''),
        onSuccess: (result: ILeadEntity): void => {
            form.setFieldsValue({
                ...result,
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchProductType, isFetching: isProductTypeFetching } = useQuery<IItemsWithCount<IProductTypeEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getProductTypes'],
        queryFn: () => GetItems<IProductTypeEntity>(
            'product_type',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IProductTypeEntity>): void => setProductType(returnedResult.items),
        onError: () => setProductType([])
    });

    const { refetch: fetchBrands } = useQuery<IItemsWithCount<IBrandEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getBrands'],
        queryFn: () => GetItems<IBrandEntity>(
            'brand',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IBrandEntity>): void => setBrands(returnedResult.items),
        onError: () => setBrands([])
    });

    const { refetch: fetchChannels } = useQuery<IItemsWithCount<IChannelEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getChannels'],
        queryFn: () => GetItems<IChannelEntity>(
            'channel',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IChannelEntity>): void => setChannels(returnedResult.items),
        onError: () => setChannels([])
    });

    const updateFormFields = (property: string, value?: string) => {
        if (value) {
            form.setFieldsValue({ [property]: value });
        }
    };

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
        setSelectedDate(date);
        form.setFieldsValue({ 'contact_time': dateString });
    };

    const onOkAddButtonClickHandler = async () => {
        await form.validateFields()
            .then(() => createLead())
            .catch(() => setIsValidForm(false));
    };

    const onOkEditButtonClickHandler = async () => {
        await form.validateFields()
            .then(() => updateLead())
            .catch(() => setIsValidForm(false));
    };

    const onCreateUserButtonClickHandler = async () => await form.validateFields()
        .then(() => createUser())
        .catch(() => setIsValidForm(false));

    useEffect(() => {
        fetchLead();
        fetchBrands();
        fetchChannels();
        fetchProductType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cities = useMemo(
        () => Object
            .values(PROVINCES_AND_CITIES)
            .reduce(
                (aggregator, values) => ([...aggregator, ...values]), []
            )
            .sort(),
        []
    );

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Lead' : 'Edit Lead'}
                open={true}
                confirmLoading={isLoading || isLeadFetching || isLeadUpdating || isProductTypeFetching}
                okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Lead' : 'Edit Lead'}
                onOk={onOkAddButtonClickHandler}
                onCancel={() => onClose()}
                bodyStyle={{ height: 550, overflow: 'auto' }}
                footer={[
                    <Button key='cancel' onClick={() => onClose()}>
                        Cancel
                    </Button>,
                    <Button key='back' type='primary'
                        onClick={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}>
                        {!editMode ? 'Add Lead' : 'Edit Lead'}
                    </Button>,
                    editMode ? <Button
                        key='link'
                        type='primary'
                        onClick={onCreateUserButtonClickHandler}
                    //   loading={loading}
                    //   onClick={handleOk}
                    >
                        Create Customer
                    </Button> : ''
                ]}

                maskClosable={false}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    {
                        editMode ?
                            <Form.Item
                                name='id'
                                label='ID'
                                className='d-flex'
                                rules={[
                                    { required: true, message: 'Please enter the Id' },
                                ]}
                            >
                                <Input onChange={(event) => updateFormFields('id', event?.target.value)} />
                            </Form.Item>
                            :
                            ''
                    }
                    <Form.Item
                        name='first_name'
                        label='First Name'
                        rules={[
                            { required: true, message: 'Please enter the first name' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('first_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='last_name'
                        label='Last Name'
                        className='d-flex'

                    // rules={ [
                    //     { required: true, message: 'Please enter the last name' }
                    // ] }
                    >
                        <Input onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='contact_time'
                        label='Contact Time'
                        rules={[
                            { required: false, message: 'Please enter the contact time' }
                        ]}
                        initialValue={selectedDate || dayjs()}
                    >
                        <ConfigProvider locale={locale}>
                            <DatePicker
                                showTime={true}
                                style={{ width: '100%' }}
                                value={selectedDate || dayjs()}
                                onChange={(date: any, dateString) => {
                                    setSelectedDate(date || dayjs());
                                    form.setFieldsValue({ 'contact_time': dateString || dayjs().toISOString() });
                                }}
                                changeOnBlur
                            />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        name='cnic_number'
                        label='Cnic Number'
                        rules={[
                            {
                                required: false, message: 'Please enter correct cnic number like xxxxx-xxxxxxx-x',
                                pattern: regexCollection.cnic
                            }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('cnic_number', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='email'
                        label='Email '
                        rules={[
                            { required: false, message: 'Please enter the email' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('email', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='mobile'
                        label='Mobile'
                        rules={[
                            {
                                required: true, message: 'Please enter correct mobile like 03xxxxxxxxx',
                                pattern: regexCollection.phone
                            }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('mobile', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='city'
                        label='City'
                        rules={[
                            { required: false, message: 'Please enter the city ' }
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {cities.map((o, i) => (
                                <Select.Option key={`option-${i}`} value={o}>
                                    {o}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='brand_id'
                        label='Brand'
                        rules={[
                            { required: false, message: 'Please select the Brand' }
                        ]}
                    >
                        <Select
                            placeholder='Select a Brand'
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                (String(option?.label || '').toLowerCase() ?? '').includes(input.toLowerCase() || '')
                            }
                            options={MapToSelectOption(brands, 'name', 'id')}
                            showSearch={true}
                        />
                    </Form.Item>
                    <Form.Item
                        name='model'
                        label='Model'
                        rules={[
                            { required: false, message: 'Please enter the model' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('model', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='organization_name'
                        label='Organization Name'
                        rules={[
                            { required: false, message: 'Please enter the Organization Name' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('organization_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='segment'
                        label='Segment '
                        rules={[
                            { required: false, message: 'Please enter the segment' },
                        ]}
                        initialValue={SegmentEnumerator.B2C}
                    >
                        <Select onChange={(value) => updateFormFields('segment', value)}>
                            {
                                getEnumeratorKeys(SegmentEnumerator).map(
                                    (key) => <Select.Option key={key}
                                        value={SegmentEnumerator[key as keyof typeof SegmentEnumerator]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='channel_id'
                        label='Channel'
                        rules={[
                            { required: true, message: 'Please select Channel' }
                        ]}
                    >
                        <Select
                            placeholder='Select a Channel'
                            tagRender={TagsDropdown}
                            onChange={(selectedItemIds: string) => updateFormFields('channel_id', selectedItemIds)}
                            className='w-full'
                            options={MapToSelectOption(channels, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='product_type_id'
                        label='Product Type'
                        rules={[
                            { required: false, message: 'Please select the Product Type' }
                        ]}
                    >
                        <Select
                            placeholder='Select a Product Type'
                            tagRender={TagsDropdown}
                            onChange={(selectedItemIds: string) => updateFormFields('product_type', selectedItemIds)}
                            className='w-full'
                            options={MapToSelectOption(productType, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='lead_status'
                        label='Lead Status'
                        rules={[
                            { required: false, message: 'Please enter the Lead Status' },
                        ]}
                        initialValue={LeadStatusEnum.NEW_LEAD}
                    >
                        <Select onChange={(value) => updateFormFields('lead_status', value)}>
                            {
                                getEnumeratorKeys(LeadStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={LeadStatusEnum[key as keyof typeof LeadStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='comments'
                        label='Comments'
                        rules={[
                            { required: false, message: 'Please enter the comment' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('comments', event?.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};