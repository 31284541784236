import React, { useEffect, useState } from 'react';
import { Button, Image, message, Modal } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DocumentTypeSearch, IDocumentEntity, IItemsWithCount, KeyValueRecord } from '@rasayi-workspace/shared';
import { GetItems, } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps } from '@interfaces';
import { CloudDownloadOutlined, FileOutlined } from '@ant-design/icons';
import { handleDownloadImage } from '@helpers';

export const ViewDocumentModalComponent = ({ onClose, onSubmit, editMode = false, id: complianceId = '' }: AddEditModalProps<IDocumentEntity>) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [complianceDocument, setComplianceDocument] = useState<any>([]);
    const [downloading, setDownloading] = useState(false);

    const { refetch: complianceDocumentsOne, isFetching: isDocumentsFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getComplianceDocument'],
        queryFn: () => {
            const queryParams = new URLSearchParams({
                complianceId: complianceId
            });

            return GetItems<KeyValueRecord>('document/compliance?' + queryParams.toString());
        },
        onSuccess: (returnedResult: IItemsWithCount<KeyValueRecord>): void => setComplianceDocument(returnedResult),
        onError: (err) => setComplianceDocument([]),
    });

    useEffect(() => {
        !!complianceId && !isDocumentsFetching && complianceDocumentsOne();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDownload = async (image: KeyValueRecord) => {
        try {
            setDownloading(true);
            await handleDownloadImage(image);
            setDownloading(false);
        } catch (error) {
            console.error('Error while downloading:', error);
            setDownloading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Customer' : 'View Documents'}
                open={true}
                confirmLoading={isDocumentsFetching}
                onOk={() => onClose()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <h2>
                    Documents
                </h2>
                {
                    complianceDocument &&
                    complianceDocument?.map((image: KeyValueRecord, index: number) => {
                        return <div className='flex-container'
                            style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                            {!image?.isPdf ?
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.url?.includes(el))?.toUpperCase()}
                                    </h4>
                                    <Image
                                        key={index}
                                        width={400}
                                        src={image?.fileContent}
                                    />
                                </div>
                                :
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.url?.includes(el))?.toUpperCase()}
                                    </h4>

                                    <div className='w-[400px] flex justify-center'><FileOutlined /></div>
                                </div>
                            }
                            <div>
                                <Button
                                    className=' '
                                    type='primary'
                                    icon={<CloudDownloadOutlined />}
                                    style={{ marginLeft: 10, marginBottom: 5 }}
                                    onClick={() => handleDownload(image)}
                                    loading={downloading}
                                />
                            </div>
                        </div>;
                    })
                }
            </Modal>
        </>
    );
};