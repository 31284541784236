export const PROVINCES_AND_CITIES: Record<string, string[]> = {
    ISLAMABAD: ['Islamabad'],
    PUNJAB: [
        'Lahore',
        'Rawalpindi',
        'Multan',
        'Sargodha',
        'Ahmed Nager Chatha',
        'Ahmadpur East',
        'Ali Khan Abad',
        'Alipur',
        'Arifwala',
        'Attock',
        'Bhera',
        'Bhalwal',
        'Bahawalnagar',
        'Bahawalpur',
        'Bhakkar',
        'Burewala',
        'Chillianwala',
        'Chakwal',
        'Chichawatni',
        'Chiniot',
        'Chishtian',
        'Daska',
        'Darya Khan',
        'Dera Ghazi Khan',
        'Dhaular',
        'Dina',
        'Dinga',
        'Dipalpur',
        'Faisalabad',
        'Ferozewala',
        'Fateh Jhang',
        'Ghakhar Mandi',
        'Gojra',
        'Gujranwala',
        'Gujrat',
        'Gujar Khan',
        'Hafizabad',
        'Haroonabad',
        'Hasilpur',
        'Haveli Lakha',
        'Jatoi',
        'Jalalpur',
        'Jattan',
        'Jampur',
        'Jaranwala',
        'Jhang',
        'Jhelum',
        'Kalabagh',
        'Karor Lal Esan',
        'Kasur',
        'Kamalia',
        'Kamoke',
        'Khanewal',
        'Khanpur',
        'Kharian',
        'Khushab',
        'Kot Addu',
        'Jauharabad',
        'Lalamusa',
        'Layyah',
        'Liaquat Pur',
        'Lodhran',
        'Malakwal',
        'Mamoori',
        'Mailsi',
        'Mandi Bahauddin',
        'Mian Channu',
        'Mianwali',
        'Murree',
        'Muridke',
        'Mianwali Bangla',
        'Muzaffargarh',
        'Narowal',
        'Nankana Sahib',
        'Okara',
        'Renala Khurd',
        'Pakpattan',
        'Pattoki',
        'Pir Mahal',
        'Qaimpur',
        'Qila Didar Singh',
        'Rabwah',
        'Raiwind',
        'Rajanpur',
        'Rahim Yar Khan',
        'Sadiqabad',
        'Safdarabad',
        'Sahiwal',
        'Sangla Hill',
        'Sarai Alamgir',
        'Shakargarh',
        'Sheikhupura',
        'Sialkot',
        'Sohawa',
        'Soianwala',
        'Siranwali',
        'Talagang',
        'Taxila',
        'Toba Tek Singh',
        'Vehari',
        'Wah Cantonment',
        'Wazirabad'
    ],
    SINDH: [
        'Karachi',
        'Hyderabad',
        'Badin',
        'Bhirkan',
        'Rajo Khanani',
        'Chak',
        'Dadu',
        'Digri',
        'Diplo',
        'Dokri',
        'Ghotki',
        'Haala',
        'Islamkot',
        'Jacobabad',
        'Jamshoro',
        'Jungshahi',
        'Kandhkot',
        'Kandiaro',
        'Kashmore',
        'Keti Bandar',
        'Khairpur',
        'Kotri',
        'Larkana',
        'Matiari',
        'Mehar',
        'Mirpur Khas',
        'Mithani',
        'Mithi',
        'Mehrabpur',
        'Moro',
        'Nagarparkar',
        'Naudero',
        'Naushahro Feroze',
        'Naushara',
        'Nawabshah',
        'Nazimabad',
        'Qambar',
        'Qasimabad',
        'Ranipur',
        'Ratodero',
        'Rohri',
        'Sakrand',
        'Sanghar',
        'Shahbandar',
        'Shahdadkot',
        'Shahdadpur',
        'Shahpur Chakar',
        'Shikarpaur',
        'Sukkur',
        'Tangwani',
        'Tando Adam Khan',
        'Tando Allahyar',
        'Tando Muhammad Khan',
        'Thatta',
        'Umerkot',
        'Warah'
    ],
    KPK: [
        'Peshawar',
        'Abbottabad',
        'Adezai',
        'Alpuri',
        'Akora Khattak',
        'Ayubia',
        'Banda Daud Shah',
        'Bannu',
        'Batkhela',
        'Battagram',
        'Birote',
        'Chakdara',
        'Charsadda',
        'Chitral',
        'Daggar',
        'Dargai',
        'Darya Khan',
        'Dera Ismail Khan',
        'Doaba',
        'Dir',
        'Drosh',
        'Hangu',
        'Haripur',
        'Karak',
        'Kohat',
        'Kulachi',
        'Lakki Marwat',
        'Latamber',
        'Madyan',
        'Mansehra',
        'Mardan',
        'Mastuj',
        'Mingora',
        'Nowshera',
        'Paharpur',
        'Pabbi',
        'Saidu Sharif',
        'Shorkot',
        'Shewa Adda',
        'Swabi',
        'Swat',
        'Tangi',
        'Tank',
        'Thall',
        'Timergara',
        'Tordher'
    ],
    BALOCHISTAN: [
        'Quetta',
        'Gwadar',
        'Awaran',
        'Barkhan',
        'Chagai',
        'Dera Bugti',
        'Harnai',
        'Jafarabad',
        'Jhal Magsi',
        'Kacchi',
        'Kalat',
        'Kech',
        'Kharan',
        'Khuzdar',
        'Killa Abdullah',
        'Killa Saifullah',
        'Kohlu',
        'Lasbela',
        'Lehri',
        'Loralai',
        'Mastung',
        'Musakhel',
        'Nasirabad',
        'Nushki',
        'Panjgur',
        'Pishin Valley',
        'Sherani',
        'Sibi',
        'Sohbatpur',
        'Washuk',
        'Zhob',
        'Ziarat'
    ],
    GILGIT_BALTISTAN: [
        'Gilgit',
        'Skardu',
        'Karimabad (Hunza)',
        'Askole',
        'Astore',
        'Besham',
        'Bunji',
        'Chilas',
        'Chillinji',
        'Chiran',
        'Dainyor',
        'Gakuch',
        'Ghangche',
        'Ghizer',
        'Gulmit',
        'Jaglot',
        'Ishkoman',
        'Khaplu',
        'Misgar',
        'Passu',
        'Shimshal',
        'Sust',
        'Taghafari',
        'Thorar'
    ],
    AZAD_KASHMIR: [
        'Mirpur',
        'Kotli',
        'Bhimber',
        'Muzaffarabad',
        'Hattian',
        'Neelam Valley',
        'Poonch',
        'Haveli',
        'Bagh',
        'Sudhanoti'
    ]
};