import { useEffect, useState } from 'react';
import { Divider, Form, message, Modal, Radio, Table, TableProps, Tooltip } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IInstalmentEntity, IOrderDTO, IOrderEntity, ITransactionDTO, ITransactionEntity, KeyValueRecord, removeWhiteSpaceFromAllProperties, transformToFormattedTime } from '@rasayi-workspace/shared';
import { GetItem, PostItem } from '@services';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { AddEditModalProps, ErrorResponse, ITableState } from '@interfaces';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { PayInstallmentModalComponent } from './pay-installments';

export const ViewOrderInsallmentsModalComponent = ({ onClose, onSubmit, viewMode = false, id: orderId = '' }: AddEditModalProps<IInstalmentEntity>) => {
    const [form] = Form.useForm<Partial<IOrderDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [orderInstallments, setOrderInstallment] = useState<IInstalmentEntity | any>();
    const [isValidForm, setIsValidForm] = useState(false);
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [isPayInstallmentModalOpened, setIsPayInstallmentModalOpened] = useState(false);
    const [payInstallmentOrderId, setPayInstallmentOrderId] = useState('');

    const { refetch: fetchOrderInstallment, isFetching: isOrderInstallmentFetching } = useQuery<IInstalmentEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getOrderInstallments'],
        queryFn: () => GetItem<IInstalmentEntity>(
            `order/${orderId}/instalment`,
            '',
            ['order'],
            []
        ),
        onSuccess: (result: IInstalmentEntity): void => {
            setOrderInstallment(result);
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { mutate: payInstallment } = useMutation<ITransactionEntity, AxiosError>({
        mutationKey: ['payIstallment'],
        mutationFn: async () =>
            PostItem<ITransactionEntity, ITransactionDTO>(
                'transaction/instalment',
                removeWhiteSpaceFromAllProperties({ order_id: orderId }) as Partial<any>
            ),
        onSuccess: async (item: any): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IInstalmentEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IInstalmentEntity>).columnKey as keyof IInstalmentEntity]: MapAntDesignSortOrder((sorter as SorterResult<IInstalmentEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const onPayInstallmentModalCloseHandler = (newOrder: IOrderEntity) => {
        // refetch();
        resetModal();

        messageApi.open(
            !payInstallmentOrderId ? {
                type: 'success',
                content: `New order '${newOrder?.user + ' ' + newOrder?.total_customer_price}' created!`,
            } : {
                type: 'success',
                content: `Order '${newOrder?.user + ' ' + newOrder?.total_customer_price}' updated!`,
            }
        );
    }

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsPayInstallmentModalOpened(false);
        setPayInstallmentOrderId('')
    }

    const handlePayInstallmentButton = () => {

        setPayInstallmentOrderId(orderId);
        setIsPayInstallmentModalOpened(true)
    }

    const columns: ColumnsType<IInstalmentEntity> = [
        {
            title: 'Id',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Order ID',
            dataIndex: ['order', 'internal_id'],
            key: 'order.internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'paid ',
            key: 'paid',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IInstalmentEntity) => {
                const paid = item?.paid?.toString()

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            paid
                        }
                    </span>
                </Tooltip>;
            },
        },
        {
            title: 'instalment_status',
            dataIndex: 'instalment_status',
            key: 'instalment_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'payment_type',
            key: 'payment_type.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IInstalmentEntity) => {
                const payment_type = item.payment_type?.name;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            payment_type
                        }
                    </span>
                </Tooltip>;
            }
        },
        {
            title: 'paid_amount',
            dataIndex: 'paid_amount',
            key: 'paid_amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Total amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Due Date',
            dataIndex: 'expected_datetime',
            key: 'expected_datetime',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (value: string) => transformToFormattedTime(value),
        },
        {
            title: 'Paid Date',
            dataIndex: 'paid_datetime',
            key: 'paid_datetime',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (value: string) => value ? transformToFormattedTime(value) : undefined,
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        }
    ];

    useEffect(() => {
        fetchOrderInstallment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // console.log(orderInstallments, 'orderInstallments')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInstallments]);

    // console.log(orderId, 'orderId hello')

    return (
        <>
            {contextHolder}
            {
                isPayInstallmentModalOpened &&
                <PayInstallmentModalComponent
                    editMode={!!payInstallmentOrderId}
                    id={payInstallmentOrderId}
                    onClose={onModalCloseHandler}
                    onSubmit={onPayInstallmentModalCloseHandler}
                />
            }
            <Radio.Group
                onChange={({ target: { value } }) => {
                    setSelectionType(value);
                }}
                value={selectionType}
            >
                <Radio value="checkbox">Checkbox</Radio>
                <Radio value="radio">radio</Radio>
            </Radio.Group>

            <Divider />
            <Modal
                title={'View order installments'}
                open={true}
                confirmLoading={isOrderInstallmentFetching}
                // okText={ 'Pay installments' }
                onOk={() => onClose()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
                width={1100}
            >
                <Table
                    columns={columns}
                    dataSource={orderInstallments}
                    onChange={handleChange}
                    loading={isOrderInstallmentFetching}
                    rowKey={'id'}
                    size='large'
                    scroll={{ x: true }}
                />
            </Modal>
        </>
    );
};