import { useEffect, useState } from 'react';
import { Form, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ComplianceStatusEnum, IComplianceDTO, IComplianceEntity, IDeliveryEntity, IItemsWithCount, getEnumeratorKeys, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import dayjs from 'dayjs';

export const ComplianceModalComponent = ({ onClose, onSubmit, editMode = false, id: complianceId = '' }: AddEditModalProps<IComplianceEntity>) => {
    const [form] = Form.useForm<Partial<IComplianceDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [compliance, setCompliance] = useState<Partial<IComplianceEntity>>();
    const [delivery, setDelivery] = useState<IDeliveryEntity[]>([]);
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<dayjs.Dayjs | null>(null);

    const { mutate: createCompliance, isLoading } = useMutation<IComplianceEntity, AxiosError>({
        mutationKey: ['createCompliance'],
        mutationFn: async () =>
            PostItem<IComplianceEntity, IComplianceDTO>(
                'compliance',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IComplianceDTO>
            ),
        onSuccess: async (item: IComplianceEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateCompliance, isLoading: isComplianceUpdating } = useMutation<IComplianceEntity, AxiosError>({
        mutationKey: ['updateCompliance'],
        mutationFn: async () =>
            PutItem<IComplianceEntity, IComplianceDTO>(
                'compliance',
                complianceId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IComplianceDTO>
            ),
        onSuccess: async (item: IComplianceEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchCompliance, isFetching: isComplianceFetching } = useQuery<IComplianceEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getCompliance'],
        queryFn: () => GetItem<IComplianceEntity>(
            'compliance',
            complianceId || '',
            []
        ),
        onSuccess: (result: IComplianceEntity): void => {
            setCompliance(compliance);
            form.setFieldsValue({
                ...result,
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchDelivery, isFetching } = useQuery<IItemsWithCount<IDeliveryEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getDelivery'],
        queryFn: () => GetItems<IDeliveryEntity>(
            'delivery',
            [],
            []
        ),
        onSuccess: (returnedResult: IItemsWithCount<IDeliveryEntity>): void => setDelivery(returnedResult.items),
        onError: () => setDelivery([])
    });

    const updateFormFields = (property: string | number, value: string | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createCompliance())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateCompliance())
        .catch(() => setIsValidForm(false));

    // const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
    //     setSelectedDateOfBirth(date);
    //     form.setFieldsValue({ 'delivery_datetime': dateString });
    // };

    useEffect(() => {
        fetchCompliance();
        fetchDelivery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Compliance' : 'Edit Compliance'}
                open={true}
                confirmLoading={isLoading || isComplianceFetching || isComplianceUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Compliance' : 'Edit Compliance'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='complete_contract'
                        label='Complete contract'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='signature'
                        label='Signature'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='thumb'
                        label='Thumb'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='box_collection'
                        label='Box collection'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='cheque_collection'
                        label='Cheque collection'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='downpayment_collection'
                        label='Deposit Confirmed'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='customer_picture'
                        label='Customer picture'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='delivery_location_picture'
                        label='Delivery Spot'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='lock_picture'
                        label='lock_picture'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='product_receipt'
                        label='Product receipt'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='downpayment_receipt'
                        label='Downpayment receipt'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='customer_cnic'
                        label='Customer cnic'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='guarantor_cnic'
                        label='Guarantor cnic'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='guarantor_picture'
                        label='Guarantor picture'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='bank_statement'
                        label='Bank statement'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='utility_bill'
                        label='Utility bill'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='compliance_status'
                        label='Compliance status'
                        rules={[
                            { required: true, message: 'Please enter the compliance status' }
                        ]}
                        initialValue={ComplianceStatusEnum.PENDING}
                    >
                        <Select onChange={(value) => updateFormFields('compliance_status', value)}>
                            {
                                getEnumeratorKeys(ComplianceStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={ComplianceStatusEnum[key as keyof typeof ComplianceStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    {/* <Form.Item
                        name='compliance_comments'
                        label='compliance_comments'
                        rules={[
                            { required: true, message: 'Please enter the compliance_comments' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('compliance_comments', event?.target.value)} />
                    </Form.Item> */ }
                </Form>
            </Modal>
        </>
    );
};