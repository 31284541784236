import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IItemsWithCount, IVendorBankDetailEntity, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { VendorBankDetailModalComponent } from './add-edit';

const { Content } = Layout;

const VendorBankDetailPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [vendorBankDetailsData, setVendorBankDetailsData] = useState<IItemsWithCount<IVendorBankDetailEntity>>(EMPTY_INITIAL_ITEMS);
    const [isVendorBankDetailModalOpened, setIsVendorBankDetailModalOpened] = useState(false);
    const [editingVendorBankDetailId, setEditingVendorBankDetailId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IVendorBankDetailEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['vendorBankDetails'],
        queryFn: () => GetTableItems<IVendorBankDetailEntity>(
            'vendor_bank_detail',
            ['vendor'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IVendorBankDetailEntity>): void => setVendorBankDetailsData(returnedResult),
        onError: () => setVendorBankDetailsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteVendorBankDetail } = useMutation<IVendorBankDetailEntity, AxiosError>({
        mutationKey: ['deleteVendorBankDetail'],
        mutationFn: async () =>
            DeleteItem<IVendorBankDetailEntity>(
                'vendor_bank_detail',
                editingVendorBankDetailId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `VendorBankDetail deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IVendorBankDetailEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IVendorBankDetailEntity>).columnKey as keyof IVendorBankDetailEntity]: MapAntDesignSortOrder((sorter as SorterResult<IVendorBankDetailEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onVendorBankDetailModalCloseHandler = (newVendorBankDetail: IVendorBankDetailEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingVendorBankDetailId ? {
                type: 'success',
                content: `New vendorBankDetail '${ newVendorBankDetail?.id + ' ' + newVendorBankDetail?.account_title }' created!`,
            } : {
                type: 'success',
                content: `VendorBankDetail '${ newVendorBankDetail?.id + ' ' + newVendorBankDetail?.account_title }' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsVendorBankDetailModalOpened(false);
        setEditingVendorBankDetailId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item) => !item.dataKey) || []
        });
    };

    const columns: ColumnsType<IVendorBankDetailEntity> = [
        {
            title: 'Vendor Name',
            dataIndex: ['vendor', 'vendor_name'],
            key: 'vendor.vendor_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('vendor.vendor_name', 'by vendor name', resetSearchHandler)
        },
        {
            title: 'Bank name',
            dataIndex: 'bank_name',
            key: 'bank_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('bank_name', 'by bank_name', resetSearchHandler)
        },
        {
            title: 'Account title',
            dataIndex: 'account_title',
            key: 'account_title',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('account_title', 'by account_title', resetSearchHandler)
        },
        {
            title: 'Account number',
            dataIndex: 'account_number',
            key: 'account_number',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('account_number', 'by account_number', resetSearchHandler)
        },
        {
            title: 'is_verified',
            key: 'is_verified',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IVendorBankDetailEntity) => {
                const is_verified = item?.is_verified?.toString();

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            is_verified
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('is_verified', 'by true/false', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, account_title = '', account_number = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EditOutlined/> }
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingVendorBankDetailId(id);
                                setIsVendorBankDetailModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={ <DeleteOutlined/> }
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={
                            () => {
                                setEditingVendorBankDetailId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete vendorBankDetail '${ account_title + ' ' + account_number }'?`,
                                    onOk: () => {
                                        deleteVendorBankDetail();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isVendorBankDetailModalOpened &&
                <VendorBankDetailModalComponent
                    editMode={ !!editingVendorBankDetailId }
                    id={ editingVendorBankDetailId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onVendorBankDetailModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Vendor Bank Detail'
                                addButtonText='Add Vendor Bank Detail'
                                // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                                onClick={ () => setIsVendorBankDetailModalOpened(true) }
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ vendorBankDetailsData.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: vendorBankDetailsData.count,
                                        pageSize: vendorBankDetailsData.pageSize,
                                        current: vendorBankDetailsData.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default VendorBankDetailPageComponent;