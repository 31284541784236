export enum PermissionsEnum {
    USER_FULL = 'user_full',
    USER_READ = 'user_read',
    USER_WRITE = 'user_write',
    USER_DELETE = 'user_delete',

    ROLE_FULL = 'role_full',
    ROLE_READ = 'role_read',
    ROLE_WRITE = 'role_write',
    ROLE_DELETE = 'role_delete',

    GROUP_FULL = 'group_full',
    GROUP_READ = 'group_read',
    GROUP_WRITE = 'group_write',
    GROUP_DELETE = 'group_delete',

    PERMISSION_FULL = 'permission_full',
    PERMISSION_READ = 'permission_read',
    PERMISSION_WRITE = 'permission_write',
    PERMISSION_DELETE = 'permission_delete',

    ORDER_FULL = 'order_full',
    ORDER_READ = 'order_read',
    ORDER_WRITE = 'order_write',
    ORDER_DELETE = 'order_delete',
    ACTIVITY_LOGS = 'activity_logs',
}