import { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IItemsWithCount, IVendorBankDetailDTO, IVendorBankDetailEntity, IVendorEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const VendorBankDetailModalComponent = ({ onClose, onSubmit, editMode = false, id: vendorBankDetailId = '' }: AddEditModalProps<IVendorBankDetailEntity>) => {
    const [form] = Form.useForm<Partial<IVendorBankDetailDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [vendorBankDetail, setVendorBankDetail] = useState<Partial<IVendorBankDetailEntity>>();
    const [vendors, setVendors] = useState<IVendorEntity[]>([]);


    const { mutate: createVendorBankDetail, isLoading } = useMutation<IVendorBankDetailEntity, AxiosError>({
        mutationKey: ['createVendorBankDetail'],
        mutationFn: async () =>
            PostItem<IVendorBankDetailEntity, IVendorBankDetailDTO>(
                'vendor_bank_detail',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IVendorBankDetailDTO>
            ),
        onSuccess: async (item: IVendorBankDetailEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateVendorBankDetail, isLoading: isVendorBankDetailUpdating } = useMutation<IVendorBankDetailEntity, AxiosError>({
        mutationKey: ['updateVendorBankDetail'],
        mutationFn: async () =>
            PutItem<IVendorBankDetailEntity, IVendorBankDetailDTO>(
                'vendor_bank_detail',
                vendorBankDetailId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IVendorBankDetailDTO>
            ),
        onSuccess: async (item: IVendorBankDetailEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchVendorBankDetail, isFetching: isVendorBankDetailFetching } = useQuery<IVendorBankDetailEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getVendorBankDetail'],
        queryFn: () => GetItem<IVendorBankDetailEntity>(
            'vendor_bank_detail',
            vendorBankDetailId || '',
        ),
        onSuccess: (result: IVendorBankDetailEntity): void => {
            setVendorBankDetail(vendorBankDetail);
            form.setFieldsValue({
                ...result,
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchVendors, isFetching: isVendorFetching } = useQuery<IItemsWithCount<IVendorEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getVendors'],
        queryFn: () => GetItems<IVendorEntity>(
            'vendor',
            [],
            ['id', 'first_name', 'last_name', 'vendor_name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IVendorEntity>): void => setVendors(returnedResult.items),
        onError: () => setVendors([])
    });

    const updateFormFields = (property: string, value: string) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createVendorBankDetail())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateVendorBankDetail())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        !isVendorFetching && fetchVendors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode)
            !isVendorBankDetailFetching && fetchVendorBankDetail();
        !isVendorFetching && fetchVendors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add VendorBankDetail' : 'Edit VendorBankDetail'}
                open={true}
                confirmLoading={isLoading || isVendorBankDetailFetching || isVendorBankDetailUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add VendorBankDetail' : 'Edit VendorBankDetail'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='vendor_id'
                        label='Vendor'
                        rules={ [{ required: true, message: 'Please Select Vendor' }] }
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(vendors, 'vendor_name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='bank_name'
                        label='bank_name'
                        rules={[
                            { required: true, message: 'Please enter the Bank name' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('bank_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='branch_name'
                        label='branch_name'
                        rules={[
                            { required: false, message: 'Please enter the Branch Name' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('branch_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='account_title'
                        label='account_title'
                        rules={[
                            { required: true, message: 'Please enter the Account Title' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('account_title', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='account_number'
                        label='account_number'
                        rules={[
                            { required: true, message: 'Please enter the Account Number' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('account_number', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='iban'
                        label='iban'
                        rules={[
                            { required: false, message: 'Please enter the IBAN' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('iban', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='is_verified'
                        label='is_verified'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};