import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Card, Col, Form, Input, Layout, message, Row, Steps } from 'antd';
import { FilterOperatorsEnum, IInventoryEntity, IOrderDTO, IOrderEntity, IUserEntity, removeWhiteSpaceFromAllProperties, UserRolesEnum } from '@rasayi-workspace/shared';
import { PostItem } from '@services';
import { BaseLayoutComponent, SearchableDropdown } from '@components';
import { orderContext } from '@contexts';
import { OrderCompletePageComponent } from '../order-complete';
import { DeliveryInformationPageComponent } from '../delivery-information';
import { ErrorResponse } from '@interfaces';

const { Content } = Layout;

export const GuarantorInformationPageComponent = () => {
    const [form] = Form.useForm<Partial<any>>();
    const {
        inventories, setInitialInventories, setGuarantorId, orderDetails,
        setOrderDetails, setInventories, setUserId, user, setUser, setLastAccess
    } = useContext(orderContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [editingOrderId, setEditingOrderId] = useState('');
    const [selectedUser, setSelectedUser] = useState<any>();
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const completeOrder = {
        ...orderDetails,
        user_id: user.id,
        delivery_charges: orderDetails.delivery_charges || 0,
        service_charges: orderDetails.service_charges || 0,
        locking_charges: orderDetails.locking_charges || 0,
        guarantor_id: form.getFieldValue('guarantor_id'),
        inventory_info: { internal_ids: inventories.map((inventory: IInventoryEntity) => inventory.internal_id), retail_prices: inventories.map((inventory: IInventoryEntity) => inventory.retail_price) }
    };

    const { mutate: createOrder, isLoading } = useMutation<IOrderEntity, AxiosError>({
        mutationKey: ['createOrder'],
        mutationFn: async () =>
            PostItem<IOrderEntity, IOrderDTO>(
                completeOrder?.inventory_info?.internal_ids?.length ? 'order' : 'order/baseline',
                removeWhiteSpaceFromAllProperties(
                    {
                        ...completeOrder
                    }
                ) as Partial<IOrderDTO>
            ),
        onSuccess: async (item: IOrderEntity): Promise<void> => {
            onOrderModalCloseHandler(item);
            setNextPage(true);
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const onOrderModalCloseHandler = (newOrder: IOrderEntity) => {
        setInitialInventories([]);
        setOrderDetails({});
        setInventories([]);
        setGuarantorId('');
        setUserId('');
        setUser({});
        setLastAccess('');

        messageApi.open(
            !editingOrderId ? {
                type: 'success',
                content: `New order '${newOrder?.id}' created!`,
            } : {
                type: 'success',
                content: `Order '${newOrder?.id}' updated!`,
            }
        );
    };

    const createOrderHandler = () => {
        createOrder();
    };


    const previousPageHandler = () => {
        setPreviousPage(true);
    };

    useEffect(() => {
        if (orderDetails?.guarantor?.id) {
            setSelectedUser(orderDetails?.guarantor);
            setGuarantorId(orderDetails?.guarantor?.id);
            form.setFieldsValue({ ...orderDetails?.guarantor });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            {
                nextPage &&
                <OrderCompletePageComponent />
            }
            {
                previousPage &&
                <DeliveryInformationPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={4}
                        items={[
                            {
                                title: 'Customer Information',
                            },
                            {
                                title: 'Order Details',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Delivery Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Guarantor Information'
                        extra={<>
                            <Button
                                key='previousPage'
                                type='primary'
                                onClick={previousPageHandler}
                                style={{ marginLeft: 4 }}
                            >
                                Previous Page
                            </Button>
                            <Button
                                key='nextPage'
                                type='primary'
                                style={{ marginLeft: 4 }}
                                onClick={createOrderHandler}
                               // disabled={(inventories?.length ? !selectedUser : false)}
                            >
                                Create Order
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            form={form}
                        >
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='guarantor_id'
                                        label='Guarantor'
                                    >
                                        {
                                            SearchableDropdown<IUserEntity>(
                                                {
                                                    key: 'id',
                                                    defaultValue: orderDetails?.guarantor?.cnic_number || '',
                                                    sortBy: { 'cnic_number': 'ASC' },
                                                    value: 'cnic_number',
                                                    queryKey: ['GuarantorsUser'],
                                                    queryFnParams: {
                                                        apiRoute: 'user',
                                                        relations: [],
                                                        fields: ['id', 'first_name', 'cnic_number', 'last_name', 'primary_mobile', 'address_line1', 'city', 'email'],
                                                        filter: {
                                                            field: 'cnic_number',
                                                            operator: FilterOperatorsEnum.LIKE,
                                                            value: ''
                                                        },
                                                        predefinedFilters: [
                                                            {
                                                                field: 'roles.name',
                                                                operator: FilterOperatorsEnum.EQUAL,
                                                                value: UserRolesEnum.CUSTOMER
                                                            }
                                                        ]
                                                    },
                                                    onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                                        const selectedRow: any = items.find((user: IUserEntity) => user.id === selectedItemIds);
                                                        if (selectedRow) {
                                                            setSelectedUser(selectedRow);
                                                            setGuarantorId(selectedRow.id);
                                                            form.setFieldsValue({ ...selectedRow });
                                                        }
                                                        setOrderDetails({
                                                            ...orderDetails, guarantor_id: selectedUser?.id, guarantor: selectedRow
                                                        });
                                                    }
                                                }
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            {selectedUser ? <>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='first_name'
                                            label='First Name'
                                            rules={[
                                                { required: true, message: 'Please enter the first name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.first_name}
                                        >
                                            <Input disabled value={selectedUser?.first_name} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='last_name'
                                            label='Last Name'
                                            rules={[
                                                { required: true, message: 'Please enter the last name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.last_name}
                                        >
                                            <Input disabled value={selectedUser?.last_name}
                                                onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='cnic_number'
                                            label='Cnic Number'
                                            rules={[
                                                { required: true, message: 'Please enter the Cnic Number' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.cnic_number}
                                        >
                                            <Input disabled value={selectedUser?.cnic_number}
                                                onChange={(event) => updateFormFields('cnic_number', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='primary_mobile'
                                            label='Primary Mobile'
                                            rules={[
                                                { required: true, message: 'Please enter the Primary Mobile' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.primary_mobile}
                                        >
                                            <Input disabled value={selectedUser?.primary_mobile}
                                                onChange={(event) => updateFormFields('primary_mobile', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='city'
                                            label='City'
                                            rules={[
                                                { required: true, message: 'Please enter the city' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.city}
                                        >
                                            <Input disabled value={selectedUser?.city}
                                                onChange={(event) => updateFormFields('city', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='address_line1'
                                            label='Address 1'
                                            rules={[
                                                { required: true, message: 'Please enter the address_line1' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.address_line1}
                                        >
                                            <Input disabled value={selectedUser?.address_line1}
                                                onChange={(event) => updateFormFields('address_line1', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> : ''}
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};