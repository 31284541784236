import React, { useEffect, useState } from 'react';
import { Button, Image, message, Modal } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DocumentTypeSearch, IDocumentEntity, IItemsWithCount, KeyValueRecord } from '@rasayi-workspace/shared';
import { DeleteItem, GetItems, } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import { CloudDownloadOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { DeleteConfirmationModal } from '@components';
import { handleDownloadImage } from '@helpers';

export const ViewOrderDocumentModalComponent = ({ onClose, onSubmit, editMode = false, id = '' }: AddEditModalProps<IDocumentEntity>) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [userDocument, setUserDocument] = useState<any>([]);
    const [downloading, setDownloading] = useState(false); 

    const { refetch: userDocumentsOne, isFetching: isDocumentsFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getOrderDocument'],
        queryFn: () => {
            return GetItems<KeyValueRecord>('order/' + id + '/documents');
        },
        onSuccess: (returnedResult: IItemsWithCount<KeyValueRecord>): void => setUserDocument(returnedResult),
        onError: (err) => {
            console.log(err);
            setUserDocument([]);
        },
    });

    const handleDownload = async (image: KeyValueRecord) => {
        try {
            setDownloading(true);

            await handleDownloadImage({ fileContent: image?.[1], name: image?.[0] }); 

            setDownloading(false);

        } catch (err) {
            console.error(err);
            setDownloading(false); 
        }
    };

    useEffect(() => {
        !!id && !isDocumentsFetching && userDocumentsOne();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {contextHolder}
            <Modal
                title={'View Order Documents'}
                open={true}
                confirmLoading={isDocumentsFetching}
                onOk={() => onClose()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <h2>
                    Documents
                </h2>
                {
                    userDocument &&
                    Object.entries(userDocument)?.map((image: KeyValueRecord, index: number) => {
                        return (
                            <div className='flex-container'
                                style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                {!image?.[0].endsWith('.pdf') ?
                                    <div>
                                        <h4 key={image.id}>
                                            {Object.values(DocumentTypeSearch).find(el => image?.[0].includes(el))?.toUpperCase()}
                                        </h4>
                                        <Image
                                            key={index}
                                            width={400}
                                            src={image?.[1]}
                                        />
                                    </div>

                                    :
                                    <div>
                                        <h4 key={image.id}>
                                            {Object.values(DocumentTypeSearch).find(el => image?.[0].includes(el))?.toUpperCase()}
                                        </h4>
                                        <div className='w-[400px] flex justify-center'><FileOutlined /></div>
                                    </div>
                                }
                                <div>
                                    <Button
                                        className=' '
                                        type='primary'
                                        icon={<CloudDownloadOutlined />}
                                        style={{ marginLeft: 10, marginBottom: 5 }}
                                        onClick={() => handleDownload(image)}
                                        loading={downloading}
                                    />
                                </div>
                            </div>
                        );
                    })
                }
            </Modal>
        </>
    );
};