import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { ReactNode } from 'react';

const { confirm } = Modal;

export const DeleteConfirmationModal = ({ title = 'Are you sure?', content, onOk, onCancel, okButtonText = 'Yes' }: { title?: string, content?: string | ReactNode, onOk: () => void, onCancel?: () => void, okButtonText?: string }) => {
    confirm({
        title: title || 'Confirmation!',
        icon: <ExclamationCircleFilled />,
        content: content || 'Are you sure you want to delete this?',
        okText: okButtonText,
        cancelText: 'No',
        okType: 'danger',
        onOk,
        onCancel
    });
};