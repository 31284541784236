import { AxiosError } from 'axios';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Col, ConfigProvider, DatePicker, Divider, Form, Input, Layout, Row, Select, Steps, Switch, Tag } from 'antd';
import { AprTypeEnum, ChequeProviderEnum, ContractStatusEnum, FilterOperatorsEnum, getEnumeratorKeys, IBrandEntity, IChannelEntity, IConstantsEntity, IInstalmentEntity, IInstalmentPlanEntity, IItemsWithCount, IOrderEntity, KeyValueRecord, SegmentEnumerator } from '@rasayi-workspace/shared';
import { MapToSelectOption } from '@helpers';
import { BASE_QUERY_OPTIONS, PROVINCES_AND_CITIES } from '@constants';
import { GetItems } from '@services';
import { BaseLayoutComponent, SearchableDropdown } from '@components';
import { orderContext } from '@contexts';
import { ProductInformationPageComponent } from '../product-information';
import { CreateOrderPageComponent } from '../customer-information';
import { isUndefined } from '@nestjs/common/utils/shared.utils';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

const { Content } = Layout;

export const OrderDetailPageComponent = () => {
    const [form] = Form.useForm<Partial<any>>();
    const { setOrderDetails, orderDetails } = useContext(orderContext);
    const [brandDetails, setBrandDetails] = useState<KeyValueRecord>({});
    const [instalmentPlan, setInstalmentPlan] = useState<IInstalmentPlanEntity[]>([]);
    const [channel, setChannel] = useState<IChannelEntity[]>([]);
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [breakdownData, setBreakdownData] = useState<KeyValueRecord>({});
    const [constants, setConstants] = useState<KeyValueRecord>({});
    const [manualAPR, setManualAPR] = useState<boolean>(false);
    const [deliveryToggle, setDeliveryToggle] = useState<boolean>(!!orderDetails['delivery_charges'] || !!form.getFieldValue('delivery_charges') || false);
    const [serviceChargesToggle, setServiceChargesToggle] = useState<boolean>(!!orderDetails['service_charges'] || !!form.getFieldValue('service_charges') || false);
    const [instalmentMonths, setInstalmentMonths] = useState<number>(orderDetails?.instalmentMonths || 1);

    const { refetch: fetchConstants, isFetching: isConstantsFetching } = useQuery<IItemsWithCount<IConstantsEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['constantsOD'],
        queryFn: () => GetItems<IConstantsEntity>(
            'constants',
            [],
            ['id', 'name', 'value_int'],
            [
                [
                    {
                        field: 'name',
                        operator: FilterOperatorsEnum.IN,
                        value: `delivery_charges|service_charges`
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IConstantsEntity>): void => {
            const finalObject: KeyValueRecord = returnedResult?.items?.reduce((start, mid: IConstantsEntity) => {
                return { ...start, [mid.name]: parseInt(String(mid.value_int)) };
            }, {});

            setConstants(finalObject || {});
        },
        onError: () => setConstants({})
    });

    const { refetch: fetchInstalmentPlan, isFetching: isInstalmentPlanFetching } = useQuery<IItemsWithCount<IInstalmentPlanEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInstalmentPlans'],
        queryFn: () => GetItems<IInstalmentPlanEntity>(
            'instalment_plan',
            [],
            ['id', 'name', 'cycle', 'days']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IInstalmentPlanEntity>): void => setInstalmentPlan(returnedResult.items),
        onError: () => setInstalmentPlan([])
    });

    const { refetch: fetchChannel, isFetching: isChannelFetching } = useQuery<IItemsWithCount<IChannelEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getChannels'],
        queryFn: () => GetItems<IChannelEntity>(
            'channel',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IChannelEntity>): void => setChannel(returnedResult.items),
        onError: () => setChannel([])
    });

    const { refetch: fetchBreakdown } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['breakdown'],
        queryFn: () => {
            const queryParams = new URLSearchParams({
                total_retail_price: form.getFieldValue('total_retail_price') || 0,
                downpayment: form.getFieldValue('downpayment') || 0,
                instalment_plan_Id: form.getFieldValue('instalment_plan_id'),
                apr: (form.getFieldValue('apr_type') === 'manual' || orderDetails.apr_type === 'manual' ? form.getFieldValue('apr') : 0) || undefined,
                non_apr:
                    (form.getFieldValue('delivery_charges') || 0) +
                    (form.getFieldValue('service_charges') || 0) +
                    (form.getFieldValue('locking_charges') || 0)
            });

            return GetItems<KeyValueRecord>('order/breakdown?' + queryParams.toString());
        },
        onSuccess: (returnedResult: IItemsWithCount<KeyValueRecord>): void => { setBreakdownData(returnedResult); setIsValidForm(true) },
        onError: () => setBreakdownData({}),
    });

    const breakDownHandler = () => {
        if (form.getFieldValue('instalment_plan_id') && (form.getFieldValue('total_retail_price') >= (form.getFieldValue('downpayment') || 0))) {
            fetchBreakdown();
        } else {
            form.setFieldsValue({
                'total_customer_price': undefined,
                'apr': form.getFieldValue('apr_type') === 'manual' || orderDetails.apr_type === 'manual' ? form.getFieldValue('apr') : undefined,
                'lastInstalmentDate': undefined,
                'monthly_plan_value': undefined
            });
            setIsValidForm(false);
        }
    };

    useEffect(() => {
        if ((breakdownData && breakdownData.totalCustomerPrice !== undefined)) {
            const monthlyValue = Math.round(((breakdownData.totalCustomerPrice || 0) - (form.getFieldValue('downpayment') || 0)) / (instalmentMonths));

            form.setFieldsValue({
                'total_customer_price': breakdownData.totalCustomerPrice,
                'apr': form.getFieldValue('apr_type') === 'manual' || orderDetails.apr_type === 'manual' ? form.getFieldValue('apr') : breakdownData.aprValue,
                'lastInstalmentDate': breakdownData.lastInstalmentDate,
                'monthly_plan_value': monthlyValue
            });
        }

    }, [breakdownData]);

    const updateFormFields = (property: string | number | any, value: string | number | any) => {
        form.setFieldsValue({ [property]: value });
    };

    const getCharges = (): KeyValueRecord => {
        return {
            delivery_charges: form.getFieldValue('delivery_charges') ? constants['delivery_charges'] : 0,
            service_charges: form.getFieldValue('service_charges') ? constants['service_charges'] : 0
        };
    };

    const previousPageHandler = () => {
        setOrderDetails({
            ...orderDetails, ...form.getFieldsValue() as IOrderEntity, brandDetails, ...getCharges(), instalmentMonths: instalmentMonths
        });
        setPreviousPage(true);
    };

    const nextPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue() as IOrderEntity, brandDetails, ...getCharges(), instalmentMonths: instalmentMonths });
        setNextPage(true);
    };

    const handleFormChange = () => {
        const formValues = form.getFieldsValue();
        const fieldsToExclude = ['cheque_number', 'apr_type'];

        const emptyFields = Object.entries(formValues).filter(([key]) => !fieldsToExclude.includes(key))
            .some(([key, value]) => isUndefined(value));

        setIsValidForm(!emptyFields);
    };

    useEffect(() => {
        form.setFieldsValue({
            delivery_charges: orderDetails?.delivery_charges || false,
            service_charges: orderDetails?.service_charges || false,
            locking_charges: orderDetails?.locking_charges || false,
            monthly_plan_value: orderDetails?.monthly_plan_value || false
        });

        !isInstalmentPlanFetching && fetchInstalmentPlan();
        !isChannelFetching && fetchChannel();
        !isConstantsFetching && fetchConstants();

        if (orderDetails?.brandDetails && !brandDetails?.name)
            setBrandDetails(orderDetails?.brandDetails);

        handleFormChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        breakDownHandler();
    }, [manualAPR]);

    const cities = useMemo(
        () => Object
            .values(PROVINCES_AND_CITIES)
            .reduce(
                (aggregator, values) => ([...aggregator, ...values]), []
            )
            .sort(),
        []
    );

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {
                nextPage &&
                <ProductInformationPageComponent />
            }
            {
                previousPage &&
                <CreateOrderPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={1}
                        items={[
                            {
                                title: 'Customer Information',
                            },
                            {
                                title: 'Order Details',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Delivery Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Order Details'
                        extra={<>
                            <Button
                                key='previousPage'
                                type='primary'
                                onClick={previousPageHandler}
                                style={{ marginLeft: 4 }}
                            >
                                Previous Page
                            </Button>
                            <Button
                                key='nextPage'
                                type='primary'
                                style={{ marginLeft: 4 }}
                                onClick={nextPageHandler}
                                disabled={!isValidForm}
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            form={form}
                            onValuesChange={handleFormChange}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='total_retail_price'
                                        label='Total Retail Price'
                                        rules={[
                                            { required: true, message: 'Please enter the Total Retail Price', }
                                        ]}
                                        initialValue={orderDetails?.total_retail_price}
                                    >
                                        <Input
                                            onChange={(event) => {
                                                updateFormFields('total_retail_price', parseInt(event?.target.value) || undefined);
                                                breakDownHandler();
                                            }
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name='apr'
                                        label='apr'
                                        initialValue={breakdownData?.aprValue || orderDetails?.apr || undefined}
                                    >
                                        <Input
                                            type='number'
                                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                            value={breakdownData?.aprValue || orderDetails?.apr || undefined}
                                            disabled={orderDetails?.apr_type === false || 'manual' ? !manualAPR : manualAPR}
                                            onChange={(event) => {
                                                updateFormFields('apr', event?.target.value ? parseFloat(event?.target.value) : undefined);
                                                breakDownHandler();
                                            }}
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name='apr_type'
                                        label='Manual APR'
                                        initialValue={orderDetails?.apr_type === 'manual' ? 'manual' : 'automatic'}
                                    >
                                        <Switch
                                            onChange={(event) => {
                                                if (event === true) {
                                                    updateFormFields('apr_type', AprTypeEnum.MANUAL)
                                                } else {
                                                    updateFormFields('apr_type', AprTypeEnum.AUTOMATIC);
                                                }
                                                setManualAPR(event);
                                            }
                                            } />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_charges'
                                        label='Delivery Charges'
                                        initialValue={orderDetails?.delivery_charges || 0}
                                    >
                                        <Switch
                                            defaultChecked={deliveryToggle}
                                            onChange={(event) => {
                                                updateFormFields('delivery_charges', event ? (constants['delivery_charges'] || 400) : 0);
                                                event ? setDeliveryToggle(true) : setDeliveryToggle(false);
                                                breakDownHandler();
                                            }
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='brand_id'
                                        label='Brand'
                                        initialValue={orderDetails?.brandDetails?.name || ''}
                                        rules={[
                                            { required: false, message: 'Please select the brand.' },
                                        ]}
                                    >
                                        {
                                            SearchableDropdown<IBrandEntity>(
                                                {
                                                    key: 'id',
                                                    defaultValue: orderDetails?.brandDetails?.name || '',
                                                    sortBy: { 'name': 'ASC' },
                                                    value: 'name',
                                                    queryKey: ['getBrandCharges'],
                                                    queryFnParams: {
                                                        apiRoute: 'brand/charges',
                                                        relations: ['brand_charges'],
                                                        fields: [],
                                                        filter: {
                                                            field: 'name',
                                                            operator: FilterOperatorsEnum.LIKE,
                                                            value: ''
                                                        },
                                                        predefinedFilters: [
                                                            {
                                                                field: 'brand_charges.charges',
                                                                operator: FilterOperatorsEnum.NOT_NULL,
                                                                value: 'garbage'
                                                            }
                                                        ]
                                                    },
                                                    onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                                        const selectedOption: IBrandEntity | undefined = items.find((user: IBrandEntity) => user.id === selectedItemIds);
                                                        if (selectedOption) {
                                                            updateFormFields('locking_charges', selectedOption?.brand_charge?.charges);
                                                            setBrandDetails(selectedOption);
                                                        }
                                                        breakDownHandler();
                                                    }
                                                }
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='service_charges'
                                        label='Service Charges'
                                        initialValue={orderDetails?.service_charges || 0}
                                    >
                                        <Switch
                                            defaultChecked={serviceChargesToggle}
                                            onChange={(event) => {
                                                updateFormFields('service_charges', event ? (constants['service_charges'] || 600) : 0);
                                                event ? setServiceChargesToggle(true) : setServiceChargesToggle(false);
                                                breakDownHandler();
                                            }
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='locking_charges'
                                        label='Locking Charges'
                                        initialValue={brandDetails?.brand_charge?.charges || orderDetails?.brandDetails?.brand_charge?.charges || orderDetails?.locking_charges || 0}
                                    >
                                        <Input
                                            value={brandDetails?.brand_charge?.charges || orderDetails?.brandDetails?.brand_charge?.charges || orderDetails?.locking_charges || 0}
                                            disabled
                                            onChange={(event) => updateFormFields('Locking Charges', event?.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='lastInstalmentDate'
                                        label='lastInstalmentDate'
                                        rules={[
                                            { required: true, message: 'Please enter the lastInstalmentDate' },
                                            { min: 2, message: 'At least 2 characters' }
                                        ]}
                                        initialValue={
                                            breakdownData?.lastInstalmentDate ? dayjs(breakdownData?.lastInstalmentDate) :
                                                orderDetails?.lastInstalmentDate ? dayjs(orderDetails?.lastInstalmentDate) : undefined}

                                    >
                                        <ConfigProvider locale={locale}>
                                            <DatePicker
                                                disabled
                                                value={dayjs(breakdownData?.lastInstalmentDate)}
                                                showTime={false}
                                                style={{ width: '100%' }}
                                            />
                                        </ConfigProvider>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='total_customer_price'
                                        label='total_customer_price'
                                        rules={[
                                            { required: true, message: 'Please enter the total_customer_price' },
                                            { min: 2, message: 'At least 2 characters' }
                                        ]}
                                        initialValue={breakdownData?.totalCustomerPrice || orderDetails?.total_customer_price || 0}
                                    >
                                        <Input value={breakdownData?.totalCustomerPrice} disabled
                                            onChange={(event) => updateFormFields('total_customer_price', event?.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='downpayment'
                                        label='downpayment'
                                        rules={[
                                            { required: true, message: 'Please enter the downpayment', }
                                        ]}
                                        initialValue={orderDetails?.downpayment}
                                    >
                                        <Input
                                            onChange={(event) => {
                                                updateFormFields('downpayment', parseInt(event?.target.value) || undefined);
                                                breakDownHandler();
                                            }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='instalment_plan_id'
                                        label='instalment plan'
                                        rules={[
                                            { required: true, message: 'Please enter the internal_id' }
                                        ]}
                                        initialValue={orderDetails?.instalment_plan_id}
                                    >
                                        <Select
                                            showSearch
                                            placeholder='Search to Select'
                                            optionFilterProp='children'
                                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={MapToSelectOption(instalmentPlan, 'name', 'id')}
                                            onChange={(selectedPlan: string) => {
                                                const currentPlan = instalmentPlan.find((instalmentPlanCurrent) => instalmentPlanCurrent.id === selectedPlan);
                                                updateFormFields('instalment_plan_id', selectedPlan);
                                                setInstalmentMonths((currentPlan?.days || 1) / (currentPlan?.cycle || 1));
                                                breakDownHandler();
                                            }}
                                        />
                                    </Form.Item>

                                    {/* <Form.Item
                                        name='instalment_plan_id'
                                        label='instalment plan'
                                        initialValue={orderDetails?.instalment_plan_id || ''}
                                        rules={[
                                            { required: true, message: 'Please select the instalment plan.' },
                                        ]}
                                    >
                                        {
                                            SearchableDropdown<IBrandEntity>(
                                                {
                                                    key: 'id',
                                                    defaultValue: orderDetails?.instalment_plan_id || '',
                                                    sortBy: { 'name': 'ASC' },
                                                    value: 'name',
                                                    queryKey: ['getInstalmentPlans'],
                                                    queryFnParams: {
                                                        apiRoute: 'instalment_plan',
                                                        relations: [''],
                                                        fields: ['id', 'name', 'cycle', 'days'],
                                                        filter: {
                                                            field: 'name',
                                                            operator: FilterOperatorsEnum.LIKE,
                                                            value: ''
                                                        },
                                                        predefinedFilters: [
                                                            // {
                                                            //     field: 'brand_charges.charges',
                                                            //     operator: FilterOperatorsEnum.NOT_NULL,
                                                            //     value: 'garbage'
                                                            // }
                                                        ]
                                                    },
                                                    onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                                        const currentPlan = instalmentPlan.find((instalmentPlanCurrent) => instalmentPlanCurrent.id === selectedItemIds);
                                                        updateFormFields('instalment_plan_id', selectedItemIds);
                                                        setInstalmentMonths((currentPlan?.days || 1) / (currentPlan?.cycle || 1));
                                                        breakDownHandler();
                                                    }

                                                    // onChange={(selectedPlan: string) => {
                                                    //     const currentPlan = instalmentPlan.find((instalmentPlanCurrent) => instalmentPlanCurrent.id === selectedPlan);
                                                    //     updateFormFields('instalment_plan_id', selectedPlan);
                                                    //     setInstalmentMonths((currentPlan?.days || 1) / (currentPlan?.cycle || 1));
                                                    //     breakDownHandler();
                                                    // }}
                                                }
                                            )
                                        }
                                    </Form.Item> */}
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='monthly_plan_value'
                                        label='Monthly'
                                        initialValue={orderDetails?.monthly_plan_value}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="order_date"
                                        label="Order Date"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter the order date',
                                            },
                                        ]}
                                        initialValue={
                                            orderDetails?.order_date
                                                ? dayjs(orderDetails.order_date)
                                                : undefined
                                        }
                                    >
                                        <ConfigProvider locale={locale}>
                                            <DatePicker
                                                value={form.getFieldValue('order_date')}
                                                showTime={{ format: 'HH:mm' }} // Include this line to enable time selection
                                                style={{ width: '100%' }}
                                                onChange={(date) => {
                                                    updateFormFields('order_date', date);
                                                }}
                                            />
                                        </ConfigProvider>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider />
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='contract_status'
                                        label='contract_status'
                                        rules={[
                                            { required: true, message: 'Please enter the contract_status' }
                                        ]}
                                        initialValue={orderDetails?.contract_status || ContractStatusEnum.PENDING}
                                    >
                                        <Select onChange={(value) => updateFormFields('contract_status', value)}>
                                            {
                                                getEnumeratorKeys(ContractStatusEnum).map(
                                                    (key) => <Select.Option key={key}
                                                        value={ContractStatusEnum[key as keyof typeof ContractStatusEnum]}>
                                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                                    </Select.Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_city'
                                        label='delivery_city'
                                        rules={[
                                            { required: true, message: 'Please enter the downpayment', }
                                        ]}
                                        initialValue={orderDetails?.delivery_city}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {cities.map((o, i) => (
                                                <Select.Option key={`option-${i}`} value={o}>
                                                    {o}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='channel_id'
                                        label='channel_id '
                                        rules={[
                                            { required: true, message: 'Please enter the internal_id' }
                                        ]}
                                        initialValue={orderDetails?.channel_id}
                                    >
                                        <Select
                                            showSearch
                                            placeholder='Search to Select'
                                            optionFilterProp='children'
                                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={MapToSelectOption(channel, 'name', 'id')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='box_and_cheque_returned'
                                        label='BoxCheq Returned'
                                        rules={[
                                            { required: true, message: 'Please enter the BoxCheq Returned' }
                                        ]}
                                        initialValue={orderDetails.box_and_cheque_returned || 'false'}
                                    >
                                        <Select>
                                            {options.map((o, i) => {
                                                return (
                                                    <Select.Option key={`option-${i}`} value={o.value}>
                                                        {o.label}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='segment'
                                        label='segment'
                                        rules={[
                                            { required: true, message: 'Please enter the segment' }
                                        ]}
                                        initialValue={orderDetails?.segment || SegmentEnumerator.B2C}
                                    >
                                        <Select onChange={(value) => updateFormFields('segment', value)}>
                                            {
                                                getEnumeratorKeys(SegmentEnumerator).map(
                                                    (key) => <Select.Option key={key}
                                                        value={SegmentEnumerator[key as keyof typeof SegmentEnumerator]}>
                                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                                    </Select.Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='cheque_provider'
                                        label='cheque_provider'
                                        rules={[
                                            { required: true, message: 'Please enter the cheque_provider' }
                                        ]}
                                        initialValue={orderDetails?.cheque_provider || ChequeProviderEnum.CUSTOMER}
                                    >
                                        <Select onChange={(value) => updateFormFields('customer_status', value)}>
                                            {
                                                getEnumeratorKeys(ChequeProviderEnum).map(
                                                    (key) => <Select.Option key={key}
                                                        value={ChequeProviderEnum[key as keyof typeof ChequeProviderEnum]}>
                                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                                    </Select.Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='cheque_number'
                                        label='Cheque number'
                                        rules={[
                                            { required: false, message: 'Please enter the cheque number' }
                                        ]}
                                        initialValue={orderDetails?.cheque_number}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('cheque_number', event?.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};