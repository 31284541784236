import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmail } from 'class-validator';
import { LockOutlined, UserOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'; // Import Eye icons

import { IPermissionEntity, ISignInResponse } from '@rasayi-workspace/shared';
import { GetRawItems, PostSignIn, SaveSignInDetails, setPermissionsInApplicationMemory } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { IUserContext, UserContext } from '@contexts';
import { Button, Card, Checkbox, Form, Input, message, Spin } from 'antd';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@interfaces';

export const SignInPageComponent = (): JSX.Element => {
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState<{ email: string; password: string; }>({ email: '', password: '' });

    const { updateUserId, updateUser } = useContext<IUserContext>(UserContext);

    const { refetch: getMyPermissions, isFetching: isPermissionsFetching } = useQuery<string[], AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['userPermissions'],
        queryFn: () => GetRawItems<IPermissionEntity, string>('permission/me'),
        onSuccess: (permissions: string[]): void => {
            setPermissionsInApplicationMemory(permissions);
            updateUser();
        },
        onError: (error) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: SignIn, isLoading: signInLoading } = useMutation<ISignInResponse, AxiosError>(
        ['SignIn'],
        () => PostSignIn(data.email, data.password),
        {
            onSuccess: (response: ISignInResponse) => {
                messageApi.open({
                    type: 'success',
                    content: 'Successfully signed in!',
                });
                SaveSignInDetails(response);
                updateUserId(response?.user?.id || '');
                getMyPermissions();
            },
            onError: (error: AxiosError) => {
                messageApi.open({
                    type: 'error',
                    content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
                });
            }
        }
    );

    const isValidForm = !!(data.email && isEmail(data.email) && data.password);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        if (isValidForm)
            SignIn();
    };

    const handleEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setData(previousState => ({ ...previousState, email: value }))
    };

    const handlePasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setData(previousState => ({ ...previousState, password: value }))
    };

    return (
        <Spin spinning={signInLoading || isPermissionsFetching}>
            {contextHolder}
            <div className='flex justify-center items-center h-screen'>
                <Card
                    className='w-96'
                    title={
                        <div className='flex justify-between mt-2'>
                            <img src='../../../../assets/rasayi-logo.png' style={{ width: 80 }} alt='logo' />
                            <div className='mt-2'>Rasayi Portal</div>
                        </div>
                    }>

                    <div>
                        <Form
                            name='login-form'
                            onFinish={handleSubmit}
                        >
                            <Form.Item
                                name='email'
                                rules={[
                                    { required: true, message: 'Please enter the email' },
                                    { type: 'email', message: 'Please enter a valid email' }
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder='Email'
                                    value={data.email}
                                    onChange={handleEmailChange}
                                />
                            </Form.Item>
                            <Form.Item
                                className='mb-0'
                                name='password'
                                rules={[{ required: true, message: 'Please input your password' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined />}
                                    type='password'
                                    value={data.password}
                                    placeholder='Password'
                                    onChange={handlePasswordChange}
                                    iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Form.Item
                                className='my-2'>
                                <a
                                    className='float-right'
                                    href='/'
                                >
                                    Forgot password
                                </a>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    className='w-full'
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Card>
            </div>
        </Spin>
    );
};
