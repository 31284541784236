import React, { useEffect, useState } from 'react';
import { Button, Image, message, Modal } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DocumentTypeSearch, IDocumentEntity, IItemsWithCount, KeyValueRecord } from '@rasayi-workspace/shared';
import { DeleteItem, GetItems, } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import { CloudDownloadOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { DeleteConfirmationModal } from '@components';

export const ViewDocumentModalComponent = ({ onClose, onSubmit, editMode = false, id: userId = '' }: AddEditModalProps<IDocumentEntity>) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [userDocument, setUserDocument] = useState<any>([]);
    const [targetImage, setTargetImage] = useState<Partial<IDocumentEntity>>({});

    const { refetch: userDocumentsOne, isFetching: isDocumentsFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUserDocument'],
        queryFn: () => {
            const queryParams = new URLSearchParams({
                userId: userId
            });

            return GetItems<KeyValueRecord>('document/user?' + queryParams.toString());
        },
        onSuccess: (returnedResult: IItemsWithCount<KeyValueRecord>): void => setUserDocument(returnedResult),
        onError: () => setUserDocument([]),
    });

    const { mutate: deleteImage } = useMutation<IDocumentEntity, AxiosError>({
        mutationKey: ['deleteImage'],
        mutationFn: async () =>
            DeleteItem<IDocumentEntity>(
                'document',
                targetImage?.id || '',
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Document deleted!`,
            });

            !isDocumentsFetching && await userDocumentsOne();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleDownloadImage = async (documentDetails: KeyValueRecord) => {
        try {
            const response = await fetch(documentDetails.fileContent);

            const blob = await response.blob();

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');

            link.href = url;

            link.download = documentDetails.name; // Specify the file name

            document.body.appendChild(link);

            link.click();

        } catch (err) {
            const downloadLink = document.createElement('a');

            downloadLink.href = documentDetails.fileContent;

            downloadLink.target = '_blank';

            downloadLink.rel = 'noopener noreferrer';

            downloadLink.click();
        }
    };

    useEffect(() => {
        !isDocumentsFetching && userDocumentsOne();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {contextHolder}
            <Modal
                title={'View Documents'}
                open={true}
                confirmLoading={isDocumentsFetching}
                onOk={() => onClose()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <h2>
                    Documents
                </h2>
                {
                    userDocument &&
                    userDocument?.map((image: KeyValueRecord, index: number) => {
                        return <div className='flex-container'
                            style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                            {!image?.isPdf ?
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.url?.includes(el))?.toUpperCase()}
                                    </h4>
                                    <img
                                        key={index}
                                        width={400}
                                        src={image?.fileContent}
                                    />
                                </div>
                                :
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.url?.includes(el))?.toUpperCase()}
                                    </h4>
                                    <div className='w-[400px] flex justify-center'><FileOutlined /></div>
                                </div>}
                            <div>
                                <Button
                                    className=' '
                                    type='primary'
                                    icon={<CloudDownloadOutlined />}
                                    style={{ marginLeft: 10, marginBottom: 5 }}
                                    onClick={() => handleDownloadImage(image)}
                                />
                                {/* <Button
                                        className=' '
                                        type='primary'
                                        icon={ <DeleteOutlined/> }
                                        style={ { marginLeft: 10, marginTop: 5 } }
                                        onClick={
                                            () => {
                                                setTargetImage(image);

                                                DeleteConfirmationModal({
                                                    content: `Are you sure you want to delete this picture?`,
                                                    onOk: () => {
                                                        deleteImage();
                                                    },
                                                    onCancel: () => {
                                                        setTargetImage({});
                                                    }
                                                });
                                            }
                                        }
                                    /> */}
                            </div>
                        </div>;
                    })
                }
            </Modal>
        </>
    );
};