import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { HasAnyPermission, hasAllPermissions, IsAuthenticated } from '@services';
import { PermissionsEnum } from '@rasayi-workspace/shared';

export const PageGuard = ({ permissions = [], anyPermissions = [], children }:
    {
        permissions?: PermissionsEnum[];
        anyPermissions?: PermissionsEnum[];
        children: ReactNode | JSX.Element;
    }
): JSX.Element => (HasAnyPermission(anyPermissions) && hasAllPermissions(permissions)) ?
        <div>{children}</div>
        : <Navigate to={`/dashboard`} />;

export const SignedInGuard = ({ children }: { children: ReactNode }): JSX.Element => IsAuthenticated() ?
    <div>{children}</div> : <Navigate to={`/sign-in`} />;

export const SignedOutGuard = ({ children }: { children: ReactNode }): JSX.Element => IsAuthenticated() ?
    <Navigate to={`/dashboard`} /> : <div>{children}</div>;