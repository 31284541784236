import React, { useEffect, useRef } from 'react';

declare global {
  interface Window {
    google: any;
  }
}

export const Map = ({ latitude, longitude }: any) => {
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);

  useEffect(() => {
    if (latitude && longitude && window.google) {
      const mapOptions = {
        center: { lat: latitude, lng: longitude },
        zoom: 14,
      };

      map.current = new window.google.maps.Map(mapContainer.current, mapOptions);

      new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map.current,
        title: 'Your Location',
      });
    }
  }, [latitude, longitude]);

  return <div ref={mapContainer} style={{ height: '400px', width: '100%' }} />;
};
