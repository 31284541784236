import { useEffect, useState } from 'react';
import { DatePicker, Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getEnumeratorKeys, IInstalmentDTO, IInstalmentEntity, IItemsWithCount, InstalmentStatusEnum, IOrderEntity, IPaymentTypeEntity, IUserEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';
import { ConfigProvider } from 'antd/lib';

export const InstalmentModalComponent = ({ onClose, onSubmit, editMode = false, id: instalmentId = '' }: AddEditModalProps<IInstalmentEntity>) => {
    const [form] = Form.useForm<Partial<IInstalmentDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [instalment, setInstalment] = useState<Partial<IInstalmentEntity>>();
    const [users, setUsers] = useState<IUserEntity[]>([]);
    const [orders, setOrders] = useState<IOrderEntity[]>([]);
    const [paymentTypes, setPaymentTypes] = useState<IPaymentTypeEntity[]>([]);
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

    const { mutate: createInstalment, isLoading } = useMutation<IInstalmentEntity, AxiosError>({
        mutationKey: ['createInstalment'],
        mutationFn: async () =>
            PostItem<IInstalmentEntity, IInstalmentDTO>(
                'instalment',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IInstalmentDTO>
            ),
        onSuccess: async (item: IInstalmentEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateInstalment, isLoading: isInstalmentUpdating } = useMutation<IInstalmentEntity, AxiosError>({
        mutationKey: ['updateInstalment'],
        mutationFn: async () =>
            PutItem<IInstalmentEntity, IInstalmentDTO>(
                'instalment',
                instalmentId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IInstalmentDTO>
            ),
        onSuccess: async (item: IInstalmentEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchInstalment, isFetching: isInstalmentFetching } = useQuery<IInstalmentEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInstalment'],
        queryFn: () => GetItem<IInstalmentEntity>(
            'instalment',
            instalmentId || '',
            [],
            []
        ),
        onSuccess: (result: IInstalmentEntity): void => {
            setInstalment(instalment);
            form.setFieldsValue({
                ...result,
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchUsers, isFetching: isUsersFetching } = useQuery<IItemsWithCount<IUserEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUsers'],
        queryFn: () => GetItems<IUserEntity>(
            'user',
            [],
            ['id', 'first_name', 'cnic_number']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IUserEntity>): void => setUsers(returnedResult.items),
        onError: () => setUsers([])
    });

    const { refetch: fetchOrders } = useQuery<IItemsWithCount<IOrderEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getOrders'],
        queryFn: () => GetItems<IOrderEntity>(
            'order',
            [],
            ['id']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IOrderEntity>): void => setOrders(returnedResult.items),
        onError: () => setOrders([])
    });

    const { refetch: fetchPaymentTypes } = useQuery<IItemsWithCount<IPaymentTypeEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getPaymentTypes'],
        queryFn: () => GetItems<IPaymentTypeEntity>(
            'payment_type',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IPaymentTypeEntity>): void => setPaymentTypes(returnedResult.items),
        onError: () => setPaymentTypes([])
    });

    const updateFormFields = (property: string, value: string | number | any): any => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined, fieldName: string) => {
        setSelectedDate(date);
        form.setFieldsValue({ [fieldName]: dateString });
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createInstalment())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateInstalment())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        if (editMode)
            fetchInstalment();

        fetchOrders();
        fetchUsers();
        fetchPaymentTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Instalment' : 'Edit Instalment'}
                open={true}
                confirmLoading={isLoading || isUsersFetching || isInstalmentFetching || isInstalmentUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Instalment' : 'Edit Instalment'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='user_id'
                        label='User'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(users, 'cnic_number', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='order_id'
                        label='Order id'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(orders, 'id', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='due_datetime'
                        label='Due datetime'
                        rules={[
                            { required: true, message: 'Please enter the Due Datetime' }
                        ]}
                    >
                        <ConfigProvider locale={locale}>
                            <DatePicker
                                value={dayjs(form.getFieldValue('due_datetime'))}
                                onChange={(date, dateString) => handleDateChange(date, dateString, 'due_datetime')}
                                showTime={false}
                                style={{ width: '100%' }}
                            />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        name='expected_datetime'
                        label='Expected datetime'
                        rules={[
                            { required: true, message: 'Please enter the Expected Datetime' }
                        ]}
                    >
                        <ConfigProvider locale={locale}>
                            <DatePicker
                                value={dayjs(form.getFieldValue('expected_datetime'))}
                                onChange={(date, dateString) => handleDateChange(date, dateString, 'expected_datetime')}
                                showTime={false}
                                style={{ width: '100%' }}
                            />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        name='loan_extension_days'
                        label='Loan extension days'
                        rules={[
                            { required: true, message: 'Please enter the loan_extension_days' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('loan_extension_days', parseInt(event?.target.value))} />
                    </Form.Item>
                    <Form.Item
                        name='amount'
                        label='Amount'
                        rules={[
                            { required: true, message: 'Please enter the amount' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('amount', parseInt(event?.target.value))} />
                    </Form.Item>
                    <Form.Item
                        name='fine'
                        label='Fine'
                        rules={[
                            { required: true, message: 'Please enter the fine' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('fine', parseInt(event?.target.value))} />
                    </Form.Item>
                    <Form.Item
                        name='carried_forward'
                        label='Carried forward'
                        rules={[
                            { required: true, message: 'Please enter the carried_forward' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('carried_forward', parseInt(event?.target.value))} />
                    </Form.Item>
                    <Form.Item
                        name='total_amount'
                        label='Total amount'
                        rules={[
                            { required: true, message: 'Please enter the total_amount' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('total_amount', parseInt(event?.target.value))} />
                    </Form.Item>
                    <Form.Item
                        name='paid_amount'
                        label='Paid amount'
                        rules={[
                            { required: true, message: 'Please enter the paid_amount' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('paid_amount', parseInt(event?.target.value))} />
                    </Form.Item>
                    <Form.Item
                        name='paid'
                        label='Paid'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='instalment_status'
                        label='Instalment status'
                        rules={[
                            { required: true, message: 'Please enter the instalment_status' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('instalment_status', value)}>
                            {
                                getEnumeratorKeys(InstalmentStatusEnum).map(
                                    (key) => <Select.Option key={key} value={InstalmentStatusEnum[key as keyof typeof InstalmentStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='paid_datetime'
                        label='Paid datetime'
                        rules={[
                            { required: true, message: 'Please enter the paid_datetime' }
                        ]}
                    >
                        <ConfigProvider locale={locale}>
                            <DatePicker
                                value={dayjs(form.getFieldValue('paid_datetime'))}
                                onChange={(date, dateString) => handleDateChange(date, dateString, 'paid_datetime')}
                                showTime={false}
                                style={{ width: '100%' }}
                            />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        name='payment_type_id'
                        label='Payment type'
                        rules={[{ required: false }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(paymentTypes, 'name', 'id')}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};