import { createContext, ReactChild, ReactFragment, ReactPortal, useState } from 'react';
import { IDeliveryEntity, IInventoryEntity, IOrderEntity, KeyValueRecord } from '@rasayi-workspace/shared';

const order = {
    progress: { value: 'string', title: 'string' },
    setProgress: (value: string, title: string) => {
        return;
    },
    userId: '',
    setUserId: (newValue: string) => {
        return;
    },
    orderId: '',
    setOrderId: (newValue: string) => {
        return;
    },
    deliveryId: '',
    setDeliveryId: (newValue: string) => {
        return;
    },
    userIdValidated: false,
    setUserIdValidated: (newValue: boolean) => {
        return;
    },
    guarantorId: '',
    setGuarantorId: (newValue: string) => {
        return;
    },
    inventories: [] as IInventoryEntity[],
    setInventories: (newValue: IInventoryEntity[]) => {
        return;
    },
    deliveryDetails: {} as KeyValueRecord,
    setDeliveryDetails: (newValue: KeyValueRecord) => {
        return;
    },
    incompleteOrder: true,
    setIncompleteOrder: (newValue: boolean) => {
        return;
    },
    initialInventories: [] as IInventoryEntity[],
    setInitialInventories: (newValue: IInventoryEntity[]) => {
        return;
    }
};

export const deliveryContext = createContext(order);

export function DeliveryContextProvider(props: { children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }): JSX.Element {
    const [progress, setProgress] = useState({ value: '1', title: 'Phone Number' });

    const [userId, setUserId] = useState('');

    const [orderId, setOrderId] = useState('');

    const [deliveryId, setDeliveryId] = useState('');

    const [userIdValidated, setUserIdValidated] = useState(false);

    const [guarantorId, setGuarantorId] = useState('');

    const [deliveryDetails, setDeliveryDetails] = useState<KeyValueRecord>({});

    const [inventories, setInventories] = useState<IInventoryEntity[]>([]);

    const [incompleteOrder, setIncompleteOrder] = useState(true);

    const [initialInventories, setInitialInventories] = useState<IInventoryEntity[]>([]);

    const updateProgress = (value: string, title: string): void => {
        setProgress({ value, title });
    };

    const updateUserId = (newValue: string): void => {
        setUserId(newValue);
    };

    const updateOrderId = (newValue: string): void => {
        setOrderId(newValue);
    };

    const updateDeliveryId = (newValue: string): void => {
        setDeliveryId(newValue);
    };

    const updateUserIdValidated = (newValue: boolean): void => {
        setUserIdValidated(newValue);
    };

    const updateGuarantorId = (newValue: string): void => {
        setGuarantorId(newValue);
    };

    const updateInventories = (newValue: IInventoryEntity[]): void => {
        setInventories(newValue);
    };

    const updateDeliveryDetails = (newValue: KeyValueRecord): void => {
        setDeliveryDetails(newValue);
    };

    const updateIncompleteOrder = (newValue: boolean): void => {
        setIncompleteOrder(newValue);
    };

    const updateInitialInventories = (newValue: IInventoryEntity[]): void => {
        setInitialInventories(newValue);
    };

    /*
      updateTheme is meant to be an example of a method that can do more than just updating the theme state.
      For example, it could be a method that triggers a fetch request, in which updating the state is not a direct step.
    */

    return (

        <deliveryContext.Provider value={ {
            progress, setProgress: updateProgress,
            userId, setUserId: updateUserId,
            orderId, setOrderId: updateOrderId,
            deliveryId, setDeliveryId: updateDeliveryId,
            userIdValidated, setUserIdValidated: updateUserIdValidated,
            guarantorId, setGuarantorId: updateGuarantorId,
            inventories, setInventories: updateInventories,
            incompleteOrder, setIncompleteOrder: updateIncompleteOrder,
            deliveryDetails, setDeliveryDetails: updateDeliveryDetails,
            initialInventories, setInitialInventories: updateInitialInventories
        } }>
            { props.children }
        </deliveryContext.Provider>
    );
}