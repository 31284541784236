import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Card, Col, Form, Input, Layout, message, Row, Steps } from 'antd';
import { FilterOperatorsEnum, IInventoryEntity, IOrderDTO, IOrderEntity, IUpdateOrderDTO, IUserEntity, removeWhiteSpaceFromAllProperties, UserRolesEnum } from '@rasayi-workspace/shared';
import { PutItem } from '@services';
import { BaseLayoutComponent, SearchableDropdown } from '@components';
import { ProductInformationPageComponent } from '../product-information';
import { orderContext } from '@contexts';
import { OrderCompletePageComponent } from '../order-complete';
import { ErrorResponse } from '@interfaces';

const { Content } = Layout;

export const GuarantorInformationPageComponent = () => {
    const [form] = Form.useForm<Partial<any>>();
    const {
        orderId, inventories, userId, orderDetails, setOrderDetails,
        incompleteOrder, setInitialInventories, setInventories, setGuarantorId, setUserId, setUser, setLastAccess
    } = useContext(orderContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedUser, setSelectedUser] = useState<any>();
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const completeOrder = {
        ...orderDetails,
        user_id: userId,
        ...form.getFieldsValue(),
        inventory_info: { internal_ids: inventories.map((inventory: IInventoryEntity) => inventory.internal_id), retail_prices: inventories.map((inventory: IInventoryEntity) => inventory.retail_price) }
    };

    const { mutate: updateOrder, isLoading: isOrderUpdating } = useMutation<IOrderEntity, AxiosError>({
        mutationKey: ['updateOrder'],
        mutationFn: async () =>
            PutItem<IOrderEntity, IUpdateOrderDTO>(
                'order',
                orderId,
                removeWhiteSpaceFromAllProperties(
                    {
                        ...completeOrder, ...form.getFieldsValue()
                    }
                ) as Partial<IOrderDTO>
            ),
        onSuccess: async (item: IOrderEntity): Promise<void> => {
            onOrderModalCloseHandler(item);
            setNextPage(true);
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const onOrderModalCloseHandler = (newOrder: IOrderEntity) => {
        setInitialInventories([]);
        setOrderDetails({});
        setInventories([]);
        setGuarantorId('');
        setUserId('');
        setUser({});
        setLastAccess('');
        messageApi.open(
            {
                type: 'success',
                content: `Order '${newOrder?.internal_id}' Updated!`,
            }
        );
    };

    const updateOrderHandler = async () => {
        !isOrderUpdating && updateOrder();
    };

    const previousPageHandler = () => {
        setPreviousPage(true);
    };
    //
    useEffect(() => {
        setSelectedUser(orderDetails?.guarantor || {});
    }, []);

    return (
        <>
            {contextHolder}
            {
                nextPage &&
                <OrderCompletePageComponent />
            }
            {
                previousPage &&
                <ProductInformationPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={3}
                        items={[
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Delivery information',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Guarantor Information'
                        extra={<>
                            <Button
                                type='primary'
                                onClick={previousPageHandler}
                                style={{ marginLeft: 4 }}
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                style={{ marginLeft: 4 }}
                                onClick={updateOrderHandler}
                                disabled={(!incompleteOrder ? !selectedUser : false) || isOrderUpdating}
                            >
                                Update Order
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            form={form}
                        >
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>

                                    <Form.Item
                                        name='guarantor_id'
                                        label='Guarantor'
                                        initialValue={orderDetails.guarantor_id}
                                    >{
                                        SearchableDropdown<IUserEntity>(
                                            {
                                                key: 'id',
                                                defaultValue: orderDetails?.guarantor?.cnic_number || '',
                                                sortBy: { 'cnic_number': 'ASC' },
                                                value: 'cnic_number',
                                                queryKey: ['GuarantorsUser'],
                                                queryFnParams: {
                                                    apiRoute: 'user',
                                                    relations: [],
                                                    fields: ['id', 'first_name', 'cnic_number', 'last_name', 'primary_mobile', 'address_line1', 'city', 'email'],
                                                    filter: {
                                                        field: 'cnic_number',
                                                        operator: FilterOperatorsEnum.LIKE,
                                                        value: ''
                                                    },
                                                    predefinedFilters: [
                                                        {
                                                            field: 'roles.name',
                                                            operator: FilterOperatorsEnum.EQUAL,
                                                            value: UserRolesEnum.CUSTOMER
                                                        }
                                                    ]
                                                },
                                                onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                                    const selectedRow: any = items.find((user: IUserEntity) => user.id === selectedItemIds);
                                                    if (selectedRow) {
                                                        setSelectedUser(selectedRow);
                                                        form.setFieldsValue({ ...selectedRow });
                                                    }
                                                    setOrderDetails({
                                                        ...orderDetails, guarantor_id: selectedUser?.id
                                                    });
                                                }
                                            }
                                        )
                                    }
                                    </Form.Item>
                                </Col>
                            </Row>
                            {selectedUser ? <>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='first_name'
                                            label='First Name'
                                            rules={[
                                                { required: true, message: 'Please enter the first name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.first_name}
                                        >
                                            <Input disabled value={selectedUser?.first_name} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='last_name'
                                            label='Last Name'
                                            rules={[
                                                { required: true, message: 'Please enter the last name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.last_name}
                                        >
                                            <Input disabled value={selectedUser?.last_name}
                                                onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='cnic_number'
                                            label='Cnic Number'
                                            rules={[
                                                { required: true, message: 'Please enter the Cnic Number' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.cnic_number}
                                        >
                                            <Input disabled value={selectedUser?.cnic_number}
                                                onChange={(event) => updateFormFields('cnic_number', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='primary_mobile'
                                            label='Primary Mobile'
                                            rules={[
                                                { required: true, message: 'Please enter the Primary Mobile' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.primary_mobile}
                                        >
                                            <Input disabled value={selectedUser?.primary_mobile}
                                                onChange={(event) => updateFormFields('primary_mobile', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='city'
                                            label='City'
                                            rules={[
                                                { required: true, message: 'Please enter the city' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.city}
                                        >
                                            <Input disabled value={selectedUser?.city}
                                                onChange={(event) => updateFormFields('city', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='address_line1'
                                            label='Address 1'
                                            rules={[
                                                { required: true, message: 'Please enter the address_line1' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedUser?.address_line1}
                                        >
                                            <Input disabled value={selectedUser?.address_line1}
                                                onChange={(event) => updateFormFields('address_line1', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> : ''}
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};