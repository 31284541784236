import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps } from 'antd';
import { EditOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { FilterOperatorsEnum, IActivityLogs, IItemsWithCount, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps } from '@components';
import { ITableState } from '@interfaces';
import ViewActivityLogs from './view';

const { Content } = Layout;

const ActivityLogs = (): any => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [activityDetailsData, setActivityDetailsData] = useState<IItemsWithCount<IActivityLogs>>(EMPTY_INITIAL_ITEMS);
    const [isActivityLogModalOpened, setIsActivityLogModalOpened] = useState(false);
    const [viewActivityLogId, setViewActivityLog] = useState('');
    const [selectedActivityLog, setSelectedActivityLog] = useState<IActivityLogs | null>(null);

    const { refetch, isFetching } = useQuery<IItemsWithCount<IActivityLogs>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['activity-logs'],
        queryFn: () => GetTableItems<IActivityLogs>(
            'activity_logs',
            ['user'],
            [],
            tableState,
            [
                [
                    {

                        field: 'api_method',
                        operator: FilterOperatorsEnum.NOT_EQUAL,
                        value: 'get',

                    },
                    {

                        field: 'user_id',
                        operator: FilterOperatorsEnum.NOT_NULL,
                        value: '',

                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IActivityLogs>): void => setActivityDetailsData(returnedResult),
        onError: () => setActivityDetailsData(EMPTY_INITIAL_ITEMS)
    });

    const handleChange: TableProps<IActivityLogs>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IActivityLogs>).columnKey as keyof IActivityLogs]: MapAntDesignSortOrder((sorter as SorterResult<IActivityLogs>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };


    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsActivityLogModalOpened(false);
        setViewActivityLog('');
        setSelectedActivityLog(null);
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item) => !item.dataKey) || []
        });
    };

    const columns: ColumnsType<IActivityLogs> = [
        {
            title: 'POC',
            dataIndex: ['user', 'first_name'],
            key: 'user.first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('user.first_name', 'by POC', resetSearchHandler)
        },
        {
            title: 'api_path',
            dataIndex: 'api_path',
            key: 'api_path',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('api_path', 'by api_path', resetSearchHandler)
        },
        {
            title: 'api_method',
            dataIndex: 'api_method',
            key: 'api_method',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IActivityLogs) => {
                switch (item?.api_method?.toLowerCase()) {
                    case 'put':
                        return 'Update';
                    case 'post':
                        return 'Add';
                    case 'delete':
                        return 'Delete';
                    default:
                        return item?.api_method;
                }
            },
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('api_method', 'by api_method', resetSearchHandler)
        },
        {
            title: 'api_status',
            dataIndex: 'api_status',
            key: 'api_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('api_status', 'by api_status', resetSearchHandler)
        },
        {
            title: 'api_param',
            dataIndex: 'api_param',
            key: 'api_param',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('api_param', 'by api_param', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, activityLog) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        onClick={
                            () => {
                                setViewActivityLog(activityLog?.id);
                                setSelectedActivityLog(activityLog);
                                setIsActivityLogModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isActivityLogModalOpened &&
                <ViewActivityLogs
                    editMode={!!viewActivityLogId}
                    id={viewActivityLogId}
                    onClose={onModalCloseHandler}
                    activityLog={selectedActivityLog}

                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={'Activity Logs'}
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={activityDetailsData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: activityDetailsData.count,
                                        pageSize: activityDetailsData.pageSize,
                                        current: activityDetailsData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true }}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default ActivityLogs;