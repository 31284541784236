import { ReactNode, useState } from 'react';
import { Divider, Layout, Button, theme } from 'antd';
import { SidebarMenuItemComponent, HeaderComponent, MobileNavBar } from '@components';
import { Header } from 'antd/es/layout/layout';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { isMobile } from '@helpers';
import { siderStyle } from '@constants';

const { Sider } = Layout;

export const BaseLayoutComponent = ({ children }: { children: ReactNode }): JSX.Element => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const ismobile = isMobile();

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {
                ismobile ?
                    <Layout className='min-h-screen'>
                        <HeaderComponent />
                        <Divider className='m-0 bg-slate-200' />
                        <Layout>
                            <MobileNavBar />
                            <Layout className=' bg-slate-100 overflow-auto pb-[200px] h-[500px]'>
                                {children}
                            </Layout>
                        </Layout>
                    </Layout>
                    :
                    <Layout className='min-h-screen'>
                        <HeaderComponent />
                        <Divider className='m-0 bg-slate-200' />
                        <Layout>
                            <Sider
                                width={220}
                                collapsible
                                collapsed={collapsed}
                                trigger={null}
                                style={{ backgroundColor: 'white' }}
                            >
                                <Header style={{ paddingLeft: 8, background: colorBgContainer }}>
                                    <Button
                                        type="text"
                                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                        onClick={() => setCollapsed(!collapsed)}
                                        style={{
                                            fontSize: '16px',
                                            width: 64,
                                            height: 64,
                                        }}
                                    />
                                </Header>
                                <SidebarMenuItemComponent />
                            </Sider>
                            <Divider className='min-h-screen m-0 bg-slate-200' type='vertical' />
                            <Layout style={siderStyle} className=''>
                                {children}
                            </Layout>
                        </Layout>
                    </Layout>
            }
        </>
    );
};
