import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { Application } from './app/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <div className='p-0 m-0 max-h-screen overflow-hidden'>
        <Application />
      </div>
    </QueryClientProvider>
  </StrictMode>
);
