import { HttpStatusCode } from 'axios';

export const ENGLISH: any = {
    errors: {
        [`${HttpStatusCode.Forbidden}`]: (customError?: string) => customError || 'Apologies! You dont have permissions to do this operation!',
        [`${HttpStatusCode.BadRequest}`]: (customError?: string) => customError || 'Bad parameters provided. Try again or contact your system administrator.',
        [`${HttpStatusCode.InternalServerError}`]: (customError?: string) => customError || 'Apologies :( The server encountered an error. Try again or contact your system administrator.',
        [`${HttpStatusCode.Unauthorized}`]: (customError?: string) => customError || 'Apologies, You are unauthorized.',
        [`${HttpStatusCode.Conflict}`]: (customError?: string) => customError || 'Apologies, a conflict has occured.',
        [`${HttpStatusCode.NotFound}`]: (customError?: string) => customError || 'Cannot find this in system.',
        [`${HttpStatusCode.NotAcceptable}`]: (customError?: string) => customError || 'Not acceptable request. This may have some other dependencies!',
    }
}