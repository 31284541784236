import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState, useEffect, useContext } from 'react';
import { BaseLayoutComponent } from 'src/app/components/shared';
import { Map } from './map';
import { CustomerInformation } from '../1-customer-information';
import { CustomerRadioFieldComponent } from '../3-customer-radio-field';
import { deliveryContext } from '@contexts';
import { useMutation } from '@tanstack/react-query';
import { IDeliveryEntity, IDeliveryPartialDTO, KeyValueRecord } from '@rasayi-workspace/shared';
import { AxiosError } from 'axios';
import { PostItem, PutItem } from '@services';


export const CustomerLocation = () => {
    const [form] = Form.useForm<Partial<any>>();
    const { setDeliveryDetails, deliveryDetails } = useContext(deliveryContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [location, setLocation] = useState<any>({ latitude: null, longitude: null });
    const [isFetchingLocation, setIsFetchingLocation] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [pageDirection, setPageDirection] = useState('');
    const [googleMapsUrl, setGoogleMapsUrl] = useState('');
    const [makeUpdateCall, setMakeUpdateCall] = useState(false);

    const formToFormDataImage = (valuesObject: KeyValueRecord): FormData => {
        const formData = new FormData();
        formData.append('delivery_pin', googleMapsUrl);
        return formData;
    };

    const { mutate: orderUpdate, isLoading: isOrderUpdating } = useMutation<IDeliveryPartialDTO, AxiosError>({
        mutationKey: ['addDocument'],
        mutationFn: async () =>
            PutItem<IDeliveryPartialDTO, FormData>(
                'delivery/partial',
                `${ deliveryDetails?.delivery?.id }`,
                formToFormDataImage(form.getFieldsValue()), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            ),
        onSuccess: async (item: IDeliveryPartialDTO): Promise<void> => {
            setDeliveryDetails({ ...deliveryDetails, ...item });
            if (pageDirection && pageDirection === 'next')
                setNextPage(true);
            else if (pageDirection && pageDirection === 'previous')
                setPreviousPage(true);
        },
        onError: (error: KeyValueRecord) => {
            messageApi.open({
                type: 'error',
                content: 'Contact support for details'
            });
        }
    });

    const fetchLocation = () => {
        setIsFetchingLocation(true);
        setMakeUpdateCall(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
                setIsFetchingLocation(false);
            },
            (error) => {
                console.error('Error getting location:', error);
                setIsFetchingLocation(false);
            }
        );
    };

    useEffect(() => {
        if (deliveryDetails?.delivery?.delivery_pin && !googleMapsUrl) {
            const deliverySplits = deliveryDetails?.delivery?.delivery_pin?.split('=');
            const coordinates = deliverySplits[deliverySplits?.length - 1].split(',');
            setLocation({ latitude: Number(coordinates[0]), longitude: Number(coordinates[1]) });
            setGoogleMapsUrl(deliveryDetails?.delivery?.delivery_pin);
        } else if (deliveryDetails?.location && !googleMapsUrl) {
            setLocation(deliveryDetails?.location);
            setGoogleMapsUrl(deliveryDetails?.delivery?.delivery_pin);
        } else if (!googleMapsUrl) {
            if ((!location?.latitude || !location?.longitude))
                !isFetchingLocation && fetchLocation();
            else {
                const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${ location?.latitude },${ location?.longitude }`;
                setGoogleMapsUrl(mapsUrl);
                setMakeUpdateCall(true);
            }
        }
    }, [location]);

    const previousPageHandler = () => {
        setDeliveryDetails({ ...deliveryDetails, delivery: { ...deliveryDetails.delivery, delivery_pin: googleMapsUrl || null }, location });
        setPageDirection('previous');
        !isOrderUpdating && makeUpdateCall && orderUpdate();
        !makeUpdateCall && setPreviousPage(true);
    };

    const nextPageHandler = () => {
        setDeliveryDetails({ ...deliveryDetails, delivery: { ...deliveryDetails.delivery, delivery_pin: googleMapsUrl || null }, location });
        setPageDirection('next');
        !isOrderUpdating && makeUpdateCall && orderUpdate();
        !makeUpdateCall && setNextPage(true);
    };

    return (
        <>
            {
                nextPage &&
                <CustomerRadioFieldComponent/>
            }
            {
                previousPage &&
                <CustomerInformation/>
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <>
                                <p>Customer Location</p>
                                <p>
                                    <Button
                                        type='primary'
                                        onClick={ previousPageHandler }
                                        style={ { marginLeft: 4 } }
                                    >
                                        Previous Page
                                    </Button>
                                    <Button
                                        type='primary'
                                        style={ { marginLeft: 4 } }
                                        onClick={ nextPageHandler }
                                        // disabled={!(isValidForm || incompleteOrder)}
                                    >
                                        Next
                                    </Button>
                                </p>
                            </>
                        }
                        style={ { height: 600, overflow: 'scroll' } }
                    >
                        <div>
                            <h2>Your Location:</h2>
                            { location.latitude && location.longitude ? (
                                <>
                                    <p>
                                        Latitude: { location.latitude }, Longitude: { location.longitude }
                                    </p>
                                    <a
                                        href={ googleMapsUrl }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Open in Google Maps<br/>
                                        { googleMapsUrl }
                                    </a>
                                    <Map latitude={ location.latitude } longitude={ location.longitude }/>
                                </>
                            ) : isFetchingLocation ? (
                                <p>Loading location...</p>
                            ) : (
                                <p>Click on Fetch Location</p>
                            ) }
                        </div>
                        <Button
                            className='mt-2'
                            key='link'
                            type='primary'
                            style={ { marginLeft: 4 } }
                            onClick={ fetchLocation }
                            disabled={ isFetchingLocation }
                        >
                            Fetch Location
                        </Button>
                    </Card>
                </Content>
            }
            />
        </>
    );
};