import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, Layout, message, Table, TableProps } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IItemsWithCount, IPaymentTypeEntity, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { PaymentTypeModalComponent } from './add-edit';

const { Content } = Layout;

const PaymentTypePageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [paymentTypesData, setPaymentTypesData] = useState<IItemsWithCount<IPaymentTypeEntity>>(EMPTY_INITIAL_ITEMS);
    const [isPaymentTypeModalOpened, setIsPaymentTypeModalOpened] = useState(false);
    const [editingPaymentTypeId, setEditingPaymentTypeId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IPaymentTypeEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['paymentTypes'],
        queryFn: () => GetTableItems<IPaymentTypeEntity>(
            'payment_type',
            [],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IPaymentTypeEntity>): void => setPaymentTypesData(returnedResult),
        onError: () => setPaymentTypesData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deletePaymentType } = useMutation<IPaymentTypeEntity, AxiosError>({
        mutationKey: ['deletePaymentType'],
        mutationFn: async () =>
            DeleteItem<IPaymentTypeEntity>(
                'payment_type',
                editingPaymentTypeId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Payment Type deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IPaymentTypeEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IPaymentTypeEntity>).columnKey as keyof IPaymentTypeEntity]: MapAntDesignSortOrder((sorter as SorterResult<IPaymentTypeEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onPaymentTypeModalCloseHandler = (newPaymentType: IPaymentTypeEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingPaymentTypeId ? {
                type: 'success',
                content: `New payment Type '${ newPaymentType?.name }' created!`,
            } : {
                type: 'success',
                content: `Payment Type '${ newPaymentType?.name }' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsPaymentTypeModalOpened(false);
        setEditingPaymentTypeId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item) => !item.dataKey) || []
        });
    };

    const columns: ColumnsType<IPaymentTypeEntity> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('name', 'by name', resetSearchHandler)
        },
        {
            title: 'index',
            dataIndex: 'index',
            key: 'index',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('index', 'by index', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'operation',
        //     key: 'operation',
        //     render: (_, { id, name = '' }) => (
        //         <div className='lg:flex'>
        //             <Button
        //                 type='text'
        //                 shape='circle'
        //                 icon={ <EditOutlined/> }
        //                 // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
        //                 onClick={
        //                     () => {
        //                         setEditingPaymentTypeId(id);
        //                         setIsPaymentTypeModalOpened(true);
        //                     }
        //                 }
        //             />
        //             <Button
        //                 className='ml-2'
        //                 type='text'
        //                 shape='circle'
        //                 icon={ <DeleteOutlined/> }
        //                 // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
        //                 onClick={
        //                     () => {
        //                         setEditingPaymentTypeId(id);

        //                         DeleteConfirmationModal({
        //                             content: `Are you sure you want to delete payment Type '${ name }'?`,
        //                             onOk: () => {
        //                                 deletePaymentType();
        //                             },
        //                             onCancel: () => resetModal()

        //                         });
        //                     }
        //                 }
        //             />
        //         </div>
        //     ),
        // }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isPaymentTypeModalOpened &&
                <PaymentTypeModalComponent
                    editMode={ !!editingPaymentTypeId }
                    id={ editingPaymentTypeId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onPaymentTypeModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={ 'Payment Type'
                            // <TablePageTitleComponent
                            //     title=
                            //     addButtonText='Add Payment Type'
                            //     // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                            //     onClick={ () => setIsPaymentTypeModalOpened(true) }
                            // />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ paymentTypesData.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: paymentTypesData.count,
                                        pageSize: paymentTypesData.pageSize,
                                        current: paymentTypesData.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default PaymentTypePageComponent;