import { FilterOperatorsEnum, IFilterParameter, IItemsWithCount, ISignInResponse, KeyValueRecord, SortOrder } from '@rasayi-workspace/shared';
import { IAPIQueryParams, ITableState } from '@interfaces';
import { CreateQueryParams } from './parsers';
import { API } from './api';

export const EMPTY_INITIAL_ITEMS: IItemsWithCount = { count: 0, items: [], page: 1, pageSize: 20 };

export const PostSignIn = async (email: string, password: string): Promise<ISignInResponse> => {
    return await API.post('/signin', {
        email,
        password
    }).then(({ data }) => data);
};

export async function GetTableItems<T>(
    apiRoute: string,
    relations: string[] = [],
    fields: string[] = [],
    { page, pageSize, search, sortBy }: ITableState,
    filter: IFilterParameter[][] = []
): Promise<IItemsWithCount<T>> {
    return await API.get(apiRoute, {
        params: CreateQueryParams({
            page,
            pageSize,
            sortBy,
            search: search.map(
                searchFilter => ({
                    criteria: searchFilter.dataKey || '',
                    term: searchFilter.term || '',
                    operator: searchFilter.operator || FilterOperatorsEnum.LIKE
                })
            ),
            relations,
            fields,
            filter
        } as IAPIQueryParams)
    }).then(({ data }) => data);
}

/**
 * Only gets the maximum of 200 records from the backend.
 */
export async function GetItems<T>(
    apiRoute: string,
    relations: string[] = [],
    fields: string[] = [],
    filter: IFilterParameter[][] = [],
    sortBy?: SortOrder
): Promise<IItemsWithCount<T>> {
    return await API.get(apiRoute, {
        params: CreateQueryParams({
            relations,
            fields,
            filter,
            sortBy
        } as IAPIQueryParams)
    }).then(({ data }) => data);
}

export async function GetRawItems<T, R = T>(
    apiRoute: string,
    relations: string[] = [],
    fields: string[] = [],
    filter: IFilterParameter[][] = []
): Promise<R[]> {
    return await API.get(apiRoute, {
        params: CreateQueryParams({
            relations,
            fields,
            filter
        } as IAPIQueryParams)
    }).then(({ data }) => data);
}

export async function GetItem<T>(
    apiRoute: string,
    id: string,
    relations: string[] = [],
    fields: string[] = [],
    filter: IFilterParameter[][] = []
): Promise<T> {
    return await API.get(`${apiRoute}/${id}`, {
        params: CreateQueryParams({
            relations,
            fields,
            filter
        } as IAPIQueryParams)
    }).then(({ data }) => data);
}

export async function PostItem<T, DTO = T>(
    apiRoute: string,
    body: DTO | Partial<DTO> | FormData,
    headers: KeyValueRecord = {}
): Promise<T> {
    return await API.post(apiRoute, body, { headers })
        .then(({ data }) => data);
}

export async function PutItem<T, DTO = T>(
    apiRoute: string,
    id: string,
    body: DTO | Partial<DTO> | FormData | null,
    headers: KeyValueRecord = {}
): Promise<T> {
    return await API.put(`${apiRoute}/${id}`, body, { headers })
        .then(({ data }) => data);
}

export async function DeleteItem<T>(
    apiRoute: string,
    id: string,
    headers: KeyValueRecord = {}
): Promise<T> {
    return await API.delete(`${apiRoute}/${id}`, { headers })
        .then(({ data }) => data);
}

export async function PatchItem<T>(
    apiRoute: string,
    id: string,
    data: any,
    headers: KeyValueRecord = {}
): Promise<T> {
    try {
        const response = await API.patch(`${apiRoute}/${id}`, data, {
            headers: { ...headers, 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        // Handle the error appropriately
        throw error;
    }
}

