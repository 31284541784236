import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IInstalmentPlanDTO, IInstalmentPlanEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const InstalmentPlanModalComponent = ({ onClose, onSubmit, editMode = false, id: instalmentPlanId = '' }: AddEditModalProps<IInstalmentPlanEntity>) => {
    const [form] = Form.useForm<Partial<IInstalmentPlanDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [instalmentPlan, setInstalmentPlan] = useState<Partial<IInstalmentPlanEntity>>();

    const { mutate: createInstalmentPlan, isLoading } = useMutation<IInstalmentPlanEntity, AxiosError>({
        mutationKey: ['createInstalmentPlan'],
        mutationFn: async () =>
            PostItem<IInstalmentPlanEntity, IInstalmentPlanDTO>(
                'instalment_plan',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IInstalmentPlanDTO>
            ),
        onSuccess: async (item: IInstalmentPlanEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateInstalmentPlan, isLoading: isInstalmentPlanUpdating } = useMutation<IInstalmentPlanEntity, AxiosError>({
        mutationKey: ['updateInstalmentPlan'],
        mutationFn: async () =>
            PutItem<IInstalmentPlanEntity, IInstalmentPlanDTO>(
                'instalment_plan',
                instalmentPlanId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IInstalmentPlanDTO>
            ),
        onSuccess: async (item: IInstalmentPlanEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchInstalmentPlan, isFetching: isInstalmentPlanFetching } = useQuery<IInstalmentPlanEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInstalmentPlan'],
        queryFn: () => GetItem<IInstalmentPlanEntity>(
            'instalment_plan',
            instalmentPlanId || '',
        ),
        onSuccess: (result: IInstalmentPlanEntity): void => {
            setInstalmentPlan(instalmentPlan);
            form.setFieldsValue({
                ...result,
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const updateFormFields = (property: string, value: string | number | undefined) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched();
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;
        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createInstalmentPlan())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateInstalmentPlan())
        .catch(() => setIsValidForm(false));

    useEffect(() => {
        if (editMode)
            fetchInstalmentPlan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Instalment Plan' : 'Edit Instalment Plans'}
                open={true}
                confirmLoading={isLoading || isInstalmentPlanFetching || isInstalmentPlanUpdating}
                okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Instalment Plans' : 'Save'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='name'
                        label='Name'
                        rules={[
                            { required: true, message: 'Please enter the name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        label='Description'
                        rules={[
                            { required: false, message: 'Please enter the description' },
                            // { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('description', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='days'
                        label='Days'
                        rules={[
                            { required: true, message: 'Please enter the days' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('days', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                    <Form.Item
                        name='cycle'
                        label='Cycle'
                        rules={[
                            { required: true, message: 'Please enter the cycle' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('cycle', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};