import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, ConfigProvider, DatePicker, Form, Input, Layout, message, Row, Select, Steps, Tag, Upload } from 'antd';
import { getEnumeratorKeys, IAddTransactionForInstalmentDTO, ITransactionEntity, KeyValueRecord, PaymentMethodEnum } from '@rasayi-workspace/shared';
import { BaseLayoutComponent } from '@components';
import { orderContext } from '@contexts';
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';
import { InstalmentDetailsComponent } from '../1-instalment-information';
import { isUndefined } from '@nestjs/common/utils/shared.utils';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';
import { PostItem } from '@services';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TransactionCompletePageComponent } from '../3-complete';
import { isFileSizeValid, MAX_FILE_SIZE_MB } from '@constants';

const { Content } = Layout;

export const PaymentDetailsPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const { orderDetails, setOrderDetails, setInitialInventories, setInventories, setGuarantorId, setUserId, setUser, setLastAccess } = useContext(orderContext);
    const [form] = Form.useForm<Partial<any>>();
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [paidDateTime, setPaidDateTime] = useState(orderDetails?.paid_datetime || dayjs(new Date));
    const [viewForm, setViewForm] = useState(orderDetails?.payment_style !== undefined);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [validUpload, setValidUpload] = useState(false);

    const formToFormData = (valuesObject: KeyValueRecord): FormData => {
        const formData = new FormData();
        
        formData.append('order_id', valuesObject?.id);
        formData.append('instalment_id', valuesObject?.selected_instalment_ids?.[0] || undefined);
        formData.append('amount', valuesObject?.transaction_amount);
        formData.append('transaction_id', valuesObject?.transaction_id);
        formData.append('payment_picture', valuesObject?.document?.file?.originFileObj);
        formData.append('payment_method', valuesObject?.payment_method);
        formData.append('paid_datetime', valuesObject?.paid_datetime?.toISOString());
        formData.append('force_change_instalment_payment_status', (!!valuesObject?.payment_style).toString());

        return formData;
    };

    const { mutate: addTransaction, isLoading: isTransactionLoading } = useMutation<ITransactionEntity, AxiosError>({
        mutationKey: ['payInstalment'],
        mutationFn: async () =>
            PostItem<ITransactionEntity, IAddTransactionForInstalmentDTO>(
                'transaction/instalment',
                formToFormData({ ...orderDetails, ...form.getFieldsValue() }), {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }),
        onSuccess: async (item: ITransactionEntity): Promise<void> => {
            setNextPage(true);
            setInitialInventories([]);
            setOrderDetails({});
            setInventories([]);
            setGuarantorId('');
            setUserId('');
            setUser({});
            setLastAccess('');
        },
        onError: (error: AxiosError) => {
            const errorMessage: KeyValueRecord = error?.response as KeyValueRecord;
            messageApi.open({
                type: 'error',
                content: errorMessage.data?.message?.toString() || 'Error'
            });
        }
    });

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const nextPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue(), 'paid_datetime': paidDateTime });
        addTransaction();
    };

    const previousPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue(), 'paid_datetime': paidDateTime });
        setPreviousPage(true);
    };

    const handleFormChangeMain = () => {
        const fields = form.getFieldsValue();

        if (!fields.transaction_id) {
            fields.transaction_id = null;
            form.setFieldsValue({ transaction_id: null });
        }

        const requiredFields = Object.entries(fields).filter(([key]) => key !== 'transaction_id');
        const emptyFields = requiredFields.some(([key, value]) => isUndefined(value));

        setIsFormValid(!emptyFields);
    };

    const handleUpload: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setIsFileUploading(true);
            handleFormChangeMain();
            return;
        }
        if (info.file.status === 'done') {
            setIsFileUploading(false);
            handleFormChangeMain();
            return;
        }
        if (info.file.status === 'error') {
            setIsFileUploading(false);
            handleFormChangeMain();
            messageApi.open({
                type: 'error',
                content: ('File upload failed.')
            });
        }
    };

    useEffect(() => {
        form.setFieldsValue({ ...orderDetails });
        handleFormChangeMain();
    }, []);

    return (
        <>
            {contextHolder}
            {
                nextPage &&
                <TransactionCompletePageComponent />
            }
            {
                previousPage &&
                <InstalmentDetailsComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={2}
                        items={[
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Instalments information',
                            },
                            {
                                title: 'Transaction Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Transaction Details'
                        extra={<>
                            <Button
                                type='primary'
                                style={{ marginLeft: 4 }}
                                onClick={previousPageHandler}
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                onClick={nextPageHandler}
                                style={{ marginLeft: 4 }}
                                disabled={(!isFormValid && !isFileUploading) || isTransactionLoading || !validUpload}
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'auto' }}
                    >
                        <Form
                            form={form}
                            onChange={handleFormChangeMain}
                        >
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Instalment ID'
                                        rules={[
                                            { required: true, message: 'Please enter the Order ID' }
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            value={orderDetails?.selected_instalment?.[0]?.internal_id || undefined}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='payment_style'
                                        label='Payment Style'
                                        initialValue={undefined}
                                        rules={[
                                            { required: true, message: 'Please Select Payment Style' }
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '100%' }} // Set the width to 100% to make it dynamic
                                            onChange={(value) => {
                                                updateFormFields('payment_style', value);
                                                setViewForm(true);
                                            }}
                                        >
                                            <Select.Option key={0} value={0}>
                                                <Tag key={ 0 }>{ 'Normal payment' }</Tag>
                                            </Select.Option>
                                            <Select.Option key={1} value={1}>
                                                <Tag key={ 1 }>{ 'Partial Payment' }</Tag>
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {viewForm ? <>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='payment_type_name'
                                            label='Payment Type'
                                            initialValue={ orderDetails.selected_instalment?.[0]?.payment_type?.name || undefined }
                                        >
                                            <Input
                                                disabled
                                                value={ orderDetails.selected_instalment?.[0]?.payment_type?.name || undefined }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='transaction_id'
                                            label='Transaction ID'
                                            initialValue={undefined}
                                            rules={[

                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                        >
                                            <Input
                                                value={undefined}
                                                onChange={(event) => {
                                                    updateFormFields('transaction_id', event?.target?.value || undefined);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='amount'
                                            label='Base Amount'
                                            initialValue={ orderDetails.selected_instalment?.[0]?.amount || undefined }
                                        >
                                            <Input
                                                disabled
                                                value={ orderDetails.selected_instalment?.[0]?.amount || undefined }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='payment_method'
                                            label='Payment Method'
                                            initialValue={undefined}
                                            rules={[
                                                { required: true, message: 'Please Select Payment Method' }
                                            ]}
                                        >
                                            <Select
                                                onChange={(value) => {
                                                    form.setFieldsValue({ payment_method: value });
                                                    handleFormChangeMain();
                                                }}
                                            >
                                                {
                                                    getEnumeratorKeys(PaymentMethodEnum).map(
                                                        (key) => <Select.Option key={key}
                                                            value={PaymentMethodEnum[key as keyof typeof PaymentMethodEnum]}>
                                                            <Tag key={key}>{key.toUpperCase()}</Tag>
                                                        </Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='fine'
                                            label='Fine'
                                            initialValue={ orderDetails.selected_instalment?.[0]?.fine || 0 }
                                        >
                                            <Input
                                                disabled value={ orderDetails.selected_instalment?.[0]?.amount || 0 }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='paid_datetime'
                                            label='Paid Date'
                                            rules={[
                                                { required: true, message: 'Please enter the Paid Date' }
                                            ]}
                                            initialValue={paidDateTime}
                                        >
                                            <ConfigProvider locale={locale}>
                                                <DatePicker
                                                    value={paidDateTime}
                                                    showTime={true}
                                                    style={{ width: '100%' }}
                                                    onChange={(date, dateString) => {
                                                        setPaidDateTime(date);
                                                        updateFormFields('paid_datetime', date);
                                                    }}
                                                    changeOnBlur
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='carried_forward'
                                            label='Previous Adjustment'
                                            initialValue={orderDetails.selected_instalment?.[0]?.carried_forward || 0}
                                        >
                                            <Input
                                                disabled
                                                value={orderDetails.selected_instalment?.[0]?.carried_forward || 0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='paid_amount'
                                            label='Paid Amount'
                                            initialValue={orderDetails.selected_instalment?.[0]?.paid_amount || 0}
                                        >
                                            <Input
                                                disabled
                                                value={orderDetails.selected_instalment?.[0]?.paid_amount || 0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='total_amount'
                                            label='Pending Amount'
                                            initialValue={(orderDetails.selected_instalment?.[0]?.total_amount || 0) -
                                                (orderDetails.selected_instalment?.[0]?.paid_amount || 0)}
                                        >
                                            <Input
                                                disabled
                                                value={(orderDetails.selected_instalment?.[0]?.total_amount || 0) -
                                                    (orderDetails.selected_instalment?.[0]?.paid_amount || 0)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='transaction_amount'
                                            label='Transaction Amount'
                                            rules={[
                                                { required: true, message: 'Please enter the total_retail_price' },
                                                { min: 0, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={undefined}
                                        >
                                            <Input value={undefined}
                                                onChange={(event) => {
                                                    updateFormFields('transaction_amount', event?.target?.value ? parseInt(event.target.value) : undefined);
                                                    updateFormFields('amount_difference', (form.getFieldValue('total_amount') || 0) - (event?.target?.value ? parseInt(event.target.value) : 0));
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='amount_difference'
                                            label='Amount Difference'
                                            initialValue={undefined}
                                        >
                                            <Input
                                                disabled
                                                value={undefined}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name='document'
                                            label='Payment Proof'
                                            rules={[
                                                { required: true, message: 'Please select document to Upload.' }
                                            ]}
                                        >
                                            <Upload
                                                maxCount={1}
                                                multiple={false}
                                                beforeUpload={(file: File) => {
                                                    if (!isFileSizeValid(file)) {
                                                        message.error(`File size must be less than ${MAX_FILE_SIZE_MB}MB`);
                                                        return setValidUpload(false);
                                                    }
                                                    return setValidUpload(true);
                                                }}
                                                customRequest={ ({ file, onSuccess }) => {
                                                    if (onSuccess)
                                                        setTimeout(() => {
                                                            onSuccess('ok');
                                                        }, 0);
                                                } } onChange={ handleUpload }>
                                                <Button icon={<UploadOutlined />}>Select File</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> : ''
                            }
                            {/* Add more rows with Col and Form.Item as needed */}
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};