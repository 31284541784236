import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IItemsWithCount, ILeadEntity, IUserEntity, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { LeadModalComponent } from './add-edit';

const { Content } = Layout;

export const LeadPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>({ ...DEFAULT_TABLE_STATE, sortBy: { 'created_at': 'DESC' } });
    const [leadsData, setLeadsData] = useState<IItemsWithCount<ILeadEntity>>(EMPTY_INITIAL_ITEMS);
    const [isLeadModalOpened, setIsLeadModalOpened] = useState(false);
    const [editingLeadId, setEditingLeadId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<ILeadEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['leads'],
        queryFn: () => GetTableItems<ILeadEntity>(
            'lead',
            ['product_type', 'channel', 'brand'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<ILeadEntity>): void => setLeadsData(returnedResult),
        onError: () => setLeadsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteLead } = useMutation<ILeadEntity, AxiosError>({
        mutationKey: ['deleteLead'],
        mutationFn: async () =>
            DeleteItem<ILeadEntity>(
                'lead',
                editingLeadId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Lead deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<ILeadEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<ILeadEntity>).columnKey as keyof ILeadEntity]: MapAntDesignSortOrder((sorter as SorterResult<ILeadEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onLeadModalCloseHandler = (newLead: ILeadEntity | IUserEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingLeadId ? {
                type: 'success',
                content: `New lead '${ newLead?.first_name }' created!`,
            } : {
                type: 'success',
                content: (Object.keys(newLead).includes('roles')) ? 'New User Created!' : `Lead '${ newLead?.first_name }' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsLeadModalOpened(false);
        setEditingLeadId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<ILeadEntity> = [
        {
            title: 'ID',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('internal_id', 'by ID', resetSearchHandler)
        },
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('first_name', 'by first name', resetSearchHandler)
        },
        {
            title: 'Last name',
            dataIndex: 'last_name',
            key: 'last_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('last_name', 'by last name', resetSearchHandler)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('email', 'by email', resetSearchHandler)
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('mobile', 'by mobile', resetSearchHandler)
        },
        {
            title: 'Cnic number',
            dataIndex: 'cnic_number',
            key: 'cnic_number',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('cnic_number', 'by cnic_number', resetSearchHandler)
        },
        {
            title: 'Status',
            dataIndex: 'lead_status',
            key: 'lead_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('lead_status', 'by status', resetSearchHandler)
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('city', 'by city', resetSearchHandler)
        },
        {
            title: 'Organization Name',
            dataIndex: 'organization_name',
            key: 'organization_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('organization_name', 'by organization_name', resetSearchHandler)
        },
        {
            title: 'Channel',
            key: 'channel.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: ILeadEntity) => {
                const channel = item.channel?.name;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            channel
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('channel.name', 'by Channel', resetSearchHandler)
        },
        {
            title: 'Brand',
            key: 'brand.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: ILeadEntity) => {
                const channel = item.brand?.name;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            channel
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('brand.name', 'by Brand', resetSearchHandler)
        },
        {
            title: 'Customer Brand',
            dataIndex: 'customer_brand',
            key: 'customer_brand',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('customer_brand', 'by customer_brand', resetSearchHandler)
        },
        {
            title: 'Segment',
            dataIndex: 'segment',
            key: 'segment',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('segment', 'by segment', resetSearchHandler)
        },
        {
            title: 'Product Type',
            key: 'product_type.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: ILeadEntity) => {
                const product_type = item.product_type?.name;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            product_type
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('product_type.name', 'by Product Type', resetSearchHandler)
        },
        {
            title: 'Customer Product',
            dataIndex: 'customer_product',
            key: 'customer_product',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('customer_product', 'by customer_product', resetSearchHandler)
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('comments', 'by comments', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, first_name = '', last_name = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EditOutlined/> }
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingLeadId(id);
                                setIsLeadModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={ <DeleteOutlined/> }
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={
                            () => {
                                setEditingLeadId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete lead '${ first_name }'?`,
                                    onOk: () => {
                                        deleteLead();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isLeadModalOpened &&
                <LeadModalComponent
                    editMode={ !!editingLeadId }
                    id={ editingLeadId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onLeadModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Leads'
                                addButtonText='Add Leads'
                                // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                                onClick={ () => setIsLeadModalOpened(true) }
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ leadsData.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: leadsData.count,
                                        pageSize: leadsData.pageSize,
                                        current: leadsData.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};