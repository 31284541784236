import { Card, Layout, Typography } from 'antd';
import { BaseLayoutComponent } from '@components';

const { Text } = Typography;

const { Content } = Layout;

export const DeliveryCompletePageComponent = () => {
    return (
        <BaseLayoutComponent children={
            <Content className='m-5 bg-white border rounded-lg'>
                <Card
                    style={{ height: 400, justifyContent: 'center', display: 'flex' }}
                >
                    <Text type='success' underline strong style={{ fontSize: 30, marginTop: 40 }}>
                        Order Delivery has been successfully recorded!</Text>
                </Card>
            </Content>
        }
        />
    );
};