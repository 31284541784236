import { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Layout, Button, Form, Col, Input, Row, Steps, Select, Tag, ConfigProvider, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BaseLayoutComponent } from '@components';
import { orderContext } from '@contexts';
import { ProductInformationPageComponent } from '../product-information';
import { UpdateOrderPageComponent } from '../order-information';
import { isUndefined } from '@nestjs/common/utils/shared.utils';
import { PROVINCES_AND_CITIES } from '@constants';
import { ProvinceEnumerator, getEnumeratorKeys } from '@rasayi-workspace/shared';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

const { Content } = Layout;

export const DeliveryInformationPageComponent = () => {
    const [form] = Form.useForm<Partial<any>>();
    const { setOrderDetails, orderDetails, incompleteOrder } = useContext(orderContext);
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [suggestedDeliveryTime, setSuggestedDeliveryTime] = useState<string>('');

    const updateFormFields = (property: string | number | any, value: string | number | any) => {
        form.setFieldsValue({ [property]: value });
    };

    const previousPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue() });
        setPreviousPage(true);
    };

    const nextPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue() });
        setNextPage(true);
    };

    const handleFormChange = () => {
        const formValues = form.getFieldsValue();
        const requiredFields = ['delivery_address_line_1', 'delivery_city', 'delivery_province', 'delivery_pin'];
        const isValidForm = requiredFields.every(field => !!formValues[field]);
        setIsValidForm(isValidForm);
    };

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
        form.setFieldsValue({ 'suggested_delivery_time': date?.toISOString() });
        if (date)
            setSuggestedDeliveryTime(date.toISOString());
    };

    useEffect(() => {
        handleFormChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cities = useMemo(
        () => Object
            .values(PROVINCES_AND_CITIES)
            .reduce(
                (aggregator, values) => ([...aggregator, ...values]), []
            )
            .sort(),
        []
    );

    return (
        <>
            {
                nextPage &&
                <ProductInformationPageComponent />
            }
            {
                previousPage &&
                <UpdateOrderPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={1}
                        items={[
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Delivery information',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Delivery Information'
                        extra={<>
                            <Button
                                type='primary'
                                onClick={previousPageHandler}
                                style={{ marginLeft: 4 }}
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                style={{ marginLeft: 4 }}
                                onClick={nextPageHandler}
                                disabled={!(isValidForm || incompleteOrder)}
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            form={form}
                            onValuesChange={handleFormChange}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_address_line_1'
                                        label='delivery_address_line_1'
                                        rules={[
                                            { required: !incompleteOrder, message: 'Please enter the delivery_address_line_1', }
                                        ]}
                                        initialValue={orderDetails?.delivery_address_line_1}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_address_line_1', event?.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_address_line_2'
                                        label='delivery_address_line_2'
                                        rules={[
                                            { required: false, message: 'Please enter the delivery_address_line_2', }
                                        ]}
                                        initialValue={orderDetails?.delivery_address_line_2}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_address_line_2', event?.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_city'
                                        label='delivery_city'
                                        rules={[
                                            { required: !incompleteOrder, message: 'Please enter the delivery_city', }
                                        ]}
                                        initialValue={orderDetails?.delivery_city}
                                    >
                                        {/* <Input
                                            onChange={ (event) => updateFormFields('delivery_city', event?.target.value) }/> */}
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {cities.map((o, i) => (
                                                <Select.Option key={`option-${i}`} value={o}>
                                                    {o}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_province'
                                        label='delivery_province'
                                        rules={[
                                            { required: !incompleteOrder, message: 'Please enter the delivery_province', }
                                        ]}
                                        initialValue={orderDetails?.delivery_province}
                                    >
                                        {/* <Input
                                            onChange={(event) => updateFormFields('delivery_province', event?.target.value)} /> */}
                                        <Select onChange={(value) => updateFormFields('delivery_province', value)}>
                                            {
                                                getEnumeratorKeys(ProvinceEnumerator).map(
                                                    (key) => <Select.Option key={key}
                                                        value={ProvinceEnumerator[key as keyof typeof ProvinceEnumerator]}>
                                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                                    </Select.Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_postal_code'
                                        label='delivery_postal_code'
                                        rules={[
                                            { required: true, message: 'Please enter the delivery_postal_code', }
                                        ]}
                                        initialValue={orderDetails?.delivery_postal_code}
                                    >
                                        <Input
                                            maxLength={5}
                                            onChange={(event) => updateFormFields('delivery_postal_code', event?.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='delivery_pin'
                                        label='delivery_pin'
                                        rules={[
                                            { required: !incompleteOrder, message: 'Please enter the delivery_pin', }
                                        ]}
                                        initialValue={orderDetails?.delivery_pin}
                                    >
                                        <Input
                                            onChange={(event) => updateFormFields('delivery_pin', event?.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='suggested_delivery_time'
                                            label='suggested_delivery_time'
                                            rules={[
                                                { required: false, message: 'Please enter the suggested_delivery_time' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={orderDetails?.suggested_delivery_time ? dayjs(orderDetails?.suggested_delivery_time) : undefined}
                                        >
                                            <ConfigProvider locale={locale}>
                                                <DatePicker
                                                    // disabled
                                                    value={suggestedDeliveryTime ? dayjs(suggestedDeliveryTime) : undefined}
                                                    showTime={true}
                                                    style={{ width: '100%' }}
                                                    onChange={(date, dateString) => {
                                                        handleDateChange(date, dateString);
                                                    }}
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='tokenamount'
                                            label='tokenamount'
                                            rules={[
                                                { required: false, message: 'Please enter the tokenamount' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={orderDetails?.tokenamount}
                                        >
                                            <Input value={orderDetails?.tokenamount}
                                                onChange={(event) => {
                                                    updateFormFields('tokenamount', event?.target.value ? parseInt(String(event?.target.value)) : undefined);
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};