import { useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FilterOperatorsEnum, IBrandChargesDTO, IBrandChargesEntity, IBrandEntity, KeyValueRecord, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { PostItem } from '@services';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import { SearchableDropdown } from '@components';
import { isArray } from 'class-validator';

export const BrandModalComponent = ({ onClose, onSubmit, editMode = false, id: brandId = '', extra_field={} }: AddEditModalProps<IBrandChargesEntity>) => {
    const [form] = Form.useForm<Partial<IBrandChargesDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedBrandId, setSelectedBrandId] = useState('')
    const [isValidForm, setIsValidForm] = useState(false);

    const { mutate: createBrand, isLoading } = useMutation<IBrandChargesEntity, AxiosError>({
        mutationKey: ['createUpdateBrandCharges'],
        mutationFn: async () =>
            PostItem<IBrandChargesEntity, IBrandChargesDTO>(
                'brand_charges',
                removeWhiteSpaceFromAllProperties({ ...form.getFieldsValue(), brand_id: selectedBrandId || brandId }) as Partial<IBrandChargesDTO>
            ),
        onSuccess: async (item: IBrandChargesEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createBrand())
        .catch(() => setIsValidForm(false));

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Brand Charges' : 'Edit Brand Charges'}
                open={true}
                confirmLoading={isLoading}
                okText={!editMode ? 'Add Brand Charges' : 'Edit Brand Charges'}
                onOk={onOkAddButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='name'
                        label='Name'
                        initialValue={extra_field?.name}
                        rules={ [
                            { required: true, message: 'Please Select Brands' }
                        ] }
                    >
                        { editMode ? <Input disabled/> :
                            SearchableDropdown<IBrandEntity>(
                                {
                                    key: 'id',
                                    value: 'name',
                                    queryKey: ['getBrands'],
                                    queryFnParams: {
                                        apiRoute: 'brand',
                                        relations: ['brand_charges'],
                                        fields: ['id', 'name'],
                                        filter: {
                                            field: 'name',
                                            operator: FilterOperatorsEnum.LIKE,
                                            value: ''
                                        },
                                        predefinedFilters: []
                                    },
                                    onSelectionChange: (selectedItemIds: string | string[], option: KeyValueRecord, items) => {
                                        if (!isArray(selectedItemIds)) {
                                            setSelectedBrandId(selectedItemIds);
                                            form.setFieldsValue({ 'name': option?.children || '' });
                                        }
                                    }
                                }
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        name='charges'
                        label='Charges'
                        initialValue={extra_field?.misc?.charges}
                        rules={[
                            { required: true, message: 'Please enter the Charges' }
                        ]}
                    >
                        <Input
                            type={ 'number' }
                            className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                            onChange={ (event) => form.setFieldsValue({ 'charges': (event?.target.value ? (parseInt(event?.target.value) || 0) : undefined) }) }
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};