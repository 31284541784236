import { createContext, ReactChild, ReactFragment, ReactPortal, useState } from 'react';

const sideBar = {
    current: '',
    setCurrent: (newValue: string) => {
        return;
    },
    openKey: '',
    setOpenKey: (newValue: string) => {
        return;
    },
};

export const sideBarContext = createContext(sideBar);

export function SideBarContextProvider(props: { children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }): JSX.Element {

    const [current, setCurrent] = useState('');

    const [openKey, setOpenKey] = useState('');

    const updateCurrent = (newValue: string): void => {
        setCurrent(newValue);
    };

    const updateOpenKey = (newValue: string): void => {
        setOpenKey(newValue);
    };

    return (

        <sideBarContext.Provider value={{
            current, setCurrent: updateCurrent,
            openKey, setOpenKey: updateOpenKey,

        }}>
            {props.children}
        </sideBarContext.Provider>
    );
}