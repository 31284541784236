import { useEffect, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IGroupEntity, IItemsWithCount, IRoleEntity, IBrandDTO, IBrandEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const BrandModalComponent = ({ onClose, onSubmit, editMode = false, id: brandId = '' }: AddEditModalProps<IBrandEntity>) => {
    const [form] = Form.useForm<Partial<IBrandDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [brand, setBrand] = useState<Partial<IBrandEntity>>();

    const { mutate: createBrand, isLoading } = useMutation<IBrandEntity, AxiosError>({
        mutationKey: ['createBrand'],
        mutationFn: async () =>
            PostItem<IBrandEntity, IBrandDTO>(
                'brand',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IBrandDTO>
            ),
        onSuccess: async (item: IBrandEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateBrand, isLoading: isBrandUpdating } = useMutation<IBrandEntity, AxiosError>({
        mutationKey: ['updateBrand'],
        mutationFn: async () =>
            PutItem<IBrandEntity, IBrandDTO>(
                'brand',
                brandId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IBrandDTO>
            ),
        onSuccess: async (item: IBrandEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchBrand, isFetching: isBrandFetching } = useQuery<IBrandEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getBrand'],
        queryFn: () => GetItem<IBrandEntity>(
            'brand',
            brandId || '',
        ),
        onSuccess: (result: IBrandEntity): void => {
            setBrand(brand);
            form.setFieldsValue({
                ...result,
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });


    const updateFormFields = (property: string, value: string) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createBrand())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateBrand())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        if (editMode)
            fetchBrand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Brand' : 'Edit Brand'}
                open={true}
                confirmLoading={isLoading || isBrandFetching || isBrandUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Brand' : 'Edit Brand'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='name'
                        label='Name'
                        rules={[
                            { required: true, message: 'Please enter the name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        label='Description'
                        rules={[
                            { required: false, message: 'Please enter the description' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('description', event?.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};