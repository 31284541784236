import { useEffect, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IProductTypeDTO, IProductTypeEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { GetItem, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const ProductTypeModalComponent = ({ onClose, onSubmit, editMode = false, id: productTypeId = '' }: AddEditModalProps<IProductTypeEntity>) => {
    const [form] = Form.useForm<Partial<IProductTypeDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [productType, setProductType] = useState<Partial<IProductTypeEntity>>();

    const { mutate: createProductType, isLoading } = useMutation<IProductTypeEntity, AxiosError>({
        mutationKey: ['createProductType'],
        mutationFn: async () =>
            PostItem<IProductTypeEntity, IProductTypeDTO>(
                'product_type',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IProductTypeDTO>
            ),
        onSuccess: async (item: IProductTypeEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateProductType, isLoading: isProductTypeUpdating } = useMutation<IProductTypeEntity, AxiosError>({
        mutationKey: ['updateProductType'],
        mutationFn: async () =>
            PutItem<IProductTypeEntity, IProductTypeDTO>(
                'product_type',
                productTypeId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IProductTypeDTO>
            ),
        onSuccess: async (item: IProductTypeEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchProductType, isFetching: isProductTypeFetching } = useQuery<IProductTypeEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getProductType'],
        queryFn: () => GetItem<IProductTypeEntity>(
            'product_type',
            productTypeId || '',
        ),
        onSuccess: (result: IProductTypeEntity): void => {
            setProductType(productType);
            form.setFieldsValue({
                ...result
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const updateFormFields = (property: string, value: string | undefined | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched();
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;
        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createProductType())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateProductType())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        fetchProductType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode)
            fetchProductType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Product Types' : 'Edit Product Types'}
                open={true}
                confirmLoading={isLoading || isProductTypeFetching || isProductTypeUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Product Types' : 'Edit Product Types'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='name'
                        label='Name'
                        rules={[
                            { required: true, message: 'Please enter the  name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='product_code'
                        label='product_code'
                        rules={[
                            { required: true, message: 'Please enter the product_code' },
                            // { max: 3, message: 'At least 1 characters, maximum 3' }
                        ]}
                    >
                        <Input type='number'
                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={(event) => updateFormFields('product_code', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};