import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IItemsWithCount, IVendorEntity, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { VendorModalComponent } from './add-edit';

const { Content } = Layout;

const VendorPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [vendorsData, setVendorsData] = useState<IItemsWithCount<IVendorEntity>>(EMPTY_INITIAL_ITEMS);
    const [isVendorModalOpened, setIsVendorModalOpened] = useState(false);
    const [editingVendorId, setEditingVendorId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IVendorEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['vendors'],
        queryFn: () => GetTableItems<IVendorEntity>(
            'vendor',
            ['categories', 'vendor_registration_type'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IVendorEntity>): void => setVendorsData(returnedResult),
        onError: () => setVendorsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteVendor } = useMutation<IVendorEntity, AxiosError>({
        mutationKey: ['deleteVendor'],
        mutationFn: async () =>
            DeleteItem<IVendorEntity>(
                'vendor',
                editingVendorId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Vendor deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IVendorEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IVendorEntity>).columnKey as keyof IVendorEntity]: MapAntDesignSortOrder((sorter as SorterResult<IVendorEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onVendorModalCloseHandler = (newVendor: IVendorEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingVendorId ? {
                type: 'success',
                content: `New vendor '${ newVendor?.first_name + ' ' + newVendor?.last_name }' created!`,
            } : {
                type: 'success',
                content: `Vendor '${ newVendor?.first_name + ' ' + newVendor?.last_name }' updated!`,
            }
        );
    }

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsVendorModalOpened(false);
        setEditingVendorId('');
    }

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item) => !item.dataKey) || []
        });
    };

    const columns: ColumnsType<IVendorEntity> = [
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('first_name', 'by first name', resetSearchHandler)
        },
        {
            title: 'Last name',
            dataIndex: 'last_name',
            key: 'last_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('last_name', 'by last name', resetSearchHandler)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('email', 'by email', resetSearchHandler)
        },
        {
            title: 'mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('mobile', 'by mobile', resetSearchHandler)
        },
        {
            title: 'business_mobile',
            dataIndex: 'business_mobile',
            key: 'business_mobile',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('business_mobile', 'by business_mobile', resetSearchHandler)
        },
        {
            title: 'active ',
            key: 'active',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IVendorEntity) => {
                const active = item?.active?.toString()

                return <Tooltip >
                    <span className='cursor-pointer'>
                        {
                            active
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('active', 'by true/false', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, first_name = '', last_name = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingVendorId(id);
                                setIsVendorModalOpened(true)
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={
                            () => {
                                setEditingVendorId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete vendor '${first_name + ' ' + last_name}'?`,
                                    onOk: () => {
                                        deleteVendor();
                                    },
                                    onCancel: () => resetModal()

                                })
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isVendorModalOpened &&
                <VendorModalComponent
                    editMode={ !!editingVendorId }
                    id={ editingVendorId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onVendorModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Vendor'
                                addButtonText='Add Vendor'
                                // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                                onClick={() => setIsVendorModalOpened(true)}
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={vendorsData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: vendorsData.count,
                                        pageSize: vendorsData.pageSize,
                                        current: vendorsData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true}}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default VendorPageComponent;