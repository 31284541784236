import { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, message, Select, Upload } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { BaseLayoutComponent, TagsDropdown } from '@components';
import { FilterOperatorsEnum, IDeliveryDTO, IDeliveryPartialDTO, IItemsWithCount, IUserEntity, KeyValueRecord, UserRolesEnum } from '@rasayi-workspace/shared';
import { GetItems, PutItem } from '@services';
import { BASE_QUERY_OPTIONS, isFileSizeValid, MAX_FILE_SIZE_MB } from '@constants';
import { MapToSelectOption } from '@helpers';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';
import { Content } from 'antd/es/layout/layout';
import { deliveryContext } from '@contexts';
import { CustomerRadioFieldComponent } from '../3-customer-radio-field';
import { DeliveryCompletePageComponent } from '../5-complete';

export const AddDeliveryReceipt = () => {
    const [form] = Form.useForm<Partial<IDeliveryDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const { setDeliveryDetails, deliveryDetails } = useContext(deliveryContext);
    const [isValidForm, setIsValidForm] = useState(false);
    const [isFileUploadComplete, setIsFileUploadComplete] = useState(true);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<IUserEntity[]>([]);
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<KeyValueRecord>({});
    const [pageDirection, setPageDirection] = useState('');
    const [validUpload, setValidUpload] = useState(false);

    const { refetch: fetchUsers, isFetching: isUsersFetching } = useQuery<IItemsWithCount<IUserEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUsers'],
        queryFn: () => GetItems<IUserEntity>(
            'user',
            [],
            ['id', 'first_name', 'cnic_number', 'last_name', 'primary_mobile', 'address_line1', 'city', 'email'],
            [
                [
                    {
                        field: 'roles.name',
                        operator: FilterOperatorsEnum.EQUAL,
                        value: UserRolesEnum.AGENT
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IUserEntity>): void => setUsers(returnedResult.items),
        onError: () => setUsers([])
    });

    const formToFormDataImage = (valuesObject: KeyValueRecord): FormData => {
        const formData = new FormData();
        valuesObject?.deliverer_id && formData.append('deliverer_id', valuesObject?.deliverer_id);
        valuesObject?.downpayment_receipt?.file?.originFileObj && formData.append('downpayment_receipt', valuesObject?.downpayment_receipt?.file?.originFileObj);
        valuesObject?.customer_picture?.file?.originFileObj && formData.append('customer_picture', valuesObject?.customer_picture?.file?.originFileObj);
        valuesObject?.delivery_location_picture?.file?.originFileObj && formData.append('delivery_location_picture', valuesObject?.delivery_location_picture?.file?.originFileObj);
        valuesObject?.lock_picture?.file?.originFileObj && formData.append('lock_picture', valuesObject?.lock_picture?.file?.originFileObj);
        valuesObject?.house_video?.file?.originFileObj && formData.append('house_video', valuesObject?.house_video?.file?.originFileObj);

        return formData;
    };

    const { mutate: deliveryUpdate, isLoading: isDeliveryUpdating } = useMutation<IDeliveryPartialDTO, AxiosError>({
        mutationKey: ['addDocument'],
        mutationFn: async () =>
            PutItem<IDeliveryPartialDTO, FormData>(
                'delivery/partial',
                `${deliveryDetails?.delivery?.id}`,
                formToFormDataImage(form.getFieldsValue()), {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            ),
        onSuccess: async (item: IDeliveryPartialDTO): Promise<void> => {
            setDeliveryDetails({ ...deliveryDetails, ...item });
            if (pageDirection && pageDirection === 'next')
                setNextPage(true);
            else if (pageDirection && pageDirection === 'previous')
                setPreviousPage(true);
        },
        onError: (error: KeyValueRecord) => {
            messageApi.open({
                type: 'error',
                content: 'Contact support for details'
            });
        }
    });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const checkUploadStatus = (keyIgnore: string) => {
        if (!Object.keys(uploadStatus).some((key) => key === keyIgnore ? false : uploadStatus[key] === true))
            setIsFileUploadComplete(true);
    };

    const handleUpload: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setIsFileUploadComplete(false);
            setUploadStatus({ ...uploadStatus, [info.file.name + '+' + String(info.file.lastModified)]: true });
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            setUploadStatus({ ...uploadStatus, [info.file.name + '+' + String(info.file.lastModified)]: false });
            checkUploadStatus(info.file.name + '+' + String(info.file.lastModified));
        }
        if (info.file.status === 'error') {
            setLoading(false);
            setUploadStatus({ ...uploadStatus, [info.file.name + '+' + String(info.file.lastModified)]: false });
            checkUploadStatus(info.file.name + '+' + String(info.file.lastModified));
            messageApi.open({
                type: 'error',
                content: ('File upload failed.')
            });
        }
    };

    const nextPageHandler = () => {
        setDeliveryDetails({ ...deliveryDetails, ...form.getFieldsValue() });
        deliveryUpdate();
        setPageDirection('next')
    };

    const previousPageHandler = () => {
        setDeliveryDetails({ ...deliveryDetails, ...form.getFieldsValue() });
        setPreviousPage(true);
        setPageDirection('previous');
    };

    useEffect(() => {
        !isUsersFetching && fetchUsers();

    }, []);

    return (
        <>
            {contextHolder}
            {
                nextPage &&
                <DeliveryCompletePageComponent />
            }
            {
                previousPage &&
                <CustomerRadioFieldComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <>
                                <p>
                                    Complete the delivery necessities here!
                                </p>
                                <p>
                                    <Button
                                        type='primary'
                                        onClick={previousPageHandler}
                                        style={{ marginLeft: 4 }}
                                    >
                                        Previous Page
                                    </Button>
                                    <Button
                                        type='primary'
                                        style={{ marginLeft: 4 }}
                                        icon={<PlusOutlined />}
                                        onClick={nextPageHandler}
                                        loading={isDeliveryUpdating}
                                        disabled={ !isFileUploadComplete || !validUpload }
                                    >
                                        Next
                                    </Button>
                                </p>
                            </>
                        }
                        style={{ height: 600, overflow: 'scroll' }}
                    >
                        <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={ { span: 8 } }
                            form={form}
                            onValuesChange={handleFormChange}
                        >
                            <Form.Item
                                name='deliverer_id'
                                label='deliverer'
                                initialValue={deliveryDetails?.delivery?.deliverer_id}
                            >
                                <Select
                                    tagRender={TagsDropdown}
                                    onChange={(selectedItemIds: string) => {
                                        const selectedUser = users.find((user) => user.id === selectedItemIds);

                                        if (selectedUser) {
                                            form.setFieldsValue({
                                                'deliverer_id': selectedUser.id,
                                            });
                                        }
                                    }}
                                    showSearch
                                    className='w-full'
                                    options={MapToSelectOption(users, 'first_name', 'id')}
                                />
                            </Form.Item>
                            <Form.Item
                                name='downpayment_receipt'
                                label='downpayment_receipt'
                                rules={[
                                    { required: false, message: 'Please enter the downpayment_receipt' }
                                ]}
                            >
                                <Upload
                                    defaultFileList={deliveryDetails?.delivery?.downpayment_receipt ? [
                                        {
                                            uid: deliveryDetails?.delivery?.downpayment_receipt?.id,
                                            name: deliveryDetails?.delivery?.downpayment_receipt?.name,
                                            status: 'done',
                                            url: deliveryDetails?.delivery?.downpayment_receipt?.url
                                        }] : []}
                                    customRequest={({ file, onSuccess }) => {
                                        if (onSuccess) {
                                            setTimeout(() => {
                                                onSuccess('ok');
                                            }, 0);
                                        }
                                    }}
                                    beforeUpload={ (file: File) => {
                                        if (!isFileSizeValid(file)) {
                                            message.error(`File size must be less than ${ MAX_FILE_SIZE_MB }MB`);
                                            return setValidUpload(false);
                                        }
                                        return setValidUpload(true);
                                    } }
                                    onChange={handleUpload}
                                    maxCount={1}
                                    multiple={false}
                                >
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name='customer_picture'
                                label='customer_picture'
                                rules={[
                                    { required: false, message: 'Please enter the customer_picture' }
                                ]}
                            >
                                <Upload
                                    defaultFileList={deliveryDetails?.delivery?.customer_picture ? [
                                        {
                                            uid: deliveryDetails?.delivery?.customer_picture?.id,
                                            name: deliveryDetails?.delivery?.customer_picture?.name,
                                            status: 'done',
                                            url: deliveryDetails?.delivery?.customer_picture?.url
                                        }] : []}
                                    customRequest={({ file, onSuccess }) => {
                                        if (onSuccess) {
                                            setTimeout(() => {
                                                onSuccess('ok');
                                            }, 0);
                                        }
                                    }}
                                    beforeUpload={ (file: File) => {
                                        if (!isFileSizeValid(file)) {
                                            message.error(`File size must be less than ${ MAX_FILE_SIZE_MB }MB`);
                                            return setValidUpload(false);
                                        }
                                        return setValidUpload(true);
                                    } }
                                    onChange={handleUpload}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name='delivery_location_picture'
                                label='House Picture'
                                rules={[
                                    { required: false, message: 'Please enter the delivery_location_picture' }
                                ]}
                            >
                                <Upload
                                    defaultFileList={deliveryDetails?.delivery?.delivery_location_picture ? [
                                        {
                                            uid: deliveryDetails?.delivery?.delivery_location_picture?.id,
                                            name: deliveryDetails?.delivery?.delivery_location_picture?.name,
                                            status: 'done',
                                            url: deliveryDetails?.delivery?.delivery_location_picture?.url
                                        }] : []}
                                    customRequest={({ file, onSuccess }) => {
                                        if (onSuccess) {
                                            setTimeout(() => {
                                                onSuccess('ok');
                                            }, 0);
                                        }
                                    }}
                                    beforeUpload={ (file: File) => {
                                        if (!isFileSizeValid(file)) {
                                            message.error(`File size must be less than ${ MAX_FILE_SIZE_MB }MB`);
                                            return setValidUpload(false);
                                        }
                                        return setValidUpload(true);
                                    } }
                                    onChange={handleUpload}
                                    maxCount={1}
                                    multiple={false}
                                >
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name='house_video'
                                label='house_video'
                                rules={[
                                    { required: false, message: 'Please enter the house_video' }
                                ]}
                            >
                                <Upload
                                    defaultFileList={deliveryDetails?.delivery?.house_video ? [
                                        {
                                            uid: deliveryDetails?.delivery?.house_video?.id,
                                            name: deliveryDetails?.delivery?.house_video?.name,
                                            status: 'done',
                                            url: deliveryDetails?.delivery?.house_video?.url
                                        }] : []}
                                    customRequest={({ file, onSuccess }) => {
                                        if (onSuccess) {
                                            setTimeout(() => {
                                                onSuccess('ok');
                                            }, 0);
                                        }
                                    }}
                                    beforeUpload={ (file: File) => {
                                        if (!isFileSizeValid(file, 25)) {
                                            message.error(`File size must be less than ${ 25 }MB`);
                                            return setValidUpload(false);
                                        }
                                        return setValidUpload(true);
                                    } }
                                    onChange={handleUpload}
                                    maxCount={1}
                                    multiple={false}
                                >
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name='lock_picture'
                                label='lock_picture'
                                rules={[
                                    { required: false, message: 'Please enter the lock_picture' }
                                ]}
                            >
                                <Upload
                                    defaultFileList={deliveryDetails?.delivery?.lock_picture ? [
                                        {
                                            uid: deliveryDetails?.delivery?.lock_picture?.id,
                                            name: deliveryDetails?.delivery?.lock_picture?.name,
                                            status: 'done',
                                            url: deliveryDetails?.delivery?.lock_picture?.url
                                        }] : []}
                                    customRequest={({ file, onSuccess }) => {
                                        if (onSuccess) {
                                            setTimeout(() => {
                                                onSuccess('ok');
                                            }, 0);
                                        }
                                    }}
                                    beforeUpload={ (file: File) => {
                                        if (!isFileSizeValid(file)) {
                                            message.error(`File size must be less than ${ MAX_FILE_SIZE_MB }MB`);
                                            return setValidUpload(false);
                                        }
                                        return setValidUpload(true);
                                    } }
                                    onChange={handleUpload}
                                    maxCount={1}
                                    multiple={false}
                                >
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};