import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, Layout, message, Table, TableProps } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IInstalmentPlanEntity, IItemsWithCount, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, TablePageTitleComponent } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { InstalmentPlanModalComponent } from './add-edit';

const { Content } = Layout;

export const InstalmentPlanPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [InstalmentPlansData, setInstalmentPlansData] = useState<IItemsWithCount<IInstalmentPlanEntity>>(EMPTY_INITIAL_ITEMS);
    const [isInstalmentPlanModalOpened, setIsInstalmentPlanModalOpened] = useState(false);
    const [editingInstalmentPlanId, setEditingInstalmentPlanId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IInstalmentPlanEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['InstalmentPlans'],
        queryFn: () => GetTableItems<IInstalmentPlanEntity>(
            'instalment_plan',
            [],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IInstalmentPlanEntity>): void => setInstalmentPlansData(returnedResult),
        onError: () => setInstalmentPlansData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteInstalmentPlan } = useMutation<IInstalmentPlanEntity, AxiosError>({
        mutationKey: ['deleteInstalmentPlan'],
        mutationFn: async () =>
            DeleteItem<IInstalmentPlanEntity>(
                'instalment_plan',
                editingInstalmentPlanId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `InstalmentPlan deleted!`,
            });

            !isFetching && refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IInstalmentPlanEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IInstalmentPlanEntity>).columnKey as keyof IInstalmentPlanEntity]: MapAntDesignSortOrder((sorter as SorterResult<IInstalmentPlanEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onInstalmentPlanModalCloseHandler = (newInstalmentPlan: IInstalmentPlanEntity) => {
        !isFetching && refetch();
        resetModal();

        messageApi.open(
            !editingInstalmentPlanId ? {
                type: 'success',
                content: `New InstalmentPlan '${ newInstalmentPlan?.name }' created!`,
            } : {
                type: 'success',
                content: `InstalmentPlan '${ newInstalmentPlan?.name }' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsInstalmentPlanModalOpened(false);
        setEditingInstalmentPlanId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IInstalmentPlanEntity> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('name', 'by name', resetSearchHandler)
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('description', 'by description', resetSearchHandler)
        },
        {
            title: 'Days',
            dataIndex: 'days',
            key: 'days',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('days', 'by days', resetSearchHandler)
        },
        {
            title: 'Cycle',
            dataIndex: 'cycle',
            key: 'cycle',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('cycle', 'by cycle', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
    ];

    useEffect(() => {
        !isFetching && refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isInstalmentPlanModalOpened &&
                <InstalmentPlanModalComponent
                    editMode={ !!editingInstalmentPlanId }
                    id={ editingInstalmentPlanId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onInstalmentPlanModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Instalment Plans'
                                addButtonText='Add Instalment Plan'
                                // disabled={!HasAnyPermission([PermissionsEnum.InstalmentPlan_FULL, PermissionsEnum.InstalmentPlan_WRITE])}
                                onClick={ () => setIsInstalmentPlanModalOpened(true) }
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ InstalmentPlansData.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: InstalmentPlansData.count,
                                        pageSize: InstalmentPlansData.pageSize,
                                        current: InstalmentPlansData.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};