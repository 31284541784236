import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IComplianceEntity, IDocumentEntity, IItemsWithCount, KeyValueRecord, PermissionsEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { ComplianceModalComponent } from './add-edit';
import { ViewDocumentModalComponent } from './view-documents';
import { AddDocumentModalComponent } from './add-document';
import { ViewDeliveryDocumentModalComponent } from './view-delivery-documents';
import { ViewOrderDocumentModalComponent } from './view-order-documents';

const { Content } = Layout;

const CompliancePageComponent = (): any => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [compliancesData, setCompliancesData] = useState<IItemsWithCount<any>>(EMPTY_INITIAL_ITEMS);
    const [isComplianceModalOpened, setIsComplianceModalOpened] = useState(false);
    const [editingComplianceId, setEditingComplianceId] = useState('');
    const [viewDocumentComplianceId, setViewDocumentComplianceId] = useState('');
    const [isViewDocumentModalOpened, setIsViewDocumentModalOpened] = useState(false);
    const [isAddDocumentModalOpened, setIsAddDocumentModalOpened] = useState(false);
    const [editingDocumentUserId, setEditingDocumentUserId] = useState('');
    const [viewOrderDocumentUserId, setViewOrderDocumentUserId] = useState('');
    const [isViewOrderDocumentModalOpened, setIsViewOrderDocumentModalOpened] = useState(false);
    const [isDeliveryViewDocumentModalOpened, setIsDeliveryViewDocumentModalOpened] = useState(false);

    const { refetch, isFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['compliance'],
        queryFn: () => GetTableItems<any>(
            'compliance',
            ['delivery.order', 'delivery.order.user', 'delivery.order.internal_point_of_contact'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<any>): void => setCompliancesData(returnedResult),
        onError: () => setCompliancesData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteCompliance } = useMutation<any, AxiosError>({
        mutationKey: ['deleteCompliance'],
        mutationFn: async () =>
            DeleteItem<any>(
                'compliance',
                editingComplianceId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Compliance deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IComplianceEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IComplianceEntity>).columnKey as keyof IComplianceEntity]: MapAntDesignSortOrder((sorter as SorterResult<IComplianceEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onComplianceModalCloseHandler = (newCompliance: any) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingComplianceId ? {
                type: 'success',
                content: `New compliance created!`,
            } : {
                type: 'success',
                content: `Compliance updated!`,
            }
        );
    };

    const onComplianceViewDocumentModalCloseHandler = (newCompliance: IDocumentEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !viewDocumentComplianceId ? {
                type: 'success',
                content: `New compliance '${newCompliance?.id + ' ' + newCompliance?.name}' created!`,
            } : {
                type: 'success',
                content: `Compliance '${newCompliance?.id + ' ' + newCompliance?.name}' updated!`,
            }
        );
    };

    const onUserAddDocumentModalCloseHandler = (newUser: IDocumentEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingDocumentUserId ? {
                type: 'success',
                content: `Compliance created!`,
            } : {
                type: 'success',
                content: `Compliance updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const onViewDocumentModalCloseHandler = () => resetViewModal();

    const onAddDocumentModalCloseHandler = () => resetAddModal();

    const onViewOrderDocumentModalCloseHandler = () => resetViewOrderModal();


    const resetModal = () => {
        setIsComplianceModalOpened(false);
        setEditingComplianceId('');
    };

    const resetViewModal = () => {
        setIsViewDocumentModalOpened(false);
        setViewDocumentComplianceId('');
    };

    const resetViewOrderModal = () => {
        setIsViewOrderDocumentModalOpened(false);
        setViewOrderDocumentUserId('');
    };

    const resetAddModal = () => {
        setIsAddDocumentModalOpened(false);
        setEditingDocumentUserId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item: any) => !item.dataKey) || []
        });
    };

    const columns: ColumnsType<any> = [
        {
            title: 'POC',
            dataIndex: ['delivery', 'order', 'internal_point_of_contact', 'first_name'],
            key: 'delivery.order.internal_point_of_contact.first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivery.order.internal_point_of_contact.first_name', 'by POC', resetSearchHandler)
        },
        {
            title: 'Compliance status',
            dataIndex: 'compliance_status',
            key: 'compliance_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('compliance_status', 'by Compliance status', resetSearchHandler)
        },
        {
            title: 'Order ID',
            dataIndex: ['delivery', 'order', 'internal_id'],
            key: 'delivery.order.internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivery.order.internal_id', 'by Order ID', resetSearchHandler)
        },
        {
            title: 'Customer first name',
            dataIndex: ['delivery', 'order', 'user', 'first_name'],
            key: 'delivery.order.user.first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivery.order.user.first_name', 'by customer first name', resetSearchHandler)
        },
        {
            title: 'Customer last name',
            dataIndex: ['delivery', 'order', 'user', 'last_name'],
            key: 'delivery.order.user.last_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivery.order.user.last_name', 'by customer last name', resetSearchHandler)
        },
        {
            title: 'Customer cnic',
            dataIndex: ['delivery', 'order', 'user', 'cnic_number'],
            key: 'delivery.order.user.cnic_number',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivery.order.user.cnic_number', 'by customer cnic', resetSearchHandler)
        },
        // {
        //     title: 'Downpayment collection',
        //     key: 'downpayment_collection',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     render: (_: string, item: IComplianceEntity) => {
        //         const downpayment_collection = item.downpayment_collection.toString();

        //         return <Tooltip>
        //             <span className='cursor-pointer'>
        //                 {
        //                     downpayment_collection
        //                 }
        //             </span>
        //         </Tooltip>;
        //     },
        //     ...ColumnsSearchProps('downpayment_collection', 'by true/false downpayment', resetSearchHandler)
        // },
        // {
        //     title: 'Box collection',
        //     key: 'box_collection',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     render: (_: string, item: IComplianceEntity) => {
        //         const box_collection = item.box_collection.toString();

        //         return <Tooltip>
        //             <span className='cursor-pointer'>
        //                 {
        //                     box_collection
        //                 }
        //             </span>
        //         </Tooltip>;
        //     },
        //     ...ColumnsSearchProps('box_collection', 'by true/false box', resetSearchHandler)
        // },
        // {
        //     title: 'Signature',
        //     key: 'signature',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     render: (_: string, item: IComplianceEntity) => {
        //         const signature = item.signature.toString();

        //         return <Tooltip>
        //             <span className='cursor-pointer'>
        //                 {
        //                     signature
        //                 }
        //             </span>
        //         </Tooltip>;
        //     },
        //     ...ColumnsSearchProps('signature', 'by true/false signature', resetSearchHandler)
        // },
        // {
        //     title: 'Thumb',
        //     key: 'thumb',
        //     ellipsis: true,
        //     sorter: () => 0,
        //     render: (_: string, item: IComplianceEntity) => {
        //         const thumb = item.thumb.toString();

        //         return <Tooltip>
        //             <span className='cursor-pointer'>
        //                 {
        //                     thumb
        //                 }
        //             </span>
        //         </Tooltip>;
        //     },
        //     ...ColumnsSearchProps('thumb', 'by true/false thumb', resetSearchHandler)
        // },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'View documents',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EyeOutlined />}
                        // disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                        onClick={
                            () => {
                                setViewDocumentComplianceId(id);
                                setIsViewDocumentModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'View order documents',
            dataIndex: ['delivery', 'order', 'id'],
            key: 'delivery.order.id',
            render: (_, { delivery }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EyeOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setViewOrderDocumentUserId(delivery?.order?.id);
                                setIsViewOrderDocumentModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'View Delivery documents',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { delivery_id = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EyeOutlined />}
                        // disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                        onClick={
                            () => {
                                setViewDocumentComplianceId(delivery_id);
                                setIsDeliveryViewDocumentModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, first_name = '', last_name = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<CloudUploadOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingDocumentUserId(id);
                                setIsAddDocumentModalOpened(true);
                            }
                        }
                    />
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingComplianceId(id);
                                setIsComplianceModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={
                            () => {
                                setEditingComplianceId(id);
                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete compliance '${id}'?`,
                                    onOk: () => {
                                        deleteCompliance();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        !isFetching && refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);


    return (
        <>
            {contextHolder}
            {
                isDeliveryViewDocumentModalOpened &&
                <ViewDeliveryDocumentModalComponent
                    editMode={!!viewDocumentComplianceId}
                    id={viewDocumentComplianceId}
                    onClose={() => {
                        setViewDocumentComplianceId('');
                        setIsDeliveryViewDocumentModalOpened(false);
                    }}
                    onSubmit={() => {
                        setViewDocumentComplianceId('');
                        setIsDeliveryViewDocumentModalOpened(false);
                    }}
                />
            }
            {
                isViewOrderDocumentModalOpened &&
                <ViewOrderDocumentModalComponent
                    editMode={ !!viewOrderDocumentUserId }
                    id={ viewOrderDocumentUserId }
                    onClose={ onViewOrderDocumentModalCloseHandler }
                    onSubmit={ onViewOrderDocumentModalCloseHandler }
                />
            }
            {
                isComplianceModalOpened &&
                <ComplianceModalComponent
                    editMode={!!editingComplianceId}
                    id={editingComplianceId}
                    onClose={onModalCloseHandler}
                    onSubmit={onComplianceModalCloseHandler}
                />
            }
            {
                isViewDocumentModalOpened &&
                <ViewDocumentModalComponent
                    editMode={!!viewDocumentComplianceId}
                    id={viewDocumentComplianceId}
                    onClose={() => {
                        setViewDocumentComplianceId('');
                        setIsViewDocumentModalOpened(false);
                    }}
                    onSubmit={() => {
                        setViewDocumentComplianceId('');
                        setIsViewDocumentModalOpened(false);
                    }}
                />
            }
            {
                isAddDocumentModalOpened &&
                <AddDocumentModalComponent
                    editMode={!!editingDocumentUserId}
                    id={editingDocumentUserId}
                    onClose={onAddDocumentModalCloseHandler}
                    onSubmit={onUserAddDocumentModalCloseHandler}
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            'Compliance'
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={compliancesData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: compliancesData.count,
                                        pageSize: compliancesData.pageSize,
                                        current: compliancesData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true }}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default CompliancePageComponent;