import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IDocumentEntity, IInstalmentEntity, IItemsWithCount, KeyValueRecord, PermissionsEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { InstalmentModalComponent } from './add-edit';
import { ViewDocumentModalComponent } from './view-documents';

const { Content } = Layout;

export const InstalmentPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>({ ...DEFAULT_TABLE_STATE, sortBy: { 'order.internal_id': 'DESC', 'payment_type.index': 'ASC' } });
    const [instalmentsData, setInstalmentsData] = useState<IItemsWithCount<IInstalmentEntity>>(EMPTY_INITIAL_ITEMS);
    const [isInstalmentModalOpened, setIsInstalmentModalOpened] = useState(false);
    const [viewDocumentPaymentProofId, setViewDocumentPaymentProofId] = useState('');
    const [isViewDocumentModalOpened, setIsViewDocumentModalOpened] = useState(false);
    const [editingInstalmentId, setEditingInstalmentId] = useState('');
    const defaultSorting: KeyValueRecord = { 'order.internal_id': 'desc', 'payment_type.index': 'asc' };

    const { refetch, isFetching } = useQuery<IItemsWithCount<IInstalmentEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['instalments'],
        queryFn: () => GetTableItems<IInstalmentEntity>(
            'instalment',
            ['payment_type', 'user', 'order.inventories.procurement', 'order.inventories.mobile_info'],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IInstalmentEntity>): void => setInstalmentsData(returnedResult),
        onError: () => setInstalmentsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteInstalment } = useMutation<IInstalmentEntity, AxiosError>({
        mutationKey: ['deleteInstalment'],
        mutationFn: async () =>
            DeleteItem<IInstalmentEntity>(
                'instalment',
                editingInstalmentId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Instalment deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IInstalmentEntity>['onChange'] = (pagination, filters, sorter) => {
        const sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IInstalmentEntity>).columnKey as keyof IInstalmentEntity]: MapAntDesignSortOrder((sorter as SorterResult<IInstalmentEntity>).order)
        });

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: Object.keys(sortBy).length ? sortBy : defaultSorting,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onInstalmentModalCloseHandler = (newInstalment: IInstalmentEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingInstalmentId ? {
                type: 'success',
                content: `New instalment '${ newInstalment?.user + ' ' + newInstalment?.total_amount }' created!`,
            } : {
                type: 'success',
                content: `Instalment '${ newInstalment?.user + ' ' + newInstalment?.total_amount }' updated!`,
            }
        );
    };

    const onPaymentProofViewDocumentModalCloseHandler = (newPaymentProof: IDocumentEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !viewDocumentPaymentProofId ? {
                type: 'success',
                content: `New paymentProof '${ newPaymentProof?.id }' created!`,
            } : {
                type: 'success',
                content: `PaymentProof '${ newPaymentProof?.id }' updated!`,
            }
        );
    };
    
    const onModalCloseHandler = () => resetModal();

    const onViewDocumentModalCloseHandler = () => resetViewModal();

    const resetModal = () => {
        setIsInstalmentModalOpened(false);
        setEditingInstalmentId('');
    };

    const resetViewModal = () => {
        setIsViewDocumentModalOpened(false);
        setViewDocumentPaymentProofId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IInstalmentEntity> = [
        {
            title: 'ID',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('internal_id', 'By Instalment ID', resetSearchHandler)
        },
        {
            title: 'Order id',
            dataIndex: ['order', 'internal_id'],
            key: 'order.internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('order.internal_id', 'By Internal Order ID', resetSearchHandler)
        },
        {
            title: 'Customer Name',
            dataIndex: ['user', 'first_name'],
            key: 'user.first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (_: string, item: IInstalmentEntity) => {
                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            item?.user?.first_name + ' ' + item.user?.last_name
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('user.first_name', `by User's First Name`, resetSearchHandler)
        },
        {
            title: 'User cnic',
            dataIndex: ['user', 'cnic_number'],
            key: 'user.cnic_number',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('user.cnic_number', 'by cnic_number', resetSearchHandler)
        },
        {
            title: 'Model',
            dataIndex: ['order', 'inventories', 'procurement', 'model'],
            key: 'order.inventories.procurement.model',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IInstalmentEntity) => {
                const model = item?.order?.inventories?.map(model => model?.procurement?.model?.toString());

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            model
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('order.inventories.procurement.model', 'by device model', resetSearchHandler)
        },
        {
            title: 'Locking identifier',
            dataIndex: ['order', 'inventories', 'mobile_info', 'locking_identifier'],
            key: 'order.inventories.mobile_info.locking_identifier',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IInstalmentEntity) => {
                const locking_identifier = item?.order?.inventories?.map(model => model?.mobile_info?.locking_identifier);

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            locking_identifier
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('order.inventories.mobile_info.locking_identifier', 'by locking_identifier', resetSearchHandler)
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('amount', 'by amount', resetSearchHandler)
        },
        {
            title: 'Paid amount',
            dataIndex: 'paid_amount',
            key: 'paid_amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('paid_amount', 'by paid_amount', resetSearchHandler)
        },
        {
            title: 'Installment status',
            dataIndex: 'instalment_status',
            key: 'instalment_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('instalment_status', 'by instalment_status', resetSearchHandler)
        },
        {
            title: 'Payment type',
            key: 'payment_type.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IInstalmentEntity) => {
                const payment_type = item.payment_type?.name;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            payment_type
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('payment_type.name', 'by payment_type name', resetSearchHandler)
        },
        {
            title: 'Paid ',
            key: 'paid',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IInstalmentEntity) => {
                const paid = item?.paid?.toString();

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            paid
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('paid', 'by true/false', resetSearchHandler)
        },
        {
            title: 'Due date',
            dataIndex: 'due_datetime',
            key: 'due_datetime',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'View Documents',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EyeOutlined/> }
                        // disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) }
                        onClick={
                            () => {
                                setViewDocumentPaymentProofId(id);
                                setIsViewDocumentModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, user_id = '', total_amount = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EditOutlined/> }
                        disabled
                        onClick={
                            () => {
                                setEditingInstalmentId(id);
                                setIsInstalmentModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={ <DeleteOutlined/> }
                        disabled={ !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE]) }
                        onClick={
                            () => {
                                setEditingInstalmentId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete user '${ user_id + ' ' + total_amount }'?`,
                                    onOk: () => {
                                        deleteInstalment();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        !isFetching && refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isInstalmentModalOpened &&
                <InstalmentModalComponent
                    editMode={ !!editingInstalmentId }
                    id={ editingInstalmentId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onInstalmentModalCloseHandler }
                />
            }
            {
                isViewDocumentModalOpened &&
                <ViewDocumentModalComponent
                    editMode={ !!viewDocumentPaymentProofId }
                    id={ viewDocumentPaymentProofId }
                    onClose={ onViewDocumentModalCloseHandler }
                    onSubmit={ onPaymentProofViewDocumentModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white binstalment rounded-lg'>
                    <Card
                        title={ 'Instalments' }
                    >
                        <div className='binstalment-2 binstalment-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ instalmentsData.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: instalmentsData.count,
                                        pageSize: instalmentsData.pageSize,
                                        current: instalmentsData.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};