import React, { useContext } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { DiffOutlined, DropboxOutlined, KeyOutlined, MailOutlined, SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Routes } from '@routes';
import { useNavigate } from 'react-router-dom';
import { sideBarContext } from '@contexts';
import { siderStyle } from '@constants';
import { PermissionsEnum } from '@rasayi-workspace/shared';
import { HasPermission } from '@services';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const SidebarMenuItemComponent: React.FC = () => {
    const navigate = useNavigate();

    const items: MenuItem[] = [
        getItem('Dashboard', `/${Routes.dashboard.path}`, <UserOutlined />),
        getItem('Agents', `/${Routes.users.path}`, <UserOutlined />),
        getItem('Leads', `/${Routes.leads.path}`, <DiffOutlined />),
        getItem('Customers', `/${Routes.customers.path}`, <UserOutlined />),
        getItem('Guarantors', `/${Routes.guarantors.path}`, <UserOutlined />),
        getItem('Procurement', `/${Routes.procurement.path}`, <DiffOutlined />),
        getItem('Inventory', 'sub1', <MailOutlined />, [
            getItem('Inventory', `/${Routes.inventory.path}`, <MailOutlined />),
            getItem('Mobile info', `/${Routes.mobileInfo.path}`, <MailOutlined />),
        ]),
        getItem('Orders', 'sub2', <DropboxOutlined />, [
            getItem('All Orders', `/${Routes.orders.path}`, <DropboxOutlined />),
            getItem('Create Order', `/${Routes.createOrder.path}`, <DropboxOutlined />),
            getItem('Update Order', `/${Routes.updateOrder.path}`, <DropboxOutlined />),
        ]),
        getItem('Delivery', 'sub3', <MailOutlined />, [
            getItem('All Deliveries', `/${Routes.delivery.path}`, <UserOutlined />),
            getItem('Ready to Deliver', `/${Routes.readyToDeliver.path}`, <UserOutlined />),
        ]),
        getItem('Compliance', `/${Routes.compliance.path}`, <UserOutlined />),
        getItem('Instalments', 'sub4', <MailOutlined />, [
            getItem('All Instalments', `/${Routes.instalments.path}`, <UserOutlined />),
            getItem('Pay Instalment', `/${Routes.payInstalment.path}`, <UserOutlined />)
        ]),
        getItem('Transactions', `/${Routes.transactions.path}`, <UserOutlined />),
        getItem('Vendors', 'sub5', <MailOutlined />, [
            getItem('All Vendors', `/${Routes.vendors.path}`, <UserOutlined />),
            getItem('Vendor Bank Details', `/${Routes.vendorBankDetails.path}`, <UserOutlined />),
        ]),
        getItem('Admin Settings', 'sub6', <SettingOutlined />, [
            getItem('Brands', `/${Routes.brands.path}`, <SettingOutlined />),
            getItem('Brand Charges', `/${Routes.brandCharges.path}`, <SettingOutlined />),
            getItem('Product Types', `/${Routes.productTypes.path}`, <SettingOutlined />),
            getItem('Instalment Plans', `/${Routes.instalmentPlans.path}`, <SettingOutlined />),
            getItem('Payment Types', `/${Routes.paymentTypes.path}`, <SettingOutlined />),
            getItem('Channels', `/${Routes.channels.path}`, <UserOutlined />),
            HasPermission(PermissionsEnum.ACTIVITY_LOGS) ?
                getItem('Activity Logs', `/${Routes.activityLogs.path}`, <TeamOutlined />) : null,
            getItem('Roles', `/${Routes.roles.path}`, <TeamOutlined />),
            // getItem('Groups', `/${Routes.groups.path}`, <GroupOutlined />),
            getItem('Permissions', `/${Routes.permissions.path}`, <KeyOutlined />),
        ]),
    ];
    const { current, setCurrent, openKey, setOpenKey } = useContext(sideBarContext);
    const onClickHandler: MenuProps['onClick'] = (e: any) => {
        const keyPath = e.keyPath.slice(1);
        const lastKey = keyPath[keyPath.length - 1];

        setOpenKey(lastKey);
        setCurrent(e.key);
        navigate(e.key);
    };

    return (
        <Menu
            style={{ ...siderStyle, height: '70vh' }}
            // className='h-screen overflow-auto pb-[120px]'
            onClick={onClickHandler}
            selectedKeys={[current]}
            mode='inline'
            items={items}
            selectable={true}
            defaultOpenKeys={openKey ? [openKey] : undefined}
        />
    );
};