import { Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

export const TagsDropdown = (props: CustomTagProps): JSX.Element => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            className='mr-1'
        >
            {label}
        </Tag>
    );
};