import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, Card, Layout, message, Table, TableProps, Tag } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission } from '@services';
import { IItemsWithCount, IPermissionEntity, KeyValueRecord, PermissionsEnum, PermissionTypeEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ErrorResponse, ITableState } from '@interfaces';
import { PermissionModalComponent } from './add-edit';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';

const { Content } = Layout;

export const PermissionPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [permissionsData, setPermissionsData] = useState<IItemsWithCount<IPermissionEntity>>(EMPTY_INITIAL_ITEMS);
    const [isPermissionModalOpened, setIsPermissionModalOpened] = useState(false);
    const [editingPermissionId, setEditingPermissionId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IPermissionEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['permissions'],
        queryFn: () => GetTableItems<IPermissionEntity>(
            'permission',
            [],
            [],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IPermissionEntity>): void => setPermissionsData(returnedResult),
        onError: () => setPermissionsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deletePermission } = useMutation<IPermissionEntity, AxiosError>({
        mutationKey: ['deletePermission'],
        mutationFn: async () =>
            DeleteItem<IPermissionEntity>(
                'permission',
                editingPermissionId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Permission deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IPermissionEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IPermissionEntity>).columnKey as keyof IPermissionEntity]: MapAntDesignSortOrder((sorter as SorterResult<IPermissionEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onPermissionModalCloseHandler = (newPermission: IPermissionEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingPermissionId ? {
                type: 'success',
                content: `New permission '${ newPermission?.name }' created!`,
            } : {
                type: 'success',
                content: `Permission '${ newPermission?.name }' updated!`,
            }
        );
    }

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsPermissionModalOpened(false);
        setEditingPermissionId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IPermissionEntity> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: () => 0,
            ...ColumnsSearchProps('name', 'by permission name', resetSearchHandler)
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            ellipsis: true,
            sorter: () => 0,
            render: (type) => (
                type === PermissionTypeEnum.SYSTEM ?
                    <Tag color={'geekblue'} key={type}>
                        {type.toUpperCase()}
                    </Tag>
                    :
                    <Tag color={'green'} key={type}>
                        {type.toUpperCase()}
                    </Tag>
            ),
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            sorter: () => 0,
            render: (value: string) => transformToFormattedTime(value),
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            sorter: () => 0,
            render: (value: string) => transformToFormattedTime(value),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, type, name }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        disabled={type === PermissionTypeEnum.SYSTEM || !HasAnyPermission([PermissionsEnum.PERMISSION_FULL, PermissionsEnum.PERMISSION_WRITE])}
                        onClick={
                            () => {
                                setEditingPermissionId(id);
                                setIsPermissionModalOpened(true)
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        disabled={type === PermissionTypeEnum.SYSTEM || !HasAnyPermission([PermissionsEnum.PERMISSION_FULL, PermissionsEnum.PERMISSION_WRITE])}
                        onClick={
                            () => {
                                setEditingPermissionId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete permission '${name}'?`,
                                    onOk: () => {
                                        deletePermission();
                                    },
                                    onCancel: () => resetModal()

                                })
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isPermissionModalOpened &&
                <PermissionModalComponent
                    editMode={ !!editingPermissionId }
                    id={ editingPermissionId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onPermissionModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Permissions'
                                addButtonText='Add Permissions'
                                onClick={() => setIsPermissionModalOpened(true)}
                                disabled={!HasAnyPermission([PermissionsEnum.PERMISSION_FULL, PermissionsEnum.PERMISSION_WRITE])}
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                caption={
                                    <div className='text-left mb-5'>
                                        <Tag color={'geekblue'} key={PermissionTypeEnum.SYSTEM}>{PermissionTypeEnum.SYSTEM.toUpperCase()}</Tag>permissions are not modifiable.
                                        Only <Tag color={'green'} key={PermissionTypeEnum.EXTERNAL}>{PermissionTypeEnum.EXTERNAL.toUpperCase()}</Tag>
                                        permissions are modifiable.
                                    </div>
                                }
                                columns={columns}
                                dataSource={permissionsData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: permissionsData.count,
                                        pageSize: permissionsData.pageSize,
                                        current: permissionsData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true}}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};