/**
 * Enumerator made so that based on these keys, client can query items from the database.
 */
export enum FilterOperatorsEnum {
    GREATER_THAN = 'gt',
    GREATER_THAN_OR_EQUAL = 'ge',
    LESS_THAN = 'lt',
    LESS_THAN_OR_EQUAL = 'le',
    EQUAL = 'eq',
    NOT_EQUAL = 'ne',
    LIKE = 'li',
    IN = 'in',
    NOT_IN = 'ni',
    NOT_NULL = 'nnl',
    NULL = 'nl',
}