import React, { ReactNode, useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { Routes } from '@routes';
import { DiffOutlined, DropboxOutlined, GroupOutlined, KeyOutlined, MailOutlined, SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const MobileNavBar = ({ children }: { children?: ReactNode }): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    const items: MenuItem[] = [
        getItem('Dashboard', `/${Routes.dashboard.path}`, <UserOutlined />),
        getItem('Agents', `/${Routes.users.path}`, <UserOutlined />),
        getItem('Leads', `/${Routes.leads.path}`, <DiffOutlined />),
        getItem('Customers', `/${Routes.customers.path}`, <UserOutlined />),
        getItem('Procurement', `/${Routes.procurement.path}`, <DiffOutlined />),
        getItem('Inventory', 'sub1', <MailOutlined />, [
            getItem('Inventory', `/${Routes.inventory.path}`, <MailOutlined />),
            getItem('Mobile info', `/${Routes.mobileInfo.path}`, <MailOutlined />),
        ]),
        getItem('Orders', 'sub2', <DropboxOutlined />, [
            getItem('All Orders', `/${Routes.orders.path}`, <DropboxOutlined />),
            getItem('Create Order', `/${Routes.createOrder.path}`, <DropboxOutlined />),
            getItem('Update Order', `/${Routes.updateOrder.path}`, <DropboxOutlined />),
        ]),
        getItem('Delivery', 'sub3', <MailOutlined />, [
            getItem('All Deliveries', `/${Routes.delivery.path}`, <UserOutlined />),
            getItem('Ready to Deliver', `/${Routes.readyToDeliver.path}`, <UserOutlined />),

        ]),
        getItem('Compliance', `/${Routes.compliance.path}`, <UserOutlined />),
        getItem('Instalments', 'sub4', <MailOutlined />, [
            getItem('All Instalments', `/${Routes.instalments.path}`, <UserOutlined />),
            getItem('Pay Instalment', `/${Routes.payInstalment.path}`, <UserOutlined />)
        ]),
        getItem('Transactions', `/${Routes.transactions.path}`, <UserOutlined />),
        getItem('Vendors', 'sub5', <MailOutlined />, [
            getItem('All Vendors', `/${Routes.vendors.path}`, <UserOutlined />),
            getItem('Vendor Bank Details', `/${Routes.vendorBankDetails.path}`, <UserOutlined />),
        ]),
        getItem('Admin Settings', 'sub6', <SettingOutlined />, [
            getItem('Brands', `/${Routes.brands.path}`, <SettingOutlined />),
            getItem('Product Types', `/${Routes.productTypes.path}`, <SettingOutlined />),
            getItem('Instalment Plans', `/${Routes.instalmentPlans.path}`, <SettingOutlined />),
            getItem('Payment Types', `/${Routes.paymentTypes.path}`, <SettingOutlined />),
            getItem('Channels', `/${Routes.channels.path}`, <UserOutlined />),
            getItem('Roles', `/${Routes.roles.path}`, <TeamOutlined />),
            // getItem('Groups', `/${Routes.groups.path}`, <GroupOutlined />),
            getItem('Permissions', `/${Routes.permissions.path}`, <KeyOutlined />),
        ]),
    ];

    const [current, setCurrent] = useState(location.pathname);

    const onClickHandler: MenuProps['onClick'] = (e: any) => {
        setCurrent(e.key);
        navigate(e.key);
    };

    return (
        <Menu
            theme="dark"
            mode="horizontal"
            onClick={onClickHandler}
            selectedKeys={[current]}
            items={items}
        />
    );
};

