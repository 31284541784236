import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Layout, message, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BaseLayoutComponent } from '@components';
import { deliveryContext } from '@contexts';
import { CustomerLocation } from '../2-customer-location';
import { AddDeliveryReceipt } from '../4-customer-receipt';
import { IDeliveryPartialDTO, KeyValueRecord } from '@rasayi-workspace/shared';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PutItem } from '@services';

const { Content } = Layout;

export const CustomerRadioFieldComponent = () => {
    const [form] = Form.useForm<Partial<any>>();
    const { setDeliveryDetails, deliveryDetails } = useContext(deliveryContext);
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [pageDirection, setPageDirection] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const [initialFormValues, setInitialFormValues] = useState({});

    const updateFormFields = (property: string | number | any, value: string | number | any) => {
        form.setFieldsValue({ [property]: value });
    };

    const previousPageHandler = () => {
        setDeliveryDetails({ ...deliveryDetails, delivery: { ...deliveryDetails.delivery, ...form.getFieldsValue() } });
        setPageDirection('previous');
        const jsonInitial: string = JSON.stringify(initialFormValues);
        const jsonCurrent: string = JSON.stringify(form.getFieldsValue());
        if (!(jsonCurrent === jsonInitial))
            !isOrderUpdating && orderUpdate();
        else
            setPreviousPage(true);
    };

    const nextPageHandler = () => {
        setDeliveryDetails({ ...deliveryDetails, delivery: { ...deliveryDetails.delivery, ...form.getFieldsValue() } });
        setPageDirection('next');
        const jsonInitial: string = JSON.stringify(initialFormValues);
        const jsonCurrent: string = JSON.stringify(form.getFieldsValue());
        if (!(jsonCurrent === jsonInitial))
            !isOrderUpdating && orderUpdate();
        else
            setNextPage(true);
    };

    const formToFormDataImage = (valuesObject: KeyValueRecord): FormData => {
        const formData = new FormData();
        Object.keys(valuesObject?.delivery).map((key: string) => {
            formData.append(key, valuesObject.delivery[key]);
            return [key, valuesObject.delivery[key].toString()];
        });
        return formData;
    };

    const { mutate: orderUpdate, isLoading: isOrderUpdating } = useMutation<IDeliveryPartialDTO, AxiosError>({
        mutationKey: ['addDocument'],
        mutationFn: async () =>
            PutItem<IDeliveryPartialDTO, FormData>(
                'delivery/partial',
                `${ deliveryDetails?.delivery?.id }`,
                formToFormDataImage(form.getFieldsValue()), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            ),
        onSuccess: async (item: IDeliveryPartialDTO): Promise<void> => {
            setDeliveryDetails({ ...deliveryDetails, ...item });
            if (pageDirection && pageDirection === 'next')
                setNextPage(true);
            else if (pageDirection && pageDirection === 'previous')
                setPreviousPage(true);
        },
        onError: (error: KeyValueRecord) => {
            messageApi.open({
                type: 'error',
                content: 'Contact support for details'
            });
        }
    });

    useEffect(() => {
        setInitialFormValues(form.getFieldsValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {
                nextPage &&
                <AddDeliveryReceipt/>
            }
            {
                previousPage &&
                <CustomerLocation/>
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <>
                                <p>
                                    Complete the delivery necessities here!
                                </p>
                                <p>
                                    <Button
                                        type='primary'
                                        onClick={ previousPageHandler }
                                        style={ { marginLeft: 4 } }
                                    >
                                        Previous Page
                                    </Button>
                                    <Button
                                        type='primary'
                                        style={ { marginLeft: 4 } }
                                        icon={ <PlusOutlined/> }
                                        onClick={ nextPageHandler }
                                    >
                                        Next
                                    </Button>
                                </p>
                            </>
                        }
                        style={ { height: 600, overflow: 'scroll' } }
                    >
                        <Form
                            form={ form }
                        >
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <Form.Item
                                        name={ ['delivery', 'signature'] }
                                        label='Customer Signature'
                                        initialValue={ deliveryDetails?.delivery?.signature }
                                    >
                                        <Select>
                                            { options.map((o, i) => {
                                                return (
                                                    <Select.Option key={ `option-${ i }` } value={ o.value }>
                                                        { o.label }
                                                    </Select.Option>
                                                );
                                            }) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <Form.Item
                                        name={ ['delivery', 'thumb'] }
                                        label='Thumb Impression'
                                        initialValue={ deliveryDetails?.delivery?.thumb }
                                    >
                                        <Select>
                                            { options.map((o, i) => {
                                                return (
                                                    <Select.Option key={ `option-${ i }` } value={ o.value }>
                                                        { o.label }
                                                    </Select.Option>
                                                );
                                            }) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <Form.Item
                                        name={ ['delivery', 'box_collection'] }
                                        label='Box Collection'
                                        initialValue={ deliveryDetails?.delivery?.box_collection }
                                    >
                                        <Select>
                                            { options.map((o, i) => {
                                                return (
                                                    <Select.Option key={ `option-${ i }` } value={ o.value }>
                                                        { o.label }
                                                    </Select.Option>
                                                );
                                            }) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <Form.Item
                                        name={ ['delivery', 'cheque_collection'] }
                                        label='Cheque Collection'
                                        initialValue={ deliveryDetails?.delivery?.cheque_collection }
                                    >
                                        <Select>
                                            { options.map((o, i) => {
                                                return (
                                                    <Select.Option key={ `option-${ i }` } value={ o.value }>
                                                        { o.label }
                                                    </Select.Option>
                                                );
                                            }) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <Form.Item
                                        name={ ['delivery', 'downpayment_collection'] }
                                        label='Downpayment Collection'
                                        initialValue={ deliveryDetails?.delivery?.downpayment_collection }
                                    >
                                        <Select>
                                            { options.map((o, i) => {
                                                return (
                                                    <Select.Option key={ `option-${ i }` } value={ o.value }>
                                                        { o.label }
                                                    </Select.Option>
                                                );
                                            }) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};