import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ReactNode } from 'react';

export const TablePageTitleComponent = ({ title, addButtonText, onClick, icon = <PlusOutlined />, disabled = false }:
    { title: string, addButtonText?: string, onClick?: () => void, icon?: ReactNode, disabled?: boolean }
) => {
    return <div className='flex justify-between items-center'>
        <div>{title}</div>
        <Button
            type='primary'
            onClick={onClick}
            disabled={disabled}
            icon={icon}
        >
            {addButtonText}
        </Button>
    </div>;
}