import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IPermissionEntity, IPermissionDTO, removeWhiteSpaceFromAllProperties, getEnumeratorKeys, PermissionTypeEnum } from '@rasayi-workspace/shared';
import { GetItem, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';

export const PermissionModalComponent = ({ onClose, onSubmit, editMode = false, id: permissionId = '' }: AddEditModalProps<IPermissionEntity>) => {
    const [form] = Form.useForm<Partial<IPermissionDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [permission, setPermission] = useState<Partial<IPermissionEntity>>();

    const { mutate: createPermission, isLoading } = useMutation<IPermissionEntity, AxiosError>({
        mutationKey: ['createPermission'],
        mutationFn: async () =>
            PostItem<IPermissionEntity, IPermissionDTO>(
                'permission',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IPermissionDTO>
            ),
        onSuccess: async (item: IPermissionEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updatePermission, isLoading: isPermissionUpdating } = useMutation<IPermissionEntity, AxiosError>({
        mutationKey: ['updatePermission'],
        mutationFn: async () =>
            PutItem<IPermissionEntity, IPermissionDTO>(
                'permission',
                permissionId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IPermissionDTO>
            ),
        onSuccess: async (item: IPermissionEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchPermission, isFetching: isPermissionFetching } = useQuery<IPermissionEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getPermission'],
        queryFn: () => GetItem<IPermissionEntity>(
            'permission',
            permissionId || ''
        ),
        onSuccess: (result: IPermissionEntity): void => {
            setPermission(permission);
            form.setFieldsValue({
                ...result
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const updateFormFields = (property: string, value: string) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createPermission())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updatePermission())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        if (editMode)
            fetchPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Permission' : 'Edit Permission'}
                open={true}
                confirmLoading={isLoading || isPermissionFetching || isPermissionUpdating}
                okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Permission' : 'Edit Permission'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{maxHeight: 550, overflow: 'auto'}}
            >
                <>
                    {
                        !editMode &&
                        <p>If the permission is of type <Tag color={'geekblue'} key={PermissionTypeEnum.SYSTEM}>{PermissionTypeEnum.SYSTEM.toUpperCase()}</Tag>it can neither be deleted nor modified. Only permissions with type <Tag color={'green'} key={PermissionTypeEnum.EXTERNAL}>{PermissionTypeEnum.EXTERNAL.toUpperCase()}</Tag>can be deleted in future.</p>
                    }
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        form={form}
                        onValuesChange={handleFormChange}
                    >
                        <Form.Item
                            name='name'
                            label='Permission name'
                            rules={[
                                { required: true, message: 'Please enter the permission name' },
                                { min: 2, message: 'Atleast 2 characters' }
                            ]}
                        >
                            <Input onChange={(event) => updateFormFields('name', event?.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name='type'
                            label='Permission type'
                            rules={[
                                { required: true, message: 'Please select the permission type' }
                            ]}
                        >
                            <Select onChange={(value) => updateFormFields('type', value)}>
                                {
                                    getEnumeratorKeys(PermissionTypeEnum).map(
                                        (key) => <Select.Option key={key} value={PermissionTypeEnum[key as keyof typeof PermissionTypeEnum]}>
                                            <Tag key={key}>{key.toUpperCase()}</Tag>
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </>

            </Modal>
        </>
    );
};
