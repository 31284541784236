import { useEffect, useState } from 'react';
import { Button, Form, message, Modal, Select, Upload } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { TagsDropdown } from '@components';
import { DocumentTypeSubTypeEnum, FilterOperatorsEnum, IDocumentDTO, IDocumentEntity, IDocumentTypeEntity, IItemsWithCount } from '@rasayi-workspace/shared';
import { GetItems, PostItem } from '@services';
import { BASE_QUERY_OPTIONS, isFileSizeValid, MAX_FILE_SIZE_MB } from '@constants';
import { ENGLISH } from '@i18n';
import { AddEditModalProps } from '@interfaces';
import { MapToSelectOption } from '@helpers';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';

export const AddDocumentModalComponent = ({ onClose, onSubmit, editMode = false, id: complianceId = '' }: AddEditModalProps<IDocumentEntity>) => {
    const [form] = Form.useForm<Partial<IDocumentDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [documentType, setDocumentTypes] = useState<IDocumentTypeEntity[]>([]);
    const [isFileUploadComplete, setIsFileUploadComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validUpload, setValidUpload] = useState(false);

    const formToFormData = (): FormData => {
        const formData = new FormData();
        const finalFields = form.getFieldsValue();

        for (const [key, value] of Object.entries(finalFields)) {
            if (!(typeof value === 'object' && !!value?.file) && typeof value === 'string') {
                formData.append(key, value);
            } else {
                formData.append(key, value?.file?.originFileObj);
            }
        }

        formData.append('compliance_id', complianceId);

        return formData;
    };

    const { mutate: addDocument, isLoading } = useMutation<IDocumentEntity, AxiosError>({
        mutationKey: ['addDocument'],
        mutationFn: async () =>
            PostItem<IDocumentEntity, FormData>(
                'document/compliance',
                formToFormData(), {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }),
        onSuccess: async (item: IDocumentEntity): Promise<void> => {
            onClose();
            onSubmit(item);
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: error.response?.status && ENGLISH.errors[error.response.status]() || ''
            });
        }
    });

    const { refetch: fetchDocumentTypes, isFetching: isDocumentTypesFetching } = useQuery<IItemsWithCount<IDocumentTypeEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getDocumentTypes'],
        queryFn: () => GetItems<IDocumentTypeEntity>(
            'document_type',
            [],
            ['id', 'name'],
            [
                [
                    {
                        field: 'type',
                        operator: FilterOperatorsEnum.EQUAL,
                        value: DocumentTypeSubTypeEnum.COMPLIANCE
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IDocumentTypeEntity>): void => setDocumentTypes(returnedResult.items),
        onError: () => setDocumentTypes([])
    });

    const updateFormFields = (property: string, value: string | any) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const handleUpload: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            setIsFileUploadComplete(true);
        }
        if (info.file.status === 'error') {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: ('File upload failed.')
            });
        }
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => addDocument())
        .catch(() => setIsValidForm(false));

    useEffect(() => {
        if (editMode)
            !isDocumentTypesFetching && fetchDocumentTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {contextHolder}
            <Modal
                title={'Add Document'}
                open={true}
                confirmLoading={isLoading || loading}
                okButtonProps={ { disabled: !validUpload } }
                okText={'Save'}
                onOk={onOkAddButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='document_type_id'
                        label='Document type'
                        rules={[{ required: true, message: 'Please enter the document type' }]}
                    >
                        <Select
                            tagRender={TagsDropdown}
                            onChange={(selectedItemIds: string) => updateFormFields('document_type_id', selectedItemIds)}
                            showSearch={false}
                            className='w-full'
                            options={MapToSelectOption(documentType, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='document'
                        label='Document'
                        rules={[
                            { required: true, message: 'Please enter the document' }
                        ]}
                    >
                        <Upload
                            maxCount={ 1 }
                            multiple={ false }
                            beforeUpload={ (file: File) => {
                                if (!isFileSizeValid(file)) {
                                    message.error(`File size must be less than ${ MAX_FILE_SIZE_MB }MB`);
                                    return setValidUpload(false);
                                }
                                return setValidUpload(true);
                            } }
                            customRequest={ ({ file, onSuccess }) => {
                            if (onSuccess)
                                setTimeout(() => {
                                    onSuccess('ok');
                                }, 0);
                        }} onChange={handleUpload}>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};