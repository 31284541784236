import { useEffect, useState } from 'react';
import { ConfigProvider, DatePicker, Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DeliveredByEnum, DeliveryStatusEnum, getEnumeratorKeys, IDeliveryDTO, IDeliveryEntity, IItemsWithCount, IUserEntity, removeWhiteSpaceFromAllProperties } from '@rasayi-workspace/shared';
import { TagsDropdown } from '@components';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

export const DeliveryModalComponent = ({ onClose, onSubmit, editMode = false, id: deliveryId = '' }: AddEditModalProps<IDeliveryEntity>) => {
    const [form] = Form.useForm<Partial<IDeliveryDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [delivery, setDelivery] = useState<Partial<IDeliveryEntity>>();
    const [user, setUser] = useState<IUserEntity[]>([]);
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs());


    const { mutate: createDelivery, isLoading } = useMutation<IDeliveryEntity, AxiosError>({
        mutationKey: ['createDelivery'],
        mutationFn: async () =>
            PostItem<IDeliveryEntity, IDeliveryDTO>(
                'delivery',
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IDeliveryDTO>
            ),
        onSuccess: async (item: IDeliveryEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateDelivery, isLoading: isDeliveryUpdating } = useMutation<IDeliveryEntity, AxiosError>({
        mutationKey: ['updateDelivery'],
        mutationFn: async () =>
            PutItem<IDeliveryEntity, IDeliveryDTO>(
                'delivery',
                deliveryId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IDeliveryDTO>
            ),
        onSuccess: async (item: IDeliveryEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchDelivery, isFetching: isDeliveryFetching } = useQuery<IDeliveryEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getDelivery'],
        queryFn: () => GetItem<IDeliveryEntity>(
            'delivery',
            deliveryId || '',
            ['deliverer', 'order'],
            ['order.internal_id']

        ),
        onSuccess: (result: IDeliveryEntity): void => {
            setDelivery(delivery);
            form.setFieldsValue({
                ...result,
            })
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { refetch: fetchUser, isFetching: IsUserFetching } = useQuery<IItemsWithCount<IUserEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUsers'],
        queryFn: () => GetItems<IUserEntity>(
            'user',
            [],
            ['id', 'first_name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IUserEntity>): void => setUser(returnedResult.items),
        onError: () => setUser([])
    });

    const updateFormFields = (property: string | number, value: string | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    }

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createDelivery())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateDelivery())
        .catch(() => setIsValidForm(false));

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
        setSelectedDate(date);
        form.setFieldsValue({ 'delivery_datetime': dateString });
    };

    useEffect(() => {
        !isDeliveryFetching && fetchDelivery();
        !IsUserFetching && fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Delivery' : 'Edit Delivery'}
                open={true}
                confirmLoading={isLoading || isDeliveryFetching || isDeliveryUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Delivery' : 'Edit Delivery'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name={['order', 'internal_id']}
                        label='order_id'
                        rules={[
                            { required: true, message: 'Please enter the order_id' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('order_id', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='deliverer_id'
                        label='deliverer_id'
                        rules={[{ required: false }]}
                    >
                        <Select
                            // mode='multiple'
                            tagRender={TagsDropdown}
                            onChange={(selectedItemIds: string) => updateFormFields('deliverer_id', selectedItemIds)}
                            showSearch
                            className='w-full'
                            options={MapToSelectOption(user, 'first_name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='delivered_by'
                        label='delivered_by'
                        rules={[
                            { required: true, message: 'Please enter the customer status' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('delivered_by', value)}>
                            {
                                getEnumeratorKeys(DeliveredByEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={DeliveredByEnum[key as keyof typeof DeliveredByEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='delivery_pin'
                        label='delivery_pin'
                        rules={[
                            { required: true, message: 'Please enter the delivery_pin' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('delivery_pin', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='delivery_status'
                        label='Delivery status'
                        rules={[
                            { required: true, message: 'Please enter the delivery status' }
                        ]}
                        initialValue={DeliveryStatusEnum.READY_TO_DELIVER}
                    >
                        <Select onChange={(value) => updateFormFields('delivery_status', value)}>
                            {
                                getEnumeratorKeys(DeliveryStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={DeliveryStatusEnum[key as keyof typeof DeliveryStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='signature'
                        label='signature'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='thumb'
                        label='thumb'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='box_collection'
                        label='box_collection'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='cheque_collection'
                        label='cheque_collection'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='downpayment_collection'
                        label='Deposit Confirmed'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='delivery_datetime'
                        label='delivery_datetime'
                        rules={[
                            { required: false, message: 'Please enter the delivery_datetime' }
                        ]}
                        initialValue={ selectedDate || dayjs() }
                    >
                        <ConfigProvider locale={locale}>
                            <DatePicker
                                value={ selectedDate || dayjs() }
                                onChange={ (date: any, dateString) => {
                                    setSelectedDate(date || dayjs());
                                    form.setFieldsValue({ 'delivery_datetime': dateString || dayjs().toISOString() });
                                } }
                                showTime={ true }
                                style={{ width: '100%' }}
                                changeOnBlur
                            />
                        </ConfigProvider>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};