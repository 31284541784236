import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, Card, Layout, message, Table, TableProps, Tag, Tooltip } from 'antd';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission } from '@services';
import { IGroupEntity, IItemsWithCount, KeyValueRecord, PermissionsEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ErrorResponse, ITableState } from '@interfaces';
import { GroupModalComponent } from './add-edit';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';

const { Content } = Layout;

export const GroupPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [groupsData, setGroupsData] = useState<IItemsWithCount<IGroupEntity>>(EMPTY_INITIAL_ITEMS);
    const [isGroupModalOpened, setIsGroupModalOpened] = useState(false);
    const [editingGroupId, setEditingGroupId] = useState('');

    const { refetch, isFetching } = useQuery<IItemsWithCount<IGroupEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['groups'],
        queryFn: () => GetTableItems<IGroupEntity>(
            'group',
            ['roles'],
            ['roles.id', 'roles.name'],
            tableState
        ),
        onSuccess: (returnedResult: IItemsWithCount<IGroupEntity>): void => setGroupsData(returnedResult),
        onError: () => setGroupsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteGroup } = useMutation<IGroupEntity, AxiosError>({
        mutationKey: ['deleteGroup'],
        mutationFn: async () =>
            DeleteItem<IGroupEntity>(
                'group',
                editingGroupId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Group deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IGroupEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IGroupEntity>).columnKey as keyof IGroupEntity]: MapAntDesignSortOrder((sorter as SorterResult<IGroupEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters)),
            sortBy: sortBy
        });
    };

    const onGroupModalCloseHandler = (newGroup: IGroupEntity) => {
        refetch();

        messageApi.open(
            !editingGroupId ? {
                type: 'success',
                content: `New group '${ newGroup?.name }' created!`,
            } : {
                type: 'success',
                content: `Group '${ newGroup?.name }' updated!`,
            }
        );

        resetModal();
    }

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsGroupModalOpened(false);
        setEditingGroupId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IGroupEntity> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: () => 0,
            ...ColumnsSearchProps('name', 'by group name', resetSearchHandler)
        },
        {
            title: 'Roles',
            key: 'roles.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IGroupEntity) => {
                const roles = item.roles?.map(({ name }) => name) || [];

                return <Tooltip placement='topLeft' title={roles.join(', ')}>
                    <span className='cursor-pointer'>
                        {
                            roles.map((name, index) => <Tag key={index} color='pink'>{name}</Tag>)
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('roles.name', 'by role name', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            sorter: () => 0,
            render: (value: string) => transformToFormattedTime(value),
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            sorter: () => 0,
            render: (value: string) => transformToFormattedTime(value),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, name }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.GROUP_FULL, PermissionsEnum.GROUP_WRITE])}
                        onClick={
                            () => {
                                setEditingGroupId(id);
                                setIsGroupModalOpened(true)
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        disabled={!HasAnyPermission([PermissionsEnum.GROUP_FULL, PermissionsEnum.GROUP_DELETE])}
                        icon={<DeleteOutlined />}
                        onClick={
                            () => {
                                setEditingGroupId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete group '${name}'?`,
                                    onOk: () => {
                                        deleteGroup();
                                    },
                                    onCancel: () => resetModal()

                                })
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isGroupModalOpened &&
                <GroupModalComponent
                    editMode={ !!editingGroupId }
                    id={ editingGroupId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onGroupModalCloseHandler }
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Groups'
                                addButtonText='Add Groups'
                                disabled={!HasAnyPermission([PermissionsEnum.GROUP_FULL, PermissionsEnum.GROUP_WRITE])}
                                onClick={() => setIsGroupModalOpened(true)}
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                showHeader={true}
                                columns={columns}
                                dataSource={groupsData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: groupsData.count,
                                        pageSize: groupsData.pageSize,
                                        current: groupsData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true}}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};