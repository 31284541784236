import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps } from 'antd';
import { EditOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { FilterOperatorsEnum, IBrandChargesEntity, IBrandEntity, IItemsWithCount, KeyValueRecord, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, TablePageTitleComponent } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { BrandModalComponent } from './add-edit';

const { Content } = Layout;

const BrandChargesPageComponent = (): any => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [data, setData] = useState<IItemsWithCount<IBrandEntity>>(EMPTY_INITIAL_ITEMS);
    const [isBrandModalOpened, setIsBrandModalOpened] = useState(false);
    const [editingBrandId, setEditingBrandId] = useState('');
    const [editingBrand, setEditingBrand] = useState({});


    const { refetch, isFetching } = useQuery<IItemsWithCount<IBrandEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['brandCharges'],
        queryFn: () => GetTableItems<IBrandEntity>(
            'brand/charges',
            ['brand_charges'],
            [],
            tableState,
            [
                [
                    {
                        field: 'brand_charges.charges',
                        operator: FilterOperatorsEnum.NOT_NULL,
                        value: 'garbage'
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IBrandEntity>): void => setData(returnedResult),
        onError: (err) => setData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteBrand } = useMutation<IBrandEntity, AxiosError>({
        mutationKey: ['deleteBrand'],
        mutationFn: async () =>
            DeleteItem<IBrandEntity>(
                'brand_charges',
                editingBrandId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Brand deleted!`,
            });

            !isFetching && await refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IBrandEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IBrandEntity>).columnKey as keyof IBrandEntity]: MapAntDesignSortOrder((sorter as SorterResult<IBrandEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onBrandModalCloseHandler = (newBrandCharges: IBrandChargesEntity) => {
        !isFetching && refetch();
        resetModal();

        messageApi.open(
            !editingBrandId ? {
                type: 'success',
                content: `New brand '${ newBrandCharges?.name }' created!`,
            } : {
                type: 'success',
                content: `Brand '${ newBrandCharges?.name }' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsBrandModalOpened(false);
        setEditingBrandId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter((item) => !item.dataKey) || []
        });
    };

    const columns: ColumnsType<IBrandEntity> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('name', 'by Brand Name', resetSearchHandler)
        },
        {
            title: 'Charges',
            dataIndex: ['brand_charge', 'charges'],
            key: 'brand_charges.charges',
            ellipsis: true,
            ...ColumnsSearchProps('brand_charges.charges', 'by charges', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, name = '', brand_charge= {} }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={ <EditOutlined/> }
                        // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingBrandId(id);
                                setEditingBrand({name: name, misc: brand_charge})
                                setIsBrandModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        !isFetching && refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            { contextHolder }
            {
                isBrandModalOpened &&
                <BrandModalComponent
                    editMode={ !!editingBrandId }
                    id={ editingBrandId }
                    onClose={ onModalCloseHandler }
                    onSubmit={ onBrandModalCloseHandler }
                    extra_field={editingBrand}
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title={
                            <TablePageTitleComponent
                                title='Brand Charges'
                                addButtonText='Add Brand Charges'
                                // disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                                onClick={ () => {
                                    setEditingBrandId('')
                                    setEditingBrand({})
                                    setIsBrandModalOpened(true);
                                } }
                            />
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={ columns }
                                dataSource={ data.items }
                                onChange={ handleChange }
                                pagination={
                                    {
                                        total: data.count,
                                        pageSize: data.pageSize,
                                        current: data.page,
                                    }
                                }
                                loading={ isFetching }
                                rowKey={ 'id' }
                                size='small'
                                scroll={ { x: true } }
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};

export default BrandChargesPageComponent;