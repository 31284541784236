import { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, Tag, ConfigProvider, DatePicker } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IItemsWithCount, IOrderDTO, IOrderEntity, getEnumeratorKeys, removeWhiteSpaceFromAllProperties, OrderStatusEnum, ChequeProviderEnum, ContractStatusEnum, SegmentEnumerator, IUserEntity, IInstalmentEntity, IChannelEntity, IInventoryEntity, IUpdateOrderDTO, ITransactionDTO, PaymentFlowEnum, PaymentMethodEnum, ITransactionEntity } from '@rasayi-workspace/shared';
import { TagsDropdown } from '@components';
import { GetItem, GetItems, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { ENGLISH } from '@i18n';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

export const PayInstallmentModalComponent = ({ onClose, onSubmit, editMode = false, id: orderId = '' }: AddEditModalProps<IOrderEntity>) => {
    const [form] = Form.useForm<Partial<ITransactionDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [order, setOrder] = useState<Partial<IOrderEntity>>();
    const [users, setUsers] = useState<IUserEntity[]>([]);
    const [instalmentPlan, setInstalmentPlan] = useState<IInstalmentEntity[]>([]);
    const [channel, setChannel] = useState<IChannelEntity[]>([]);
    const [inventory, setInventory] = useState<IInventoryEntity[]>([]);
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<dayjs.Dayjs | null>(null);


    const { mutate: updateOrder, isLoading: isOrderUpdating } = useMutation<IOrderEntity, AxiosError>({
        mutationKey: ['updateOrder'],
        mutationFn: async () =>
            PutItem<IOrderEntity, IUpdateOrderDTO>(
                'order',
                orderId,
                removeWhiteSpaceFromAllProperties(form.getFieldsValue()) as Partial<IUpdateOrderDTO>
            ),
        onSuccess: async (item: IOrderEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchOrder, isFetching: isOrderFetching } = useQuery<IOrderEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getOrder'],
        queryFn: () => GetItem<IOrderEntity>(
            'order',
            orderId || '',
            ['items'],
            []
        ),
        onSuccess: (result: IOrderEntity): void => {
            setOrder(order);
            form.setFieldsValue({
                ...result,
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const { mutate: payInstallment } = useMutation<ITransactionEntity, AxiosError>({
        mutationKey: ['payIstallment'],
        mutationFn: async () =>
            PostItem<ITransactionEntity, ITransactionDTO>(
                'transaction/instalment',
                removeWhiteSpaceFromAllProperties({ ...form.getFieldsValue() }) as Partial<any>
            ),
        onSuccess: async (item: any): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const updateFormFields = (property: string, value: string | number) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
        setSelectedDateOfBirth(date);
        form.setFieldsValue({ 'paid_datetime': dateString });
    };


    const payInstallmentHandler = async () => {
        console.log('helloo', form.validateFields())
        await form.validateFields()
            .then(() => payInstallment())
            .catch(() => setIsValidForm(false))
    };

    useEffect(() => {
        fetchOrder();
    }, []);

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    console.log(orderId, 'orderId');

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Adgdggg Order' : 'Edit Order'}
                open={true}
                confirmLoading={isOrderFetching || isOrderUpdating
                }
                // okButtonProps={{ disabled: !isValidForm }}
                okText={'pay installment'}
                onOk={payInstallmentHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='order_id'
                        label='order id'
                        rules={[
                            { required: true, message: 'Please enter the order id' }
                        ]}
                    >
                        <Input value={orderId ? 'hello ' : 'bye'} />
                    </Form.Item>
                    <Form.Item
                        name='flow'
                        label='flow'
                        rules={[
                            { required: true, message: 'Please enter the flow' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('flow', value)}>
                            {
                                getEnumeratorKeys(PaymentFlowEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={PaymentFlowEnum[key as keyof typeof PaymentFlowEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='amount'
                        label='amount'
                        rules={[
                            { required: true, message: 'Please enter the amount' },
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('amount', parseInt(event?.target.value) || 0)} />
                    </Form.Item>
                    <Form.Item
                        name='transaction_id'
                        label='transaction_id'
                        rules={[
                            { required: true, message: 'Please enter the transaction_id' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('transaction_id', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='payment_method'
                        label='payment_method'
                        rules={[
                            { required: true, message: 'Please enter the payment_method' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('payment_method', value)}>
                            {
                                getEnumeratorKeys(PaymentMethodEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={PaymentMethodEnum[key as keyof typeof PaymentMethodEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='paid_datetime'
                        label='paid_datetime'
                        rules={[
                            { required: true, message: 'Please enter the paid_datetime' }
                        ]}
                    >
                        <ConfigProvider locale={locale}>
                            <DatePicker
                                value={dayjs(form.getFieldValue('paid_datetime'))}
                                onChange={handleDateChange}
                                showTime={false}
                                style={{ width: '100%' }}
                            />
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        name='force_change_instalment_payment_status'
                        label='force_change_instalment_payment_status'
                        rules={[
                            { required: true, message: 'Please enter the force_change_instalment_payment_status' }
                        ]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};