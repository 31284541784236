import { FilterSeparatorsEnum } from '../enums';
import { KeyValueRecord } from '../types/key-value-record';

/**
 * @function getEnumeratorValues
 * @description Returns the values from the given enumerator.
 * @param {Record<string, T>} enumerator Enumerator.
 * @returns {T[]}
 */
export function getEnumeratorValues<T>(enumerator: Record<string, T>): T[] {
    return Object.values(enumerator) as T[];
}

/**
 * @function getEnumeratorKeys
 * @description Returns the keys from the given enumerator.
 * @param {Record<string, T>} enumerator Enumerator.
 * @returns {T[]}
*/
export function getEnumeratorKeys<T>(enumerator: Record<string, T>): string[] {
    return Object.keys(enumerator);
}

/**
 * @function nameToInitials
 * @description Returns the initials of the name, first and last part of name initials E,G John Doe => JD.
 * @param {string} fullName name.
 * @param {boolean} uppercase  {optional} transform to upper case or not. 
 * @returns {string}
 */
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export function nameToInitials(fullName: string, uppercase: boolean = false): string {
    const words: string[] = fullName.trim().split(' ');

    if (!words.length)
        return '';

    return words.reduce(word => uppercase ? word.charAt(0).toLocaleUpperCase() : word.charAt(0), '');
}

/**
 * @function addLeadingZeros Pads the given number with zeros.
 * @description Adds leading zeros to the given string until the result length is of the given result length.
 * @param {number} number Number.
 * @param {number} resultLength Result length.
 * @returns {string}
 */
export function addLeadingZeros(number: number, resultLength: number): string {
    let result = String(number);

    while (result.length < resultLength)
        result = '0' + result;

    return result;
}

/**
 * @function transformToFormattedMinutes
 * @description Returns time difference in readable format. e.g. 2 days 4 hrs and 3 minutes
 * @param {number} incomingMilliseconds number.
 * @param {boolean} returnedDetailedTime boolean. {optional} If we want detailed time returned, pass this parameter. 
 * Else this function will return in hours and minutes 
 * @returns {string} formatted string
 */
export function transformToFormattedMinutes(incomingMilliseconds: number, returnedDetailedTime?: boolean): string {
    let minutes: number = Math.floor((incomingMilliseconds / 1000) / 60),
        hours: number = Math.floor(Math.abs(minutes / 60));

    const days: number = Math.floor(Math.abs(hours / 24)),
        formatForOne = (value: number, string: string) => `${value} ${value === 1 ? string : string + 's'}`;

    if (hours)
        minutes = minutes % 60;

    if (days)
        hours = hours % 24;

    if (!minutes)
        return formatForOne(minutes, 'min');

    if (!hours)
        return `${addLeadingZeros(minutes, 2)} ${formatForOne(minutes, 'min')}`;

    if (!minutes)
        return `${addLeadingZeros(hours, 2)} ${formatForOne(hours, 'hr')}`;

    if (!days)
        return (returnedDetailedTime) ?
            `${addLeadingZeros(hours, 2)} ${formatForOne(hours, 'hr')} and ${addLeadingZeros(minutes, 2)} ${formatForOne(minutes, 'min')}` :
            `${addLeadingZeros(hours, 2)} ${formatForOne(hours, 'hr')}`;

    return (returnedDetailedTime) ?
        `${addLeadingZeros(days, 2)} ${formatForOne(days, 'day')} and ${addLeadingZeros(hours, 2)} ${formatForOne(hours, 'hr')} and ${addLeadingZeros(minutes, 2)} ${formatForOne(minutes, 'min')}` :
        `${addLeadingZeros(days, 2)} ${formatForOne(days, 'day')}`;
}

/**
    @function transformToFormattedTime
    @description Returns formatted time in DD/MM/YYYY HH:MM:AM/PM or DD/MM/YYYY
    @param {number| number} dateInMillisecondsOrISOString number<or string date.
    @param {string} delimiter {optional} delimiter to pass between the dates. e.g. if you pass '-', this function will return DD-MM-YYYY.
    @param {boolean} returnedDetailedTime {optional} returns detailed time in DD/MM/YYYY HH:MM:AM/PM else returns DD/MM/YYYY.
    @returns {string} formatted time string
*/
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export function transformToFormattedTime(dateInMillisecondsOrISOString: Date | number | string = Date.now(), delimiter: string = '/', returnedDetailedTime: boolean = true): string {
    const now = new Date(),
        date = new Date(dateInMillisecondsOrISOString),
        year = date.getFullYear().toString().slice(2);

    let hours = date.getHours();

    let ampm = 'am';

    if (hours > 11) {
        if (hours > 12)
            hours = hours % 12;

        ampm = 'pm';
    }

    if (returnedDetailedTime && (now.getDate() !== date.getDate() || (now.getMonth()) !== date.getMonth() || (now.getFullYear()) !== date.getFullYear()))
        return `${addLeadingZeros(date.getDate(), 2)}${delimiter}${addLeadingZeros(date.getMonth() + 1, 2)}${delimiter}${year} ${addLeadingZeros(hours, 2)}:${addLeadingZeros(date.getMinutes(), 2)} ${ampm}`;

    if (now.getDate() !== date.getDate() || (now.getMonth()) !== date.getMonth() || (now.getFullYear()) !== date.getFullYear())
        return `${addLeadingZeros(date.getDate(), 2)}${delimiter}${addLeadingZeros(date.getMonth() + 1, 2)}${delimiter}${year}`;

    return `${addLeadingZeros(hours, 2)}:${addLeadingZeros(date.getMinutes(), 2)} ${ampm}`;
}

/**
 * Converts a given date into formatted time WRT given delimiter. Default delimiter is `/`
 * @param date Date to convert
 * @returns formatted string
 */
export function convertTimeToDDMMYYYYHHMM(date: number | Date | undefined, delimiter = '/'): string {
    return !date ? '' : transformToFormattedTime(typeof date === 'number' ? date : date.getTime(), delimiter, true);
}

/**
 * recursively remove all the tabs, next lines, more than one spaces, from properties of object(s) which are strings and also trims the edges
 * @param {KeyValueRecord} object  object for whom all the properties have to be cleaned
 * @returns cleaned object or the cleaned string
 */
export function removeWhiteSpaceFromAllProperties<T = KeyValueRecord>(object: T | string): T | T[] | string {
    const clean = (str: string) => str.replace(/[\n\r\u2028]/g, ' ').replace(/\s\s+/g, ' ').trim();

    if (typeof object === 'string')
        return clean(object);

    if (Array.isArray(object))
        return object.map(item => removeWhiteSpaceFromAllProperties(item));

    const newCleanedObject = JSON.parse(JSON.stringify(object)); // for deep copy. not bringing lodash. 

    for (const key in object)
        if (!object[key])
            continue;
        else
            newCleanedObject[key] = removeWhiteSpaceFromAllProperties(newCleanedObject[key]);

    return newCleanedObject;
}

/**
 * gives a random number within the given boundary
 * @param {number} min minimum number
 * @param {number} max max munber
 * @returns random number
 */
export function getRandomNumberBetweenBoundaries(min: number, max: number): number {
    return parseFloat((Math.random() * (max - min) + min).toFixed(0));
}

/**
 * lowercases all the properties of an object which are strings
 * @param {KeyValueRecord} object the object which has to props to do lowercase
 * @param {string[]} whitelistProperties {optional} property keys which are not to be lowercased
 */
export function recursivelyLowercaseObjectProperties(object: KeyValueRecord, whitelistProperties: string[] = []): KeyValueRecord {
    const newCleanOjbect = JSON.parse(JSON.stringify(object));

    for (const key in newCleanOjbect)
        if (typeof newCleanOjbect[key] === 'string') {
            if (!whitelistProperties.includes(key))
                newCleanOjbect[key] = (newCleanOjbect[key] as string).toLocaleLowerCase();
        }
        else
            newCleanOjbect[key] = recursivelyLowercaseObjectProperties(newCleanOjbect[key]);

    return newCleanOjbect;
}

/**
 * make a function which takes a string and converts into a nested object separating on the `splitOn` parameter. e.g
 * example: "option" should be converted to { option: true }
 * example2: "option.option2" should be converted to { option: { option2: true } }
 * example3: "option.option2.option3" should be converted to { option: { option2: { option3: true } } }
 * @param {string} string string to convert
 * @param {string} splitOn split the string on this separator
 * @param {boolean | string | number} value value to be added
 * @param {KeyValueRecord} existingObject an object in which this needs to be appended
 * @returns nested object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertStringToNestedObject = (string: string, splitOn: string = FilterSeparatorsEnum.FULL_STOP, value: boolean | string | number = true, existingObject: KeyValueRecord = {}): KeyValueRecord => {
    const [firstValue, ...others] = string.split(splitOn);

    if (!others.length)
        return {
            ...existingObject,
            [firstValue]: value
        };

    existingObject = {
        ...existingObject,
        [firstValue]: convertStringToNestedObject(others.join(splitOn), splitOn, value, existingObject[firstValue] || {})
    }

    return existingObject;
}

/**
 * Add wait for given seconds
 * @param {number} seconds 
 * @returns a void promise 
 */
export const WAIT = async (seconds: number): Promise<void> =>
    new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });
    
//phone and cnic regex
export const regexCollection = {
    phone: new RegExp('^((03)([0-9]{9}))$'),
    cnic: new RegExp('^((?!0)([0-9]{5}[-][0-9]{7}[-][0-9]))$')
};

//removes key where value is empty 
export const removeEmptyValues = (values: KeyValueRecord) => {
    const cleanedValues = { ...values };
    for (const key in cleanedValues) {
        if (!cleanedValues[key]) {
            delete cleanedValues[key];
        }
    }
    return cleanedValues;
};